import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseModalComponent } from 'src/app/modules/shared/components/modals/course-modal/course-modal.component';
import { WDialogService } from 'src/app/modules/shared/components/w-dialog/services/w-dialog.service';
import {
  Attempt,
  AttemptStatPage,
  CompiledTest,
  Course,
  Lesson,
  LessonDetail,
  LessonPage,
  LessonStat,
  LessonStatPage,
  Student,
  StudentFilter,
  StudentPage,
} from '../../models/data-model';
import { DlgType } from 'src/app/modules/shared/components/w-notification/models/notification-models';
import { environment } from 'src/environments/environment';
import { StudentModalComponent } from 'src/app/modules/shared/components/modals/student-modal/student-modal.component';
import { CoursesService } from '../../../services/courses.service';
import { LessonsService } from '../../../services/lessons.service';
import { User } from 'src/app/modules/shared/model/user';
import { DatePipe,Location  } from '@angular/common';
import { WNotificationService } from 'src/app/modules/shared/components/w-notification/services/w-notification.service';

@Component({
  selector: 'wave-eduxible-course-detail-page',
  templateUrl: './course-detail-page.component.html',
  styleUrls: ['./course-detail-page.component.scss'],
})
export class CourseDetailPageComponent implements OnInit {
  @Output() filterStud!: StudentFilter;
  @Output() raiseFilterStud = new EventEmitter<StudentFilter>();
  completed!: boolean;
  course!: Course;
  test!: CompiledTest;
  elencoStudenti: Student[] = [];
  totalElementsStudents!: number;
  totalElementsLessonStats!: number;
  totalElementsAttempts!: number;
  totalElementsLessonList!: number;
  numeroLezioni!: Lesson;
  pageStudents = 1;
  pageLessons = 1;
  pageAttempts = 1;
  pageSize: number = environment.size;
  elencoLezioni!: LessonDetail[];
  elencoLezioniStat!: LessonStat[];
  elencoAttemptStat!: Attempt[];
  testCompletato!: CompiledTest[];
  detail!: LessonDetail[];
  currentUser!: User;
  icon: string = '';
  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private modalService: NgbModal,
    private courseService: CoursesService,
    private dialogService: WDialogService,
    private lessonService: LessonsService,
    private alertService: WNotificationService,
    public location: Location
  ) {
    this.filterStud = {};
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.course = JSON.parse(localStorage.getItem('course')!);
    this.icon = this.course.icon + '.png';
    /* this.icon = (this.course.icon && this.course.icon.length > 0 ? this.course.icon : "") + ".png"; */
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
    console.log('Corso', this.course);
    if (this.currentUser.role !== 'Student') {
      this.courseService
        .getCourseStudent(this.course.id, 0, this.filterStud)
        .subscribe({
          next: (students: StudentPage) => {
            this.elencoStudenti = students.content.filter((s) => {
              return s.active;
            });
            this.totalElementsStudents = students.totalElements;
            console.log('Studenti', this.elencoStudenti);
          },
        });
    }
    this.getLessonList(0);
    //this.getTestDetail(this.course.id, this.lesson.id, this.test.id);
    if (this.currentUser.role === 'Student') {
      this.getLessonStat(this.course.id, 0); //da vedere la paginazione
    }
    this.getAttemptsStat(this.course.id, 0);
  }

  raiseFormSubmittedStud($event: StudentFilter) {
    console.log('raise filter STUD', $event);
    //debugger
    this.filterStud = $event;
    this.getStudentList(0, this.filterStud);
  }

  changePageStudents($event: number) {
    console.log('Page Students:', $event);
    this.pageStudents = $event;
    this.getStudentList(this.pageStudents - 1, this.filterStud);
    // this.getAttemptsStat(this.course.id, this.page - 1);
    // this.getLessonStat(this.course.id, this.page - 1);
    // this.getLessonList(this.page - 1);
  }

  changePageLessons($event: number) {
    console.log('Page Lessons:', $event);
    this.pageLessons = $event;
    // this.getAttemptsStat(this.course.id, this.page - 1);
    this.getLessonStat(this.course.id, this.pageLessons - 1);
    //this.getLessonList(this.page - 1);
  }

  changePageAttempts($event: number) {
    console.log('Page Attempts:', $event);
    this.pageAttempts = $event;
    this.getAttemptsStat(this.course.id, this.pageAttempts - 1);
    // this.getLessonStat(this.course.id, this.page - 1);
  }

  getStudentList(page: number, filter: StudentFilter) {
    this.courseService
      .getCourseStudent(this.course.id, page, filter)
      .subscribe({
        next: (students: StudentPage) => {
          this.elencoStudenti = students.content;
          this.totalElementsStudents = students.totalElements;
          console.log('Studenti', this.elencoStudenti);
        },
      });
  }

  getLessonList(page: number) {
    this.lessonService.getLessonList(page, this.course.id).subscribe({
      next: (lessons: LessonPage) => {
        this.elencoLezioni = lessons.content.filter((l) => {
          return !l.deleted;
        });
        this.totalElementsLessonList = lessons.totalElements;
        console.log('Lezioni', this.elencoLezioni);
      },
    });
  }

  getLessonDetail(courseID: number, lessonID: number) {
    this.lessonService.getLessonDetail(courseID, lessonID).subscribe({
      next: (detail: LessonDetail) => {
        //this.detail = detail;
        console.log('DETTAGLIO', detail);
      },
    });
  }

  getTestDetail(courseID: number, lessonID: number, testID: number) {
    this.lessonService.getTestDetail(courseID, lessonID, testID).subscribe({
      next: (detail: CompiledTest) => {
        console.log('DETTAGLIO TEST', detail);
      },
    });
  }

  editCourse(course: Course) {
    console.log(course);
    const modalRef = this.modalService.open(CourseModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.course = course;
    modalRef.componentInstance.action = 'edit';
    modalRef.componentInstance.title = 'Modifica corso';
    modalRef.componentInstance.confirmButton = 'Modifica';
    modalRef.result
      .then((editForm) => {
        console.log('editForm: ', editForm);
        this.course.name = editForm.name;
        this.course.year = editForm.year;
        this.course.description = editForm.description;
        this.course.manager = editForm.manager;
        this.course.collaborators = editForm.collaborators;
        this.course.icon = editForm.icon;
        this.icon = this.course.icon + '.png';
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  deleteCourse(course: Course) {
    console.log(course);
    this.dialogService.open({
      titolo: `Vuoi eliminare ${course.name}?`,
      userType: 'course',
      dlgType: 'delete',
      tipo: DlgType.ERROR,
      cancelVisible: true,
      okHandler: () => {
        this.courseService.deleteCourse(course).subscribe(
          (res) => {
            this.alertService.open({
              titolo: "Successo",
              tipo: DlgType.SUCCESS,
              messaggio: `Corso cancellato correttamente.`,
              cancelLabel: "",
              cancelVisible: true,
              okLabel: "",
              codErrore: 0
            });
          }
        );
        this.course.active = false;
        localStorage.removeItem('course');
        localStorage.setItem('course', JSON.stringify(this.course));
        window.location.reload();
      },
    });
  }

  restoreCourse(c: Course){
    this.dialogService.open({
      titolo: `Vuoi ripristinare ${c.name}?`,
      userType: 'course-restore',
      dlgType: 'restore',
      tipo: DlgType.INFO,
      // messaggio: '',
      cancelVisible: true,
      okHandler: () => {
        this.courseService.restoreCourse(c.id).subscribe( (res) => {
          this.alertService.open({
            titolo: "Successo",
            tipo: DlgType.SUCCESS,
            messaggio: `Corso riattivato correttamente.`,
            cancelLabel: "",
            cancelVisible: true,
            okLabel: "",
            codErrore: 0
          });
          this.course.active = true;
          localStorage.removeItem('course');
          localStorage.setItem('course', JSON.stringify(this.course));
          window.location.reload();
        });
      },
    });
  }

  editStudent(student: Student) {
    console.log(student);
    const modalRef = this.modalService.open(StudentModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.student = student;
    modalRef.componentInstance.action = 'edit';
    modalRef.componentInstance.title = 'Modifica studente';
    modalRef.componentInstance.confirmButton = 'Modifica';
    modalRef.result
      .then((editForm) => {        
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  deleteStudent(student: Student) {
    console.log(student);
    // Aprire il dialog
    this.dialogService.open({
      titolo: `Vuoi rimuovere ${student.name} ${student.surname} dal corso?`,
      userType: 'course-remove',
      dlgType: 'delete',
      tipo: DlgType.ERROR,
      cancelVisible: true,
      okHandler: () => {
        this.courseService
          .removeStudentFromCourse(this.course.id, student.id)
          .subscribe(
            (res) => {
            this.alertService.open({
              titolo: "Successo",
              tipo: DlgType.SUCCESS,
              messaggio: `Studente rimosso correttamente.`,
              cancelLabel: "",
              cancelVisible: true,
              okLabel: "",
              codErrore: 0
            });
          });
        window.location.reload();
      },
    });
  }

  openLesson(lesson: LessonStat) {
    this.lessonService
      .getLessonDetail(this.course.id, lesson.id)
      .subscribe((lesson) => {
        localStorage.removeItem('lesson');
        localStorage.setItem('lesson', JSON.stringify(lesson));
        this.router.navigate(['/dettaglio_lezione'], {
          state: { countLess: 0 },
        });
      });
  }

  goToTest(lesson: LessonStat) {
    this.lessonService
      .getLessonDetail(this.course.id, lesson.id)
      .subscribe((lesson) => {
        localStorage.removeItem('lesson');
        localStorage.setItem('lesson', JSON.stringify(lesson));
        this.router.navigate(['/test'], {
          state: { countLess: 0 },
        });
      });
  }

  openTest() {
    this.router.navigate(['/dettaglio_lezione'], { state: { countLess: 0 } });
  }

  getLessonStat(courseID: number, page: number) {
    this.courseService.getLessonsStats(courseID, page).subscribe({
      next: (lessons: LessonStatPage) => {
        console.log('STAT LESS', lessons);
        this.elencoLezioniStat = lessons.content;
        lessons.content.forEach((att) => {
          this;
        });
        this.totalElementsLessonStats = lessons.totalElements;
        console.log('Lezioni STAT', this.elencoLezioniStat);
      },
    });
  }

  getAttemptsStat(courseID: number, page: number) {
    this.courseService.getAttemptsStats(courseID, page).subscribe({
      next: (attempts: AttemptStatPage) => {
        this.elencoAttemptStat = attempts.content;
        this.elencoAttemptStat.forEach((att) => {
          att.score = Math.round(att.score * 10) / 10;
        });
        this.totalElementsAttempts = attempts.totalElements;
        console.log('Attempts STAT', this.elencoAttemptStat);
      },
    });
  }

  openLessonStat(lesson: LessonStat) {
    this.lessonService
      .getLessonDetail(this.course.id, lesson.id)
      .subscribe((lesson) => {
        localStorage.removeItem('lesson');
        localStorage.setItem('lesson', JSON.stringify(lesson));
        this.router.navigate(['/dettaglio_lezione'], {
          state: { countLess: 0 },
        });
      });
  }

  openTestDetail(attempts: Attempt) {
    this.lessonService
      .getTestDetail(this.course.id, attempts.lessonId, attempts.id)
      .subscribe((testDetail) => {
        localStorage.removeItem('testDetail');
        console.log(testDetail);
        localStorage.setItem('testDetail', JSON.stringify(testDetail));
        this.router.navigate(['/test_studente'], {});
      });
  }

  back() {
    this.location.back();
  }

  goToStudentDetail(student: Student) {
    localStorage.removeItem('student');
    localStorage.setItem('student', JSON.stringify(student));
    this.router.navigate(['/dettaglio_studenti']);
  }
}
