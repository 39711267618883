<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/statistiche">Statistiche</a></li>
    <li class="breadcrumb-item active" aria-current="page">Statistiche avanzate</li>
  </ol>
</nav>
<h1>Statistiche avanzate</h1>
<div class="ms-2 mb-2">
<div id="output"></div>
</div>

<div class="mt-5">
  <a role="button" class="text-primary mt-4 btn btn-link" (click)="back()">
    <svg class="icon me-2">
      <use xlink:href="assets/icons/annulla.svg#annulla"
        href="assets/icons/annulla.svg#annulla"></use>
    </svg>
    <span>Torna indietro</span>
  </a>
</div>
