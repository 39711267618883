<div class="container mt-5 d-flex flex-column align-items-center">
  <img
    src="../../../../../../assets/icons/logo_eduxible.svg"
    width="290px"
    height="auto"
    alt="NexMeterUP logo"
    class="mb-5"
    />

  <div class="col-12 col-xs-10 col-md-6 shadow-lg p-4 bg-white rounded mt-5">
    <h1 class="mb-5 text-center h2">Accesso</h1>
    <form [formGroup]="form" (submit)="handleAccess(form.value)">
      <div class="my-4">
        <label class="form-label" for="email">Username o indirizzo e-mail</label>
        <input
          type="text"
          class="form-control border-bottom"
          id="email"
          placeholder="Inserisci Email"
          formControlName="email"
          />
      </div>

      <div class="my-4">
        <label class="form-label" for="password">Password </label>
        <div class="input-group mb-3 d-sm-flex">
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder="Inserisci Password"
            formControlName="password" />
          <span class="password-icon cursor-pointer z-index-dropdown position-absolute end-0 top-0 mt-2 me-2 bg-transparent"  aria-hidden="true"
          onClick="toggleShowPassword()">
            <svg id="password-icon-visible" class="icon" aria-label="mostra password">
              <use href="assets/icons/eye.svg#eye"></use>
            </svg>
            <svg id="password-icon-invisible" class="d-none icon"  aria-label="nascondi password">
              <use href="assets/icons/eye-slash.svg#eye-slash"></use>
            </svg>
          </span>
        </div>
      </div>
      <div class="text-center">
        <button class="my-2 px-4 btn btn-primary" type="submit">Accedi</button>
      </div>
    </form>
  </div>
</div>
