<button class="btn btn-secondary float-end btn-sm" (click)="openModalAdmin()">
  <svg class="icon me-2">
    <use xlink:href="assets/icons/add-user.svg#add-user"
      href="assets/icons/add-user.svg#add-user"></use>
  </svg>Aggiungi amministratore</button>
<h1 class="fw-bold mt-5">Amministratori</h1>
<wave-eduxible-filter-teacher
  (formSubmittedProf)="raiseFormSubmittedProf($event)"></wave-eduxible-filter-teacher>
<div class="table-responsive">
  <table class="table table-hover">
    <thead>
      <tr class="align-middle border-bottom border-2">
        <th class="pe-0">
          <span class="visually-hidden">Attivo</span>
        </th>
        <th class="col-1 mb-0 fw-bold">
          Nome
        </th>
        <th class="col-1 mb-0 fw-bold">
          Cognome
        </th>
        <th class="col-1 mb-0 fw-bold">
          Email
        </th>
        <th class="col-2 mb-0 fw-bold">
          Responsabile
        </th>
        <th class="col-2 mb-0 fw-bold">
          Collaboratore
        </th>
        <!-- TH VUOTO PER BOTTONI -->
        <th>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let prof of elencoProfessori">
        <td class="pe-0" *ngIf="prof.active; else notActive">
          <span class="d-inline-block" tooltip="Attivo" placement="top">
            <svg class="icon icon-green">
              <use xlink:href="assets/icons/user.svg#user"
                href="assets/icons/user.svg#user"></use>
            </svg>
          </span>
        </td>
      <ng-template #notActive>
        <td>
          <span class="d-inline-block" tooltip="Non attivo" placement="top">
            <svg class="icon icon-500">
              <use xlink:href="assets/icons/user.svg#user"
                href="assets/icons/user.svg#user"></use>
            </svg>
          </span>
        </td>
      </ng-template>
    
      <!--  | slice: (page-1) * pageSize : page * pageSize-->
        <td>{{prof.name}}</td>
        <td>{{prof.surname}}</td>
        <td>{{prof.email}}</td>
        <td>
          <ul class="ps-3">
            <li *ngFor="let course of prof.managerOf">{{course.name}}</li>
          </ul>
        </td>
        <td>
          <ul class="ps-3">
            <li *ngFor="let course of prof.collaboratorOf">{{course.name}}</li>
          </ul>
        </td>
        <td>
          <div class="d-flex justify-content-end float-end">
            <a *ngIf="prof.active === true" role="button" class="d-inline me-4"
              (click)="editAdmin(prof)"
              tooltip="Modifica" placement="top">
              <svg class="icon">
                <use xlink:href="assets/icons/modify.svg#modify"
                  href="assets/icons/modify.svg#modify"></use>
              </svg></a>
            <a *ngIf="prof.active === true; else noActive" role="button"
              class="d-inline me-4 text-danger"
              (click)="deleteAdmin(prof)" tooltip="Elimina" placement="top">
              <svg class="icon me-3">
                <use xlink:href="assets/icons/trashcan.svg#trashcan"
                  href="assets/icons/trashcan.svg#trashcan"></use>
              </svg>
            </a>
            <ng-template #noActive>
              <a role="button" (click)="restoreUser(prof)"
                tooltip="Ripristina" placement="top" class="d-inline me-4
                text-primary">
                <svg class="icon">
                  <use xlink:href="assets/icons/restore.svg#restore"
                    href="assets/icons/restore.svg#restore"></use>
                </svg>
              </a>
            </ng-template>
          </div>

        </td>
      </tr>
    </tbody>
  </table>
</div>
<div>
  <ngb-pagination
    (pageChange)="changePage($event)"
    [collectionSize]="totalElements"
    [(page)]="page"
    [maxSize]="10"
    [rotate]="true"
    [ellipses]="true"
    [boundaryLinks]="true"
    [pageSize]="pageSize">
  </ngb-pagination>
</div>

