import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Professor,
  ProfessorFilter,
  ProfessorPage,
} from '../../models/data-model';
import { environment } from '../../../../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../../../shared/model/user';
import { ProfessorModalComponent } from '../../../../../shared/components/modals/professor-modal/professor-modal.component';
import { ProfessorService } from '../../../services/professor.service';
import { WDialogService } from 'src/app/modules/shared/components/w-dialog/services/w-dialog.service';
import { DlgType } from 'src/app/modules/shared/components/w-notification/models/notification-models';
import { Router } from '@angular/router';

@Component({
  selector: 'wave-eduxible-teachers-overview-page',
  templateUrl: './teachers-overview-page.component.html',
  styleUrls: ['./teachers-overview-page.component.scss'],
})
export class TeachersOverviewPageComponent implements OnInit {
  @Output() filterProf!: ProfessorFilter;
  filterList!: ProfessorFilter;
  @Output() raiseFilterProf = new EventEmitter<ProfessorFilter>();

  elencoProfessori: Professor[] = [];
  elencoProfessoriFiltrato: Professor[] = [];
  teachers: any;
  totalElements!: number;
  user!: User;
  // @Output() onChangePageTeachers = new EventEmitter<number>();
  page = 1;
  pageSize: number = environment.size;

  constructor(
    private professorService: ProfessorService,
    private dialogService: WDialogService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.filterProf = {};
  }

  changePage($event: number) {
    console.log('Page:', $event);
    this.page = $event;
    this.getTeacherList(this.page - 1, this.filterProf);
  }

  raiseFormSubmittedProf($event: ProfessorFilter) {
    console.log('raise filter PROF', $event);
    //this.raiseFilterProf.emit($event);
    //debugger;
    this.filterProf = $event;
    this.getTeacherList(0, this.filterProf);
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user')!);
    this.filterProf.active = true;
    this.getTeacherList(0, this.filterProf);
    console.log('ROTTA', this.router.url);
  }

  getTeacherList(page: number, filter: ProfessorFilter) {
    this.professorService.getProfessorsList(page, filter).subscribe({
      next: (teachers: ProfessorPage) => {
        this.elencoProfessori = teachers.content;
        this.totalElements = teachers.totalElements;
        console.log('Teachers list', this.elencoProfessori);
      },
    });
  }

  openModalProf() {
    const modalRef = this.modalService.open(ProfessorModalComponent, {
      size: 'lg',
      centered: true,
      scrollable: true,
    });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.action = 'add';
    modalRef.componentInstance.title = 'Aggiungi docente';
    modalRef.componentInstance.confirmButton = 'Conferma e aggiungi';
    modalRef.result
      .then((professorForm) => {
        // this.getTeacherList(this.page);
        window.location.reload();
        console.log('professorForm: ', professorForm);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  /* assignCopy(){
     this.elencoProfessoriFiltrato = Object.assign([], this.elencoProfessori);
   }*/

  editTeacher(prof: Professor) {
    console.log('prof: ', prof);
    const modalRef = this.modalService.open(ProfessorModalComponent, {
      size: 'lg',
      centered: true,
      scrollable: true,
    });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.prof = prof;
    modalRef.componentInstance.action = 'edit';
    modalRef.componentInstance.title = 'Modifica docente';
    modalRef.componentInstance.confirmButton = 'Modifica';
    modalRef.result
      .then((professorForm) => {
        // this.getTeacherList(this.page);
        window.location.reload();
        console.log('professorForm: ', professorForm);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  deleteTeacher(prof: Professor) {
    console.log(prof);
    // Aprire il dialog
    this.dialogService.open({
      titolo: `Vuoi eliminare ${prof.name} ${prof.surname}?`,
      userType: 'professor',
      dlgType: 'delete',
      tipo: DlgType.ERROR,
      // messaggio: '',
      cancelVisible: true,
      okHandler: () => {
        this.professorService.deleteProfessor(prof).subscribe();
        window.location.reload();
      },
    });
  }

  restoreTeacher(prof: Professor) {
    this.dialogService.open({
      titolo: `Vuoi ripristinare ${prof.name} ${prof.surname}?`,
      userType: '',
      dlgType: 'restore',
      tipo: DlgType.RESTORE,
      // messaggio: '',
      cancelVisible: true,
      okHandler: () => {
        this.professorService.restoreProfessor(prof).subscribe();
        window.location.reload();
      },
    });
  }
}
