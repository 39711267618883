<div class="grid-aside-template py-3">
  <div class="content" id="content">
    <h1 class="mb-4">Come usare Eduxible</h1>
    <div data-bs-spy="scroll" data-bs-smooth-scroll="true">
      <section *ngFor="let section of sections">
        <h2 *ngIf="section.subtitle === false; else subtitle" id={{section.hrefSection}}>{{section.sectionTitle}}</h2>
        <ng-template #subtitle>
          <h3 id={{section.hrefSection}}>{{section.sectionTitle}}</h3>
        </ng-template>
        <p>{{section.sectionText}}</p>
      </section>
    </div>

    <button class="toc-mobile btn btn-primary d-md-none position-fixed"
      type="button" data-bs-toggle="collapse"
      data-bs-target="#toc" aria-expanded="false" aria-controls="collapseMenu"
      id="toc-mobile" (onclick)="openTOC()">
      <svg class="icon">
        <use xlink:href="assets/icons/list.svg#list"
          href="assets/icons/list.svg#list"></use>
      </svg>
    </button>
  </div>
  <div id="toc" class="d-none d-md-block collapseMenu">
    <nav class="toc-menu navbar nav sticky-top pb-5">
      <h3 class="mb-3 ms-2">Domande frequenti</h3>
      <div>
        <div class="nav-item">
          <a *ngFor="let section of sections" class="nav-link p-3" [ngClass]="{'active' : section.selected}" id={{section.idLink}} (click)="selectSection(section.idLink, section)" href="#{{section.hrefSection}}">
            {{section.linkText}}
          </a>
        </div>
      </div>
    </nav>
  </div>
</div>
