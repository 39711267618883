import {
  AdminStatistics,
  CourseFilter,
  CoursePage,
  ProfessorCombo,
  ProfessorStat,
  ProfStatistics,
  UpdatableProfessor,
} from 'src/app/modules/home/home-page/home-components/models/data-model';
import {
  ProfessorFilter,
  ProfessorPage,
} from '../home-components/models/data-model';
import { GenericHttpService } from '../../../shared/services/generic-http.service';
import { Injectable } from '@angular/core';
import { ResourceHttpService } from '../../../shared/services/resource-http.service';
import { Professor } from '../home-components/models/data-model';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfessorService {
  baseURL = '/api';

  constructor(private genericHttpService: GenericHttpService) {}

  // getResourceUrl(): string {
  //   return 'teachers';
  // }

  /**
   *
   * @param page page I want the data of
   * @returns professor list
   */
  getProfessorsList(
    page: number,
    filter: ProfessorFilter
  ): Observable<ProfessorPage> {
    console.log('GET PROFESSOR');
    return this.genericHttpService.httpGet<ProfessorPage>(
      this.baseURL +
        '/professors?page=' +
        page +
        '&size=' +
        environment.size +
        (filter != null ? '&q=' + btoa(JSON.stringify(filter)) : '')
    );
  }

  getProfessorListForCombo(): Observable<ProfessorCombo> {
    console.log('GET Professor COMBO');
    return this.genericHttpService.httpGet<ProfessorCombo>(
      this.baseURL + '/professors/combolist'
    );
    // (
    //   `${this.baseUrl}/courses?page=0`
    // );
  }
  /**
   *
   * @param prof professor I want to add
   * @param isAdmin if isAdmin is true    --> I'm adding an admin user
   *                if isAdmin is false   --> I'm adding an admin user
   * @returns the status code of the api call
   */
  addProfessorOrAdmin(
    prof: UpdatableProfessor,
    isAdmin: boolean
  ): Observable<number> {
    console.log('ADD PROFESSOR', prof);
    return this.genericHttpService
      .httpPost<UpdatableProfessor>(
        `${this.baseURL}/professors?admin=${isAdmin}`,
        prof,
        {
          headers: { 'Content-Type': 'application/json' },
          observe: 'response',
        }
      )
      .pipe(
        map((response: any) => {
          // alert(response.status);
          return response.status;
        })
      );
  }

  /**
   *
   * @param prof professor I want to edit
   * @returns
   */
  editProfessor(id: number, prof: UpdatableProfessor) {
    console.log('EDIT PROFESSOR');
    return this.genericHttpService
      .httpPatch(`${this.baseURL}/professors/${id}`, prof, {
        headers: { 'Content-Type': 'application/json' },
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          console.log(response);
          // alert(response.status);
          return response.status;
        })
      );
  }

  /**
   *
   * @param prof professor I want to delete
   * @returns
   */
  deleteProfessor(prof: Professor) {
    console.log('DELETE PROFESSOR');
    return this.genericHttpService.httpDelete<Professor>(
      `${this.baseURL}/professors/${prof.id}`
    );
  }

  /**
   *
   * @param prof professor I want to restore
   * @returns
   */
  restoreProfessor(prof: Professor) {
    console.log('RESTORE PROFESSOR');
    return this.genericHttpService.httpPost<Professor>(
      `${this.baseURL}/professors/${prof.id}`
    );
  }

  /**
   *
   * @param prof professor I want the course list of
   * @param page page I want the data of
   * @returns all the courses the professor is manager or collaborator of
   */
  getProfessorCourses(profId: number, page: number, filter: CourseFilter) {
    console.log('GET PROFESSOR COURSES');
    return this.genericHttpService.httpGet<CoursePage>(
      `${this.baseURL}/professors/${profId}/courses?page=${page}&size=${environment.size}` +
      (filter != null ? '&q=' + btoa(JSON.stringify(filter)) : '')
    );
  }

  getProfStats() {
    console.log('GET PROF STATs');
    return this.genericHttpService.httpGet<ProfStatistics>(
      `${this.baseURL}/stats/professor/summary`
    );
  }
  getAdminStats() {
    console.log('GET Admin STATs');
    return this.genericHttpService.httpGet<AdminStatistics>(
      `${this.baseURL}/stats/admin/summary`
    );
  }
  getProfessorByID(profID: number): Observable<Professor> {
    console.log('GET PROF BY ID ');
    return this.genericHttpService.httpGet<Professor>(
      `${this.baseURL}/professors/${profID}`
    );
  }
  getAllProfStats() {
    console.log('GET ALL PROF');
    return this.genericHttpService.httpGet<ProfessorStat[]>(
      `${this.baseURL}/stats/professors`
    );
  }
}

