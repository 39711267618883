import {
  CourseFilter,
  CoursePage,
  Student,
  StudentFilter,
  StudentPage,
} from './../../models/data-model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CoursesService } from '../../../services/courses.service';
import { Course } from '../../models/data-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseModalComponent } from 'src/app/modules/shared/components/modals/course-modal/course-modal.component';
import { User } from 'src/app/modules/shared/model/user';
import { StudentService } from '../../../services/student.service';
import { ProfessorService } from '../../../services/professor.service';

@Component({
  selector: 'wave-eduxible-courses-overview-page',
  templateUrl: './courses-overview-page.component.html',
  styleUrls: ['./courses-overview-page.component.scss'],
})


export class CoursesOverviewPageComponent implements OnInit {
  @Output() filterCourse!: CourseFilter;
  filterList!: CourseFilter;
  @Output() raiseFilterCourse = new EventEmitter<CourseFilter>();
  user!: User;

  elencoCorsi: Course[] = [];
  totCorsi!: number;
  elencoCorsiFiltrato: Course[] = [];
  course!: Course;
  currentUser!: User;
  page = 1;
  sizeCurrent: number = environment.sizeCourses;
  elencoStudentiFiltrato: StudentPage[] = [];
  elencoStudenti: Student[] = [];
  totalElements!: number;
  icon: string = '';

  constructor(
    private studentService: StudentService,
    private courseService: CoursesService,
    private profService: ProfessorService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.filterCourse = {};
  }

  changePage($event: number) {
    console.log('Page:', $event);
    this.page = $event;
    
    if (this.currentUser.role === 'Admin') {
      this.getCourseList(this.page - 1, this.filterCourse);
    } else if (this.currentUser.role === 'Professor') {
      this.getProfessorCourseList(this.page - 1, this.filterCourse);
    } else {
      this.getStudentCourseList(this.page - 1, this.filterCourse, this.currentUser.id);
    }
  }

  raiseFormSubmittedCourse($event: CourseFilter) {
    console.log('raise filter CORUSE', $event);
    this.filterCourse = $event;
    console.log('filterCourse', this.filterCourse);
    if (this.currentUser.role === 'Admin') {
      this.getCourseList(0, this.filterCourse);
    } else if (this.currentUser.role === 'Professor') {
      this.getProfessorCourseList(0, this.filterCourse);
    } else {
      this.getStudentCourseList(0, this.filterCourse, this.currentUser.id);
    }
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
    console.log('filterCourse', this.filterCourse);
    if (this.currentUser.role === 'Admin') {
      this.getCourseList(0, this.filterCourse);
    } else if (this.currentUser.role === 'Professor') {
      this.getProfessorCourseList(0, this.filterCourse);
    } else {
      this.getStudentCourseList(0, this.filterCourse, this.currentUser.id);
    }
  }

  getStudentCourseList(page: number, filter: CourseFilter, studentID: number) {
    this.studentService
      .getStudentCoursesList(page, filter, studentID)
      .subscribe({
        next: (courses: CoursePage) => {
          console.log(courses.content);
          console.log('Courses list');
          this.elencoCorsi = courses.content;
          this.totCorsi = courses.totalElements;
        },
      });
  }

  getStudentList(page: number, filter: StudentFilter) {
    this.courseService
      .getCourseStudent(this.course.id, page, filter)
      .subscribe({
        next: (students: StudentPage) => {
          this.elencoStudenti = students.content;
          this.totalElements = students.totalElements;
          console.log('Studenti', this.elencoStudenti);
        },
      });
  }

  getProfessorCourseList(page: number, filter: CourseFilter){
    this.profService.getProfessorCourses(this.currentUser.id, page, filter).subscribe({
      next: (courses: CoursePage) => {
        console.log(courses.content);
        console.log('Courses list', courses);
        this.elencoCorsi = courses.content;
        this.totCorsi = courses.totalElements;
      },
    });
  }

  getCourseList(page: number, filter: CourseFilter) {
    this.courseService.getCoursesList(page, filter).subscribe({
      next: (courses: CoursePage) => {
        console.log(courses.content);
        console.log('Courses list', courses);
        this.elencoCorsi = courses.content;
        this.totCorsi = courses.totalElements;
      },
    });
  }

  goToCourseDetail(course: Course) {    
    localStorage.removeItem('course');
    localStorage.setItem('course', JSON.stringify(course));
    this.router.navigate(['/dettaglio_corso']);
  }

  goToStudentCourseDetail(course: Course) {
    this.router.navigate(['/student-course-detail']);
    //this.courses = JSON.parse(localStorage.getItem('courses')!);
    localStorage.removeItem('course');
    localStorage.setItem('course', JSON.stringify(course));
  }

  openModalCourse() {
    const modalRef = this.modalService.open(CourseModalComponent, {
      size: 'lg',
      centered: true,
    });

    modalRef.componentInstance.course = new Course();
    modalRef.componentInstance.action = 'add';
    modalRef.componentInstance.title = 'Aggiungi corso';
    modalRef.componentInstance.confirmButton = 'Conferma e aggiungi';
    modalRef.result
      .then((courseForm) => {
        window.location.reload();
        console.log('courseForm: ', courseForm);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }


  getPercentage (completedLesson: number, totalLesson: number): number{
    var perc = (completedLesson/totalLesson)*100;
    return perc;

  }

}
