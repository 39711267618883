import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaData } from 'ng-event-bus';
import { environment } from '../../../../environments/environment';
import { User } from '../../shared/model/user';

@Component({
  selector: 'wave-eduxible-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  user!: User;
  constructor(public router: Router, private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user')!);
    console.log('user in home-page', this.user);
    console.log('url', this.router.url);
    if(this.router.url == '/home'){
        this.router.navigate(['/dashboard']);
    }
  }
}
