import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CourseFilter, Professor, Student } from '../../../models/data-model';
import { CoursesService } from 'src/app/modules/home/home-page/services/courses.service';
import { ProfessorService } from "src/app/modules/home/home-page/services/professor.service";


@Component({
  selector: 'wave-eduxible-filter-course',
  templateUrl: './filter-course.component.html',
  styleUrls: ['./filter-course.component.scss']
})
export class FilterCourseComponent implements OnInit {

  form!: FormGroup;
  show = false;
  resetFilterCourse!: CourseFilter;
  formFilter!: CourseFilter;
  filterCourse!: CourseFilter;
  @Input() filterList!: CourseFilter;
  @Output() formSubmittedCourse = new EventEmitter<CourseFilter>();

  dropdownListResponsible: Professor[] = [];
  selectedItemsResponsible: any = [];
  dropdownSettingsResponsible = {};
  dropdownListYear = [
    {id: '2022-2023', text: '2022-2023'},
    {id: '2023-2024', text: '2023-2024'},
    {id: '2024-2025', text: '2024-2025'},
    {id: '2025-2026', text: '2025-2026'},
    {id: '2026-2027', text: '2026-2027'},
    {id: '2027-2028', text: '2027-2028'},
    {id: '2028-2029', text: '2028-2029'},
    {id: '2029-2030', text: '2029-2030'},
    {id: '2030-2031', text: '2030-2031'},
    {id: '2031-2032', text: '2031-2032'}
  ];
  selectedItemsYear: any = [];
  dropdownSettingsYear = {};

  get f() {
    return this.form.controls as any;
  }
  constructor(
    fb: FormBuilder,
    public courseService: CoursesService,
    public professorService: ProfessorService
    ) {
    this.form = fb.group({
      name : new FormControl(),
      active: new FormControl('true'),
      managers: new FormControl(),
      years: new FormControl()
    })
  }

  ngOnInit() {
    if(this.filterList){
      this.filterList.name !== null ? this.f.name.patchValue(this.filterList.name) : this.form.value.name;
      this.filterList.active !== null ? this.f.active.patchValue(this.filterList.active) : this.form.value.active;
      this.filterList.managers !== null ? this.f.managers.patchValue(this.filterList.managers) : this.form.value.managers;
      this.filterList.years !== null ? this.f.years.patchValue(this.filterList.years) : this.form.value.years;
    }
    this.professorService
    .getProfessorsList(0, this.formFilter)
    .subscribe((response: any) => {
      console.log('RESP',response);
      this.dropdownListResponsible = response.content
      this.dropdownListResponsible.forEach(element => {
        element.fullname = element.surname + " " + element.name;
      });
      this.dropdownSettingsResponsible = {
        singleSelection: false,
        idField: 'id',
        textField: 'fullname',
        selectAllText: 'Seleziona tutti',
        searchPlaceholderText: 'Cerca...',
        noFilteredDataAvailablePlaceholderText: 'Nessun dato trovato',
        unSelectAllText: 'Deseleziona tutti',
        itemsShowLimit: 5,
        allowSearchFilter: true,
      };
      this.dropdownSettingsYear = {
        singleSelection: false,
        idField: 'id',
        textField: 'text',
        selectAllText: 'Seleziona tutti',
        unSelectAllText: 'Deseleziona tutti',
        searchPlaceholderText: 'Cerca...',
        noFilteredDataAvailablePlaceholderText: 'Nessun dato trovato',
        itemsShowLimit: 5,
        allowSearchFilter: true,
      };
    });
  }

  onSubmit($event: SubmitEvent) {
    console.log('Submitted course filter', $event);
    let managerIds: number[] = [];
    if(this.selectedItemsResponsible && this.selectedItemsResponsible.length > 0) {
      for(var i=0; i<this.selectedItemsResponsible.length; i++){
        managerIds.push(this.selectedItemsResponsible[i].id);
      }
    }
    let yearIds: string[] = [];
    if(this.selectedItemsYear && this.selectedItemsYear.length > 0) {
      for(var i=0; i<this.selectedItemsYear.length; i++){
        yearIds.push(this.selectedItemsYear[i].id);
      }
    }
    this.filterCourse = {
      name: this.form.value.name,
      active: this.form.value.active,
      managers: managerIds,
      years: yearIds
    }
    console.log('filterCourse in submit: ', this.filterCourse);
    this.formSubmittedCourse.emit(this.filterCourse);
  }
  
  filtriClick(){
    this.show = !this.show;
  }

  resetFilter() {
    this.form.reset();
    this.filterCourse = {}
    this.formSubmittedCourse.emit(this.filterCourse);
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

}
