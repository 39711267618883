import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { User } from 'src/app/modules/shared/model/user';

@Component({
  selector: 'wave-eduxible-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  user!: User;
  id!: string;

  dashboardClicked: boolean = false;
  corsiClicked: boolean = false;
  studentiClicked: boolean = false;
  professoriClicked: boolean = false;
  statisticheClicked: boolean = false;
  utentiClicked: boolean = false;

  paths = {
    dashboard: ['/dashboard'],
    courses: ['/corsi', '/dettaglio_corso', '/lezioni', '/dettaglio_lezione', '/dettaglio_test'],
    students: ['/studenti', '/dettaglio_studenti', '/test_studente'],
    teachers: ['/docenti'],
    stats: ['/statistiche', '/statistiche_studenti', '/statistiche_docenti', '/statistiche_corsi', '/statistiche_avanzate'],
    users: ['/amministratori']
  }

  constructor(public router: Router) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')!);

    if (this.router.url === '/dashboard') {
      this.dashboardClicked = true;
      this.corsiClicked = false;
      this.studentiClicked = false;
      this.professoriClicked = false;
      this.statisticheClicked = false;
      this.utentiClicked = false;
    } else if (this.router.url === '/corsi' || 'dettaglio_corso' || 'lezioni' || 'dettaglio_lezione' || 'dettaglio_test') {
      this.dashboardClicked = false;
      this.corsiClicked = true;
      this.studentiClicked = false;
      this.professoriClicked = false;
      this.statisticheClicked = false;
      this.utentiClicked = false;
    } else if (this.router.url === '/studenti' || 'dettaglio_studenti') {
      this.dashboardClicked = false;
      this.corsiClicked = false;
      this.studentiClicked = true;
      this.professoriClicked = false;
      this.statisticheClicked = false;
      this.utentiClicked = false;
    } else if (this.router.url === '/professori') {
      this.dashboardClicked = false;
      this.corsiClicked = false;
      this.studentiClicked = false;
      this.professoriClicked = true;
      this.statisticheClicked = false;
      this.utentiClicked = false;
    } else if (this.router.url === '/statistiche' || 'statistiche_studenti' || 'statistiche_docenti' || 'statistiche_corsi' || 'statistiche_avanzate') {
      this.dashboardClicked = false;
      this.corsiClicked = false;
      this.studentiClicked = false;
      this.professoriClicked = false;
      this.statisticheClicked = true;
      this.utentiClicked = false;
    } else if (this.router.url === '/utenti') {
      this.dashboardClicked = false;
      this.corsiClicked = false;
      this.studentiClicked = false;
      this.professoriClicked = false;
      this.statisticheClicked = false;
      this.utentiClicked = true;
    }
  }
}
