export interface NotificationConfig {
  tipo: DlgType;
  titolo: string;
  codErrore: number;
  messaggio: string;
  cancelVisible: boolean;
  okLabel: string;
  cancelLabel: string;
  show?: boolean;
  // azione eseguita quando utente preme ok
  // okHandler: () => void;

}

export enum DlgType {
  INFO = "info",
  WARNING = "warning",
  ERROR = "danger",
  RESTORE = "restore",
  SUCCESS = "success",
}
