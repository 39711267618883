
<div *ngIf="dialogVisible" class="modal " tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title w-100 text-center">{{config?.titolo}}</h3>
      </div>
      <div class="modal-body pt-2 pb-2">
        <span [ngSwitch]="config?.userType">
          <div *ngSwitchCase="'student'" class="text-center">
            {{config?.messaggio}} i dati relativi allo studente, compresi i
            risultati dei test sostenuti
          </div>
          <div *ngSwitchCase="'professor'" class="text-center">
            {{config?.messaggio}} i dati del docente
          </div>
          <div *ngSwitchCase="'course'" class="text-center">
            {{config?.messaggio}} i dati relativi al corso, comprese le lezioni
            e i risultati dei test sostenuti
          </div>
          <div *ngSwitchCase="'lesson'" class="text-center">
            {{config?.messaggio}} i dati relativi alla lezione <span
              class="fw-bold">"{{config?.lesson}}"</span>.
          </div>
          <div *ngSwitchCase="'course-remove'" class="text-center">
            Verranno eliminati tutti i progressi dello studente relativi a questo corso
          </div>
        </span>
      </div>
      <div class="modal-footer">
        <button type="submit" class=" btn d-flex
          align-items-center" [ngClass] ="config?.userType !== 'lesson-restore' ? 'btn-outline-secondary' : 'btn-outline-secondary'"
          (click)="close(false)">
          <svg class="icon me-2">
            <use xlink:href="assets/icons/annulla.svg#annulla"
              href="assets/icons/annulla.svg#annulla"></use>
          </svg>{{config?.cancelLabel}}
        </button>
        <button type="submit" [ngClass]="'btn-'+config?.tipo" class="btn text-white btn-primary" (click)="close(true)">
          <svg class="icon me-2">
            <use xlink:href="assets/icons/confirm.svg#confirm"
              href="assets/icons/confirm.svg#confirm"></use>
          </svg>{{config?.okLabel}}
        </button>
        <span [ngSwitch]="config?.userType">
          <div *ngSwitchCase="'student'" class="mt-4 text-center small">
            {{config?.extraText}} lo studente
          </div>
          <div *ngSwitchCase="'professor'" class="mt-4 text-center small">
            {{config?.extraText}} il docente
          </div>
          <div *ngSwitchCase="'course'" class="mt-4 text-center small">
            {{config?.extraText}} il corso e i suoi contenuti
          </div>
          <div *ngSwitchCase="'lesson'" class="mt-4 text-center small">
            {{config?.extraText}} la lezione e i suoi contenuti.
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
