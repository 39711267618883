import {
  CourseFilter,
  CoursePage,
  NewStudent,
  StudentFilter,
  StudentStat,
  StudentTestStatsPage,
  UpdatableStudent,
} from './../home-components/models/data-model';
import { GenericHttpService } from './../../../shared/services/generic-http.service';
import { Injectable } from '@angular/core';
import { Student, StudentPage } from '../home-components/models/data-model';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  baseURL = '/api';

  constructor(private genericHttpService: GenericHttpService) {}

  getStudentsList(
    page: number,
    filter: StudentFilter
  ): Observable<StudentPage> {
    console.log('GET STUDENTS');
    return this.genericHttpService.httpGet<StudentPage>(
      this.baseURL +
        '/students?page=' +
        page +
        '&size=' +
        environment.size +
        (filter != null ? '&q=' + btoa(JSON.stringify(filter)) : '')
    );
    // (`${this.baseURL}/students?page=${page}&size=${environment.size}`);
  }



  addStudent(student: NewStudent): Observable<number> {
    console.log('ADD STUDENT', student);

    return this.genericHttpService
      .httpPost<NewStudent>(`${this.baseURL}/students`, student, {
        headers: { 'Content-Type': 'application/json' },
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          // alert(response.status);
          return response.status;
        })
      );
  }

  /**
   *
   * @param student student I want to restore
   * @returns
   */
  restoreStudent(student: Student) {
    console.log('RESTORE STUDENT');
    return this.genericHttpService.httpPost<Student>(
      `${this.baseURL}/students/${student.id}`
    );
  }

  /**
   *
   * @param student student I want to edit
   * @returns
   */
  editStudent(id: number, student: UpdatableStudent) {
    console.log('EDIT STUDENT');
    return this.genericHttpService
      .httpPatch(`${this.baseURL}/students/${id}`, student, {
        headers: { 'Content-Type': 'application/json' },
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          console.log(response);
          // alert(response.status);
          return response.status;
        })
      );
  }
  getStudentCoursesList(page: number, filter: CourseFilter, studentID: number): Observable<CoursePage> {
    console.log('GET COURSES');
    return this.genericHttpService.httpGet<CoursePage>(
      this.baseURL +
      `/students/${studentID}/courses?page=` +
        page +
        '&size=' +
        environment.size +
        (filter != null ? '&q=' + btoa(JSON.stringify(filter)) : '')
    );
  }
  /**
   *
   * @param student student I want to delete
   * @returns
   */
  deleteStudent(student: Student) {
    console.log('DELETE STUDENT');
    return this.genericHttpService.httpDelete<Student>(
      `${this.baseURL}/students/${student.id}`
    );
  }

  getStudentCourses(studentId: number, page: number, filter: CourseFilter) {
    console.log('STUDENT COURSES');
    return this.genericHttpService.httpGet<CoursePage>(
      this.baseURL +
        `/students/${studentId}/courses&page=` +
        page +
        '&size=' +
        environment.size +
        (filter != null ? '&q=' + btoa(JSON.stringify(filter)) : '')
    );
  }
  getStudentStats(studentId: number, page: number) {
    console.log('STUDENT STATS');
    return this.genericHttpService.httpGet<StudentTestStatsPage>(
      this.baseURL +
        `/students/${studentId}/lessons/tests?page=` +
        page +
        '&size=' +
        environment.size
    );
  }
  getAllStudStats() {
    console.log('GET ALL STUDENTS');
    return this.genericHttpService.httpGet<StudentStat[]>(
      `${this.baseURL}/stats/students`
    );
  }
}
