<h1 class="my-4">Statistiche</h1>
<div class="row g-3">
  <div class="col-12 col-md-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <svg class="icon icon-lg icon-teal me-3 ms-2">
          <use
            xlink:href="assets/icons/student.svg#student"
            href="assets/icons/student.svg#student"></use>
        </svg>
        <button role="link" routerLink="/statistiche_studenti"
          id="courseProgress"
          class="btn nav-link btn-link d-inline stretched-link text-decoration-none mt-3">
          <p class="fs-2">Studenti<p>
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-xl-3" *ngIf="user.role != 'Professor'">
    <div class="card" >
      <div class="card-body">
        <svg class="icon icon-lg icon-teal me-3 ms-2">
          <use
            xlink:href="assets/icons/teacher.svg#teacher"
            href="assets/icons/teacher.svg#teacher"></use>
        </svg>
        <button role="link" routerLink="/statistiche_docenti"
          id="courseProgress"
          class="btn nav-link btn-link d-inline stretched-link text-decoration-none mt-3">
          <p class="fs-2">Docenti<p>
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <svg class="icon icon-lg icon-teal me-3 ms-2">
          <use
            xlink:href="assets/icons/course.svg#course"
            href="assets/icons/course.svg#course"></use>
        </svg>
        <button role="link" routerLink="/statistiche_corsi"
          id="courseProgress"
          class="btn nav-link btn-link d-inline stretched-link text-decoration-none mt-3">
          <p class="fs-2">Corsi<p>
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-xl-3"  *ngIf="user.role != 'Professor'">
    <div class="card" >
      <div class="card-body">
        <svg class="icon icon-lg icon-teal me-3 ms-2">
          <use
            xlink:href="assets/icons/statistics.svg#statistics"
            href="assets/icons/statistics.svg#statistics"></use>
        </svg>
        <button role="link" routerLink="/statistiche_avanzate"
          id="courseProgress"
          class="btn nav-link btn-link d-inline stretched-link text-decoration-none mt-3">
          <p class="fs-2">Avanzate<p>
        </button>
      </div>
    </div>
  </div>
</div>
