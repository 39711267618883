import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GenericHttpService } from 'src/app/modules/shared/services/generic-http.service';
import { environment } from 'src/environments/environment';
import { Answer, Question, QuestionPage } from '../home-components/models/data-model';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  baseURL = '/api';

  constructor(private genericHttpService: GenericHttpService) { }
  
  getQuestionList(courseID: number, lessonID: number): Observable<QuestionPage> {
    console.log('GET QUestions');
    return this.genericHttpService.httpGet<QuestionPage>(
      `${this.baseURL}/courses/${courseID}/lessons/${lessonID}/questions`
    );
  }

  addQuestion(courseID: number, lessonID: number, question: Question) {
    console.log('ADD QUESTION');
    return this.genericHttpService.httpPost<Question>(
      `${this.baseURL}/courses/${courseID}/lessons/${lessonID}/questions`,
      question,
        {
          headers: { 'Content-Type': 'application/json' },
          observe: 'response',
        }
      )
      .pipe(
        map((response: any) => {
          // alert(response.status);
          return response.status;
        })
      );
  }

  editQuestion(courseID: number, lessonID: number, question: Question) {
    console.log('ADD QUESTION');
    return this.genericHttpService.httpPatch(
      `${this.baseURL}/courses/${courseID}/lessons/${lessonID}/questions/${question.id}`,
      question,
        {
          headers: { 'Content-Type': 'application/json' },
          observe: 'response',
        }
      )
      .pipe(
        map((response: any) => {
          // alert(response.status);
          return response.status;
        })
      );
  }

  deleteQuestion(courseID: number, lessonID: number, questionId: number) {
    console.log('DELETE QUESTION');
    return this.genericHttpService.httpDelete<any>(
      this.baseURL+ '/courses/' + courseID + '/lessons/' + lessonID + '/questions/' + questionId
    );
  }

  deleteAnswer(courseID: number, lessonID: number, questionId: number, answerId: number) {
    console.log('DELETE QUESTION');
    return this.genericHttpService.httpDelete<any>(
      this.baseURL+ '/courses/' + courseID + '/lessons/' + lessonID + '/questions/' + questionId + '/answers/' + answerId
    );
  }

  restoreQuestion(courseID: number, lessonID: number, questionId: number) {
    console.log('RESTORE QUESTION');
    return this.genericHttpService.httpPost<Question>(
      `${this.baseURL}/courses/${courseID}/lessons/${lessonID}/questions/${questionId}`
    );
  }

}
