import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { viewport } from '@popperjs/core';

@Component({
  selector: 'wave-eduxible-information-page',
  templateUrl: './information-page.component.html',
  styleUrls: ['./information-page.component.scss'],
})
export class InformationPageComponent implements OnInit {
  utente!: string;
  tap = false;

  sec: string = '';

  sections: {
    idLink: string;
    linkText: string;
    hrefSection: string;
    selected: boolean;
    subtitle: boolean;
    sectionTitle: string;
    sectionText: string;
  }[] = [
    {
      idLink: 'link_come_funziona',
      linkText: 'Come funziona la piattaforma',
      hrefSection: 'come_funziona',
      selected: false,
      subtitle: false,
      sectionTitle: 'Come funziona la piattaforma',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      idLink: 'link_corsi_lezioni',
      linkText: 'Corsi e lezioni',
      hrefSection: 'corsi_lezioni',
      selected: false,
      subtitle: false,
      sectionTitle: 'Corsi e lezioni',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      idLink: 'link_creare_lezione',
      linkText: 'Come creare una lezione',
      hrefSection: 'creare_lezione',
      selected: false,
      subtitle: true,
      sectionTitle: 'Come creare una lezione',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      idLink: 'link_creare_test',
      linkText: 'Come creare un test',
      hrefSection: 'creare_test',
      selected: false,
      subtitle: true,
      sectionTitle: 'Come creare un test',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      idLink: 'link_assegna_corsi',
      linkText: 'Assegnare corsi agli studenti',
      hrefSection: 'assegna_corsi',
      selected: false,
      subtitle: true,
      sectionTitle: 'Assegnare corsi agli studenti',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      idLink: 'link_studenti',
      linkText: 'Studenti',
      hrefSection: 'studenti',
      selected: false,
      subtitle: false,
      sectionTitle: 'Studenti',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      idLink: 'link_aggiungi_studente',
      linkText: 'Come aggiungere un nuovo studente',
      hrefSection: 'aggiungi_studente',
      selected: false,
      subtitle: true,
      sectionTitle: 'Come aggiungere un nuovo studente',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      idLink: 'link_modifica_studente',
      linkText: 'Modificare / eliminare uno studente',
      hrefSection: 'modifica_studente',
      selected: false,
      subtitle: true,
      sectionTitle: 'Modificare / eliminare uno studente',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      idLink: 'link_docenti',
      linkText: 'Docenti',
      hrefSection: 'docenti',
      selected: false,
      subtitle: false,
      sectionTitle: 'Docenti',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      idLink: 'link_aggiungi_docente',
      linkText: 'Come aggiungere un nuovo docente',
      hrefSection: 'aggiungi_docente',
      selected: false,
      subtitle: true,
      sectionTitle: 'Come aggiungere un nuovo docente',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      idLink: 'link_modifica_docente',
      linkText: 'Modificare / eliminare un docente',
      hrefSection: 'modifica_docente',
      selected: false,
      subtitle: true,
      sectionTitle: 'Modificare / eliminare un docente',
      sectionText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
  ];

  constructor(public router: Router) {}
  ngOnInit(): void {
    this.utente = localStorage.getItem('utente')!;
  }
  openTOC(this: any) {
    var menu = document.getElementById('toc');
    this.tap = !this.tap;

    if (this.tap === true) {
      menu?.classList.remove('d-none');
    }
  }

  selectSection(idSec: string, section: any) {
    this.sec = idSec;
    console.log('sec:' + this.sec);
    this.sections.forEach((s) => {
      if (s === section) {
        s.selected = !s.selected;
      } else {
        s.selected = false;
        // this.sec = '';
      }
    });
  }
}
