import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeWhile } from 'rxjs';
import { UpdatableProfessor } from 'src/app/modules/home/home-page/home-components/models/data-model';
import { ProfessorService } from 'src/app/modules/home/home-page/services/professor.service';
import { DlgType } from '../w-notification/models/notification-models';
import { DialogConfig } from './models/dialog-models';
import { WDialogService } from './services/w-dialog.service';

@Component({
  selector: 'wave-eduxible-w-dialog',
  templateUrl: './w-dialog.component.html',
  styleUrls: ['./w-dialog.component.scss']
})
export class WDialogComponent implements OnInit {

  private subscribed: boolean = true;
  config: DialogConfig | undefined;
  dialogVisible: boolean = false;
  dialogForm!: FormGroup;
  constructor(private dialogService: WDialogService,
              private professorService: ProfessorService){}

  ngOnInit(): void {
    this.dialogForm = new FormGroup({
      nome: new FormControl('', Validators.required),
      cognome: new FormControl('', Validators.required)
    });
    this.dialogService.configObs
      .pipe(takeWhile(() => this.subscribed))
      .subscribe((config) => {
        // console.log('subscribe');
        if (config) {
          this.open(config);
        }
      });
  }

  private open(config: DialogConfig) {
    this.config = {
      tipo: config.tipo ?? '',
      titolo: config.titolo ?? '',
      userType: config.userType ?? 'student',
      messaggio: config.messaggio ?? 'Verranno eliminati tutti',
      cancelVisible: config.cancelVisible ?? false,
      okLabel: config.okLabel ?? 'Conferma',
      cancelLabel: config.cancelLabel ?? 'Annulla',
      extraText: config.extraText ?? 'Una volta confermato, solo un utente amministratore potrà ripristinare',
      lesson: config.lesson ?? '',
      okHandler: config.okHandler ?? (() => {})
    } as DialogConfig;
    this.dialogVisible = true;
  }

  close(okClicked: boolean){
    if (okClicked) {
      this.config!.okHandler();
    }
    this.dialogVisible = false;
  }
}
