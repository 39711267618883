<div class="d-flex mb-3">
  <img *ngIf="course.icon && course.icon !== ''; else defaultIcon" width="48px"
    height="48px"
    [src]="'assets/icons/subjects/' + icon">
  <ng-template #defaultIcon>
    <img width="48px" height="48px" src="assets/icons/subjects/default.png">
  </ng-template>
  <h1 class="ms-2">{{course.name}}</h1>
</div>

<p>Lezione {{detail.progressiveNumber}}</p>
<h2 class="mb-3 mvb-sm-1">{{detail.title}}</h2>
<div *ngIf="noQuiz" class="mb-3">Al momento il test non è disponibile.</div>
<div id="surveyElement" class="survey-container contentcontainer codecontainer">
  <survey [model]="model"></survey>
</div>

<!--   <a class="btn btn-outline-primary me-4 align-items-center" href="/dettaglio_corso">
    <svg class="icon me-2">
      <use xlink:href="assets/icons/annulla.svg#annulla" href="assets/icons/annulla.svg#annulla"></use>
    </svg>
    Esci dal quiz
  </a> -->
