import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseModalComponent } from 'src/app/modules/shared/components/modals/course-modal/course-modal.component';
import { User } from 'src/app/modules/shared/model/user';
import { environment } from 'src/environments/environment';
import { CoursesService } from '../../../services/courses.service';
import { ProfessorService } from '../../../services/professor.service';
import { StudentService } from '../../../services/student.service';
import { Chart } from 'chart.js';
import {
  CourseFilter,
  Course,
  StudentPage,
  Student,
  CoursePage,
  StudentFilter,
  Professor,
  ProfStatistics,
  AdminStatistics,
  TrendStat,
} from '../../models/data-model';
import { StatService } from '../../../services/stat.service';

@Component({
  selector: 'wave-eduxible-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit {
  @Output() filterCourse!: CourseFilter;
  filterList!: CourseFilter;
  @Output() raiseFilterCourse = new EventEmitter<CourseFilter>();
  user!: User;
  elencoCorsi: Course[] = [];
  totCorsi!: number;
  elencoCorsiFiltrato: Course[] = [];
  course!: Course;
  currentUser!: User;
  public chart: any;
  page = 1;
  sizeCurrent: number = environment.size;
  elencoStudentiFiltrato: StudentPage[] = [];
  elencoStudenti: Student[] = [];
  totalElements!: number;
  ManagerOf!: Professor;
  filterStud!: StudentFilter;
  profStats!: ProfStatistics;
  adminStats!: AdminStatistics;

  constructor(
    private studentService: StudentService,
    private courseService: CoursesService,
    private router: Router,
    private modalService: NgbModal,
    private profService: ProfessorService,
    private statService: StatService
  ) {
    this.elencoCorsi = [];
  }

  createChart(labelsArray: string[], totArray: number[], okArray: number[]) {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart('TrendChart', {
      type: 'line', //this denotes tha type of chart

      data: {
        // values on X-Axis
        labels: labelsArray,//['5-Apr', '6-Apr', '7-Apr', '8-Apr', '9-Apr', '10-Apr', '11-Apr','5-Apr', '6-Apr', '7-Apr', '8-Apr', '9-Apr', '10-Apr', '11-Apr','5-Apr', '6-Apr', '7-Apr', '8-Apr', '9-Apr', '10-Apr', '11-Apr'],
        datasets: [
          {
            label: 'Test eseguiti',
            data: totArray,// [65, 59, 80, 81, 56, 55, 40,65, 59, 80, 81, 56, 55, 40,65, 59, 80, 81, 56, 55, 40],
            borderColor: ['rgb(0, 129, 143)'],
            backgroundColor: ['rgb(0, 129, 143, 0.2)'],
            //fill: true
          },
          {
            label: 'Test superati',
            data: okArray, // [25, 39, 74, 61, 50, 55, 30,25, 39, 74, 61, 50, 55, 30,25, 39, 74, 61, 50, 55, 30],
            borderColor: ['rgb(116, 162, 18)'],
            backgroundColor: ['rgb(116, 162, 18, 0.2)'],
            fill: true
          }
        ]
      },
      options: {
        aspectRatio: 2.5,
      },
    });
  }

  changePage($event: number) {
    console.log('Page:', $event);
    this.page = $event;
    this.getCourseList(this.page - 1, this.filterCourse);
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
    if (this.currentUser.role !== 'Student') {
      this.getCourseList(0, this.filterCourse);
    } else {
      this.getStudentCourseList(0, this.filterCourse, this.currentUser.id);
    }
    if (this.currentUser.role === 'Professor') {
         this.getProfStats();
    } else if (this.currentUser.role === 'Admin'){
      this.getAdminStats();
    }
    this.statService.getTrendStat(15, 'DAY').subscribe({
      next: (res: any) => {
        let labelsArray: string[] = [];
        let totArray: number[] = [];
        let okArray: number[] = [];
        for(var i=0; i<res.items.length; i++){
          labelsArray.push(res.items[i].label);
          totArray.push(res.items[i].numOfTests);
          okArray.push(res.items[i].numOfTestsPassed);
          this.createChart(labelsArray, totArray, okArray);
        }
      }
    })
  }

  getStudentCourseList(page: number, filter: CourseFilter, studentID: number) {
    this.studentService
      .getStudentCoursesList(page, filter, studentID)
      .subscribe({
        next: (courses: CoursePage) => {
          console.log(courses.content);
          console.log('Courses list');
          this.elencoCorsi = courses.content;
          this.totCorsi = courses.totalElements;
        },
      });
  }

  getStudentList(page: number, filter: StudentFilter) {
    this.courseService
      .getCourseStudent(this.course.id, page, filter)
      .subscribe({
        next: (students: StudentPage) => {
          this.elencoStudenti = students.content;
          this.totalElements = students.totalElements;
          console.log('Studenti', this.elencoStudenti);
        },
      });
  }

  getCourseList(page: number, filter: CourseFilter) {
    this.courseService.getCoursesList(page, filter).subscribe({
      next: (courses: CoursePage) => {
        console.log(courses.content);
        console.log(courses.totalElements);
        console.log('Courses list');
        this.elencoCorsi = courses.content;
        this.totCorsi = courses.totalElements;
      },
    });
  }

  goToCourseDetail(course: Course) {
    //this.courses = JSON.parse(localStorage.getItem('courses')!);
    localStorage.removeItem('course');
    localStorage.setItem('course', JSON.stringify(course));
    this.router.navigate(['/dettaglio_corso']);
  }

  goToStudentCourseDetail(course: Course) {
    //this.courses = JSON.parse(localStorage.getItem('courses')!);
    localStorage.removeItem('course');
    localStorage.setItem('course', JSON.stringify(course));
    this.router.navigate(['/student-course-detail']);
  }

  openModalCourse() {
    const modalRef = this.modalService.open(CourseModalComponent, {
      size: 'lg',
      centered: true,
    });

    modalRef.componentInstance.course = new Course();
    modalRef.componentInstance.action = 'add';
    modalRef.componentInstance.title = 'Aggiungi corso';
    modalRef.componentInstance.confirmButton = 'Conferma e aggiungi';
    modalRef.result
      .then((courseForm) => {
        window.location.reload();
        console.log('courseForm: ', courseForm);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  getPercentage(completedLesson: number, totalLesson: number): number {
    var perc = (completedLesson / totalLesson) * 100;
    return perc;
  }

  getProfStats(){
    this.profService.getProfStats() .subscribe({
      next: (res: ProfStatistics) => {
        console.log('StatsProf', res);
        this.profStats = res;
      },
    });
  }

  getAdminStats(){
    this.profService.getAdminStats() .subscribe({
      next: (res: AdminStatistics) => {
        console.log('StatAdmin', res);
        this.adminStats = res;
        this.getProfStats();
      },
    });
  }
}
