import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { WDialogService } from 'src/app/modules/shared/components/w-dialog/services/w-dialog.service';
import { DlgType } from 'src/app/modules/shared/components/w-notification/models/notification-models';
import { FileUploadService } from '../../../services/file-upload.service';
import { LessonsService } from '../../../services/lessons.service';
import { Course, Lesson, LessonDetail, LessonPage, Professor } from '../../models/data-model';
import { Location } from '@angular/common';

@Component({
  selector: 'wave-eduxible-lesson-page',
  templateUrl: './lesson-page.component.html',
  styleUrls: ['./lesson-page.component.scss']
})
export class LessonPageComponent implements OnInit{

  course!: Course;
  defaultProf!: Professor;
  elencoLezioni!: LessonDetail[];
  elencoLezioniEliminate!: LessonDetail[];
  totalElements!: number;
  lesson!: Lesson;

  // Variable to store shortLink from api response
  shortLink: string = "";
  loading: boolean = false; // Flag variable
  file!: File; // Variable to store file

  constructor(
    private router: Router,
    private fileUploadService: FileUploadService,
    private lessonService: LessonsService,
    private dialogService: WDialogService,
    public location: Location) {
  }
  ngOnInit() {
    this.course = JSON.parse(localStorage.getItem('course')!);
    this.getLessonList(0);
  }

  getLessonList(page: number) {
    this.lessonService.getLessonList(page, this.course.id).subscribe({next:(lessons: LessonPage)=>{
      this.elencoLezioni = lessons.content
      .filter((l)=>{
        return !l.deleted;
      });
      this.elencoLezioniEliminate = lessons.content
      .filter((l)=>{
        return l.deleted;
      });
      this.totalElements = lessons.totalElements;
      console.log('Lezioni', this.elencoLezioni);
      console.log('Lezioni Eliminate', this.elencoLezioniEliminate);
    }})
  }

  goToLessonModify(lessonID: number){
    console.log('LESSON ID', lessonID);
    this.lessonService.getLessonDetail(this.course.id, lessonID).subscribe((lesson) => {
      localStorage.removeItem('lesson');
      localStorage.removeItem('mode');
      localStorage.setItem('lesson', JSON.stringify(lesson));
      this.router.navigate(['/dettaglio_lezione'], { state: {countLess: 0}})
    })
  }
  drop(event: CdkDragDrop<any[]>, elenco: LessonDetail[]) {
    moveItemInArray(elenco, event.previousIndex, event.currentIndex);
  }

  addLesson(){
    localStorage.removeItem('lesson');
    this.router.navigate(['/dettaglio_lezione'], { state: {countLess: this.elencoLezioni.length}})
  }

  deleteLesson(course: Course, lesson: LessonDetail){
    console.log(lesson);
    this.dialogService.open({
      titolo: `Vuoi eliminare questa lezione?`,
      userType: 'lesson',
      dlgType: 'delete',
      lesson: lesson.title,
      tipo: DlgType.ERROR,
      cancelVisible: true,
      okHandler: () => {
        this.lessonService.deleteLesson(course.id, lesson.id).subscribe( {
          next: (res) =>{ window.location.reload();}
        }
        );
      },
    });
  }
  restoreLesson(course:Course, lesson: LessonDetail) {
    this.dialogService.open({
      titolo: `Vuoi ripristinare ${lesson.title}?`,
      userType: 'lesson-restore',
      dlgType: 'restore',
      tipo: DlgType.RESTORE,
      // messaggio: '',
      cancelVisible: true,
      okHandler: () => {
        this.lessonService.restoreLesson(course.id, lesson.id).subscribe( {
          next: (res) =>{ window.location.reload();}
        }
        );
      },
    });
  }

  goToLessonDetail(lessonID: number){
    console.log('LESSON ID', lessonID);
    this.lessonService.getLessonDetail(this.course.id, lessonID).subscribe((lesson) => {
      localStorage.removeItem('lesson');
      localStorage.setItem('lesson', JSON.stringify(lesson));
      localStorage.removeItem('mode');
      localStorage.setItem('mode', 'view');
      this.router.navigate(['/dettaglio_lezione'], { state: {countLess: 0}})
    })
  }

  goToTest(lesson: LessonDetail) {
    localStorage.removeItem('lesson');
    localStorage.setItem('lesson', JSON.stringify(lesson));
    this.router.navigate(['/dettaglio_test'], { state: {countLess: 0}});
  }

  back() {
    this.location.back();
  }
}
