<div class="mb-4 mb-md-5 ">
  <button (click)="filtriClick()" type="button"
    [ngClass]="show ? 'btn btn-link mt-3 mb-2 px-0 active d-flex align-items-center' : 'btn btn-link mt-3 mb-2 px-0 d-flex align-items-center'">
    <svg class="icon me-2">
      <use xlink:href="assets/icons/filter.svg#filter" href="assets/icons/filter.svg#filter"></use>
    </svg>Filtri
  </button>

  <ng-template [ngIf]="show">
    <form [formGroup]="form" (submit)="onSubmit($event)" class="mt-2">
      <div class="row row-cols-1 row-cols-md-3 from-group g-4 mb-4">
        <div class="col">
          <label for="NomeCorso" class="form-label">Nome corso</label>
          <input id="NomeCorso" type="text" class="form-control" formControlName="name" />
        </div>
        <div class="col">
          <label id="collab" class="form-label">Docenti assegnati</label>
          <ng-multiselect-dropdown [placeholder]="'Seleziona uno o più docenti'"
            [settings]="dropdownSettingsResponsible" [data]="dropdownListResponsible"
            [(ngModel)]="selectedItemsResponsible" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
            formControlName="managers">
          </ng-multiselect-dropdown>
        </div>
        <div class="col">
          <label id="collab" class="form-label">Anno scolastico</label>
          <ng-multiselect-dropdown [placeholder]="'Tutti'" [settings]="dropdownSettingsYear" [data]="dropdownListYear"
            [(ngModel)]="selectedItemsYear" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
            formControlName="years">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div>
        <p class="form-label">Stato corso</p>
        <div class="form-check form-check-inline">
          <input class="form-check-input " type="radio" id="activechk" value="true" formControlName="active">
          <label class="form-check-label" for="activechk">
            Attivo
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="nonactivechk" value="false" formControlName="active">
          <label class="form-check-label" for="nonactivechk">
            Non attivo
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="allchk" value="" formControlName="active">
          <label class="form-check-label" for="allchk">
            Tutti
          </label>
        </div>
      </div>
      <div class="d-flex mt-4">
        <button (click)="resetFilter()" type="button" class="btn btn-sm
          btn-outline-secondary reset">
          Azzera
        </button>
        <button class="btn btn-sm btn-primary ms-2" type="submit">
          Filtra
        </button>
      </div>
    </form>
  </ng-template>
</div>