import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Chart } from 'chart.js';
import { CoursesService } from '../../../../services/courses.service';
import {
  Course,
  CourseFilter,
  CourseFilterStats,
  CoursePage,
  CourseStat,
} from '../../../models/data-model';
import { Location } from '@angular/common';

@Component({
  selector: 'wave-eduxible-stat-courses',
  templateUrl: './stat-courses.component.html',
  styleUrls: ['./stat-courses.component.scss'],
})
export class StatCoursesComponent implements OnInit {
  course!: Course;
  filterCourses!: CourseFilter;
  elencoCorsi: Course[] = [];
  myLabels: string[] = [];
  totalLessons!: number;
  public chart: any;
  myDataSet: any = [];
  selectGrandezza!: any;
  //per filtri
  form!: FormGroup;
  show = false;
  resetFilterCourse!: CourseFilterStats;
  formFilter!: CourseFilterStats;
  filterCourse!: CourseFilterStats;
  courseStats!: CourseStat[];
  courseStatsFiltered!: CourseStat[];
  @Input() filterList!: CourseFilterStats;
  @Output() formSubmittedCourse = new EventEmitter<CourseFilterStats>();
  dropdownListCourses: Course[] = [];
  selectedItemsCourses: any = [];
  dropdownSettingsCourses = {};
  //fine
  createChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart('CoursesChart', {
      type: 'bar', //this denotes tha type of chart

      data: {
        // values on X-Axis
        labels: this.myLabels,
        datasets: [
          {
            label: '',
            data: this.myDataSet,
            backgroundColor: ['rgb(0, 129, 143)'],
            borderColor: ['rgb(0, 129, 143)'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        aspectRatio: 2.5,
        plugins: {
          legend: {
            display: false
          }
        }
      },
    });
  }
  constructor(private courseService: CoursesService, fb: FormBuilder, public location: Location) {
    this.filterCourse = {};
    this.filterCourse.active = true;
    this.filterCourse.courses = [];
    this.form = fb.group({
      active: new FormControl('true'),
      courses: new FormControl(),
    });
  }
  get f() {
    return this.form.controls as any;
  }
  ngOnInit(): void {
    this.selectGrandezza = 'totCollab';
    this.getAllCourseStats(this.selectGrandezza, null);
    if (this.filterList) {
      this.filterList.active !== null
        ? this.f.active.patchValue(this.filterList.active)
        : this.form.value.active;
      this.filterList.courses !== null
        ? this.f.courses.patchValue(this.filterList.courses)
        : this.form.value.courses;
    }

    this.courseService
      .getCoursesList(0, this.formFilter)
      .subscribe((response: any) => {
        console.log('RESP', response);
        this.dropdownListCourses = response.content;
        this.dropdownListCourses.forEach((element) => {
          element.name;
        });
        this.dropdownSettingsCourses = {
          singleSelection: false,
          idField: 'id',
          textField: 'name',
          searchPlaceholderText: 'Cerca...',
          noFilteredDataAvailablePlaceholderText: 'Nessun dato trovato',
          selectAllText: 'Seleziona tutti',
          unSelectAllText: 'Deseleziona tutti',
          itemsShowLimit: 5,
          allowSearchFilter: true,
        };
      });
  }

  getAllCourseStats(value: string, filter: any) {
    this.courseService.getAllCourseStats().subscribe({
      next: (res: any) => {
        console.log('StatsCOURSES', res);
        this.courseStats = res.content;
        this.myDataSet = [];
        this.myLabels = [];
        this.courseStatsFiltered = [];
        this.courseStats.forEach((l) => {
          l.avgProgress = Math.round(l.avgProgress);
          l.avgScore = Math.round(l.avgScore) / 10;

          if (filter !== null) {
            if (filter.active == 'true') {
              if (!l.active) {
                return;
              }
            } else if (filter.active == 'false') {
              if (l.active) {
                return;
              }
            }
            if (filter.courses !== null && filter.courses.length > 0) {
              if (!filter.courses.find((m: number) => l.id == m)) {
                return;
              }
            }
          }
          this.myLabels.push(l.name);
          if (value === 'totCollab') {
            this.myDataSet.push(l.totalCollaborators);
            this.courseStatsFiltered.push(l);
          }
          if (value === 'totStud') {
            this.myDataSet.push(l.totalStudents);
            this.courseStatsFiltered.push(l);
          }
          if (value === 'totLess') {
            this.myDataSet.push(l.totalLessons);
            this.courseStatsFiltered.push(l);
          }
          if (value === 'avanz') {            
            this.myDataSet.push(l.avgProgress);
            this.courseStatsFiltered.push(l);
          }
          if (value === 'puntMed') {
            this.myDataSet.push(l.avgScore);
            this.courseStatsFiltered.push(l);
          }
        });
        console.log(this.myLabels);
        this.createChart();
      },
    });
  }

  onSelect(value: string) {
    this.selectGrandezza = value;
    console.log(this.selectGrandezza);
    this.getAllCourseStats(
      value,
      this.filterCourse.active !== null || this.filterCourse.courses !== null
        ? this.filterCourse
        : null
    );
  }

  filtriClick() {
    this.show = !this.show;
  }

  onSubmit($event: SubmitEvent) {
    console.log('Submitted course filter', $event);
    let coursesIds: number[] = [];
    if (this.selectedItemsCourses && this.selectedItemsCourses.length > 0) {
      for (var i = 0; i < this.selectedItemsCourses.length; i++) {
        coursesIds.push(this.selectedItemsCourses[i].id);
      }
    }
    this.filterCourse = {
      active: this.form.value.active,
      courses: coursesIds,
    };
    console.log('filterCourse in submit: ', this.filterCourse);
    this.formSubmittedCourse.emit(this.filterCourse);
    this.getAllCourseStats(this.selectGrandezza, this.filterCourse);
  }

  resetFilter() {
    this.selectedItemsCourses = [];
    this.runFilter($localize);
  }

  runFilter($event:any){
    console.log('Submitted course filter', $event);
    let coursesIds: number[] = [];
    if (this.selectedItemsCourses && this.selectedItemsCourses.length > 0) {
      for (var i = 0; i < this.selectedItemsCourses.length; i++) {
        coursesIds.push(this.selectedItemsCourses[i].id);
      }
    }
    this.filterCourse = {
      active: this.form.value.active,
      courses: coursesIds,
    };
    console.log('filterCourse in submit: ', this.filterCourse);
    this.formSubmittedCourse.emit(this.filterCourse);
    this.getAllCourseStats(this.selectGrandezza, this.filterCourse);
  }

  back() {
    this.location.back();
  }
}
