import { SharedModule } from './modules/shared/shared.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginModule } from './modules/login/login.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEditorModule } from 'ngx-editor';
import { HomeModule } from './modules/home/home.module';
import { SurveyModule } from "survey-angular-ui";
import { DatePipe } from '@angular/common';
import {NgHttpLoaderModule} from "ng-http-loader";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AuthConfigModule } from './modules/shared/services/auth/auth-config.module';
import {AuthInterceptor} from "./modules/shared/services/auth/auth-interceptor";
import { SpinnerComponent } from './modules/shared/spinner/spinner.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthConfigModule,
    //AuthInterceptor,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    LoginModule,
    HomeModule,
    SharedModule,
    MatProgressSpinnerModule,
    SpinnerComponent,
    NgxEditorModule,
    SurveyModule,    
    NgHttpLoaderModule.forRoot(),
  ],
  providers: [
              //  {provide: HTTP_INTERCEPTORS, useClass:AuthInterceptor, multi:true}, 
                DatePipe
              ],
  bootstrap: [AppComponent],
})
export class AppModule {}
