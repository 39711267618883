import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GenericHttpService } from 'src/app/modules/shared/services/generic-http.service';
import { environment } from 'src/environments/environment';
import {
  CompiledTest,
  Lesson,
  LessonDetail,
  LessonPage,
  NewLesson,
  TestAnswers,
  UpdatableLesson,
} from '../home-components/models/data-model';

@Injectable({
  providedIn: 'root',
})
export class LessonsService {
  baseURL = '/api';

  constructor(private genericHttpService: GenericHttpService) {}

  getLessonList(page: number, courseID: number): Observable<LessonPage> {
    console.log('GET LESSON LIST');
    return this.genericHttpService.httpGet<LessonPage>(
      `${this.baseURL}/courses/${courseID}/lessons?page=` +
        page +
        '&size=' +
        environment.size
    );
  }

  addLesson(courseID: number, lesson: NewLesson) {
    console.log('ADD LESSON');
    return this.genericHttpService
      .httpPost<NewLesson>(
        `${this.baseURL}/courses/${courseID}/lessons`,
        lesson,
        {
          headers: { 'Content-Type': 'application/json' },
          observe: 'response',
        }
      )
      .pipe(
        map((response: any) => {
          // alert(response.status);
          return response.status;
        })
      );
  }

  deleteLesson(courseID: number, lessonID: number) {
    console.log('DELETE LESSON');
    return this.genericHttpService.httpDelete<Lesson>(
      `${this.baseURL}/courses/${courseID}/lessons/${lessonID}`
    );
  }

  modifyLesson(courseID: number, lessonID: number, lesson: UpdatableLesson) {
    console.log('Modify LESSON');
    return this.genericHttpService
      .httpPatch(
        `${this.baseURL}/courses/${courseID}/lessons/${lessonID}`,
        lesson,
        {
          headers: { 'Content-Type': 'application/json' },
          observe: 'response',
        }
      )
      .pipe(
        map((response: any) => {
          console.log(response);
          // alert(response.status);
          return response.status;
        })
      );
  }
  restoreLesson(courseID: number, lessonID: number) {
    console.log('RESTORE LESSON');
    return this.genericHttpService.httpPost<Lesson>(
      `${this.baseURL}/courses/${courseID}/lessons/${lessonID}`
    );
  }

  getLessonDetail(
    courseID: number,
    lessonID: number
  ): Observable<LessonDetail> {
    console.log('GET LESSONS');
    return this.genericHttpService.httpGet<LessonDetail>(
      `${this.baseURL}/courses/${courseID}/lessons/${lessonID}`
    );
  }

  addTestExecution(courseID: number, lessonID: number, answers: TestAnswers) {
    console.log('ADD TEST EXECUTION');
    return this.genericHttpService
      .httpPost<TestAnswers>(
        `${this.baseURL}/courses/${courseID}/lessons/${lessonID}/test`,
        answers,
        {
          headers: { 'Content-Type': 'application/json' },
          observe: 'response',
        }
      )
      .pipe(
        map((response: any) => {
          // alert(response.status);
          return response.status;
        })
      );
  }
  getTestDetail(
    courseID: number,
    lessonID: number,
    testID: number
  ): Observable<CompiledTest> {
    console.log('GET TEST DETAILS');
    return this.genericHttpService.httpGet<CompiledTest>(
      `${this.baseURL}/courses/${courseID}/lessons/${lessonID}/test/${testID}`
    );
  }
}
