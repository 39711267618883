import { Component, OnInit, Output } from '@angular/core';
import {
  Professor,
  ProfessorFilter,
  ProfessorPage,
} from '../../models/data-model';
import { environment } from '../../../../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfessorService } from '../../../services/professor.service';
import { AdminModalComponent } from '../../../../../shared/components/modals/admin-modal/admin-modal.component';
import { User } from '../../../../../shared/model/user';
import { DlgType } from 'src/app/modules/shared/components/w-notification/models/notification-models';
import { WDialogService } from 'src/app/modules/shared/components/w-dialog/services/w-dialog.service';

@Component({
  selector: 'wave-eduxible-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
})
export class UsersPageComponent implements OnInit {
  @Output() filterAdmin!: ProfessorFilter;
  elencoProfessori: Professor[] = [];
  elencoProfessoriFiltrato: Professor[] = [];
  totalElements!: number;
  user!: User;
  adminFilter!: ProfessorFilter;

  // @Output() onChangePageTeachers = new EventEmitter<number>();
  page = 1;
  pageSize: number = environment.size;

  constructor(
    private professorService: ProfessorService,
    private dialogService: WDialogService,
    private modalService: NgbModal
  ) {
    this.filterAdmin = {};
    this.filterAdmin.admin = true;
    this.filterAdmin.active = true;
  }

  changePage($event: number) {
    console.log('Page:', $event);
    this.page = $event;
    this.getAdminList(this.page - 1, this.filterAdmin);
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user')!);
    console.log('Filter admin', this.filterAdmin);
    this.getAdminList(0, this.filterAdmin);
  }
  raiseFormSubmittedProf($event: ProfessorFilter) {
    console.log('raise filter PROF', $event);
    //this.raisefilterAdmin.emit($event);
    //debugger
    this.filterAdmin = $event;
    this.getAdminList(0, this.filterAdmin);
  }

  getAdminList(page: number, filter: ProfessorFilter) {
    this.professorService.getProfessorsList(page, filter).subscribe({
      next: (admins: ProfessorPage) => {
        this.elencoProfessori = admins.content;
        this.totalElements = admins.totalElements;
        console.log('Users list', this.elencoProfessori);
      },
    });
  }

  openModalAdmin() {
    const modalRef = this.modalService.open(AdminModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.action = 'add';
    modalRef.componentInstance.title = 'Aggiungi amministratore';
    modalRef.componentInstance.confirmButton = 'Conferma e aggiungi';
    modalRef.result
      .then((adminForm) => {
        window.location.reload();
        console.log('adminForm: ', adminForm);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  editAdmin(prof: Professor) {
    console.log(prof);
    const modalRef = this.modalService.open(AdminModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.prof = prof;
    modalRef.componentInstance.action = 'edit';
    modalRef.componentInstance.title = 'Modifica amministratore';
    modalRef.componentInstance.confirmButton = 'Modifica';
    modalRef.result
      .then((editForm) => {
        console.log('editForm: ', editForm);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  deleteAdmin(prof: Professor) {
    console.log(prof);
    // Aprire il dialog
    this.dialogService.open({
      titolo: `Vuoi eliminare ${prof.name} ${prof.surname}?`,
      userType: 'professor',
      dlgType: 'delete',
      tipo: DlgType.ERROR,
      // messaggio: '',
      cancelVisible: true,
      okHandler: () => {
        this.professorService.deleteProfessor(prof).subscribe();
        window.location.reload();
      },
    });
  }
  restoreUser(prof: Professor) {
    this.dialogService.open({
      titolo: `Vuoi ripristinare ${prof.name} ${prof.surname}?`,
      userType: '',
      dlgType: 'restore',
      tipo: DlgType.RESTORE,
      // messaggio: '',
      cancelVisible: true,
      okHandler: () => {
        this.professorService.restoreProfessor(prof).subscribe();
        window.location.reload();
      },
    });
  }
}
