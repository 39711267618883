import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Chart } from 'chart.js';
import { CoursesService } from '../../../../services/courses.service';
import { ProfessorService } from '../../../../services/professor.service';
import {
  Course,
  CourseFilter,
  Professor,
  ProfessorFilter,
  ProfessorFilterStats,
  ProfessorPage,
  ProfessorStat,
  ProfStatistics,
} from '../../../models/data-model';
import { Location } from '@angular/common';

@Component({
  selector: 'wave-eduxible-stat-prof',
  templateUrl: './stat-prof.component.html',
  styleUrls: ['./stat-prof.component.scss'],
})
export class StatProfComponent implements OnInit {
  course!: Course;
  // filterProf!: ProfessorFilter;
  elencoProfessori: Professor[] = [];
  myLabels: string[] = [];
  totalElements!: number;
  public chart: any;
  profStats!: ProfStatistics;
  professorStats!: ProfessorStat[];
  professorStatsFiltered!: ProfessorStat[];
  myDataSet: any = [];
  selectGrandezza!: any;
  //per filtri
  form!: FormGroup;
  show = false;
  resetFilterCourse!: CourseFilter;
  formFilter!: CourseFilter;
  filterProf!: CourseFilter;
  @Input() filterList!: CourseFilter;
  @Output() formSubmittedCourse = new EventEmitter<CourseFilter>();
  dropdownListResponsible: Professor[] = [];
  selectedItemsResponsible: any = [];
  dropdownSettingsResponsible = {};
  //fine
  createChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart('ProfChart', {
      type: 'bar', //this denotes tha type of chart

      data: {
        // values on X-Axis
        labels: this.myLabels,
        datasets: [
          {
            label: '',
            data: this.myDataSet,
            backgroundColor: ['rgb(0, 129, 143)'],
            borderColor: ['rgb(0, 129, 143)'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        aspectRatio: 2.5,
        plugins: {
          legend: {
            display: false
          }
        }
      },
    });
  }
  constructor(
    private professorService: ProfessorService,
    fb: FormBuilder,
    public courseService: CoursesService,
    public location: Location
  ) {
    this.filterProf = {};
    this.filterProf.active = true;
    this.filterProf.managers = [];
    this.form = fb.group({
      active: new FormControl('true'),
      managers: new FormControl(),
    });
  }
  get f() {
    return this.form.controls as any;
  }
  ngOnInit(): void {
    this.selectGrandezza = 'lezioni';
    this.getAllProfStats(this.selectGrandezza, null);
    if (this.filterList) {
      this.filterList.active !== null
        ? this.f.active.patchValue(this.filterList.active)
        : this.form.value.active;
      this.filterList.managers !== null
        ? this.f.managers.patchValue(this.filterList.managers)
        : this.form.value.managers;
    }

    this.professorService
      .getProfessorsList(0, this.formFilter)
      .subscribe((response: any) => {
        console.log('RESP', response);
        this.dropdownListResponsible = response.content;
        this.dropdownListResponsible.forEach((element) => {
          element.fullname = element.surname + ' ' + element.name;
        });
        this.dropdownSettingsResponsible = {
          singleSelection: false,
          idField: 'id',
          textField: 'fullname',
          searchPlaceholderText: 'Cerca...',
          noFilteredDataAvailablePlaceholderText: 'Nessun dato trovato',
          selectAllText: 'Seleziona tutti',
          unSelectAllText: 'Deseleziona tutti',
          itemsShowLimit: 5,
          allowSearchFilter: true,
        };
      });
  }

  getAllProfStats(value: string, filter: any) {
    this.professorService.getAllProfStats().subscribe({
      next: (res: any) => {
        console.log('StatsProf', res);
        this.professorStats = res.content;
        this.myDataSet = [];
        this.myLabels = [];
        this.professorStatsFiltered = [];
        this.professorStats.forEach((l) => {
          l.passedTestsPerc = Math.round(l.passedTestsPerc);
          if (filter !== null) {
            if (filter.active == 'true') {
              if (!l.active) {
                return;
              }
            } else if (filter.active == 'false') {
              if (l.active) {
                return;
              }
            }
            if (filter.managers !== null && filter.managers.length > 0) {
              if (!filter.managers.find((m: number) => l.id == m)) {
                return;
              }
            }
          }
          if (l.totalLessons >= 0) {
            this.myLabels.push(l.surname + ' ' + l.name);
            if (value === 'lezioni') {
              this.myDataSet.push(l.totalLessons);
              this.professorStatsFiltered.push(l);
            }
            if (value === 'corsiResp') {
              this.myDataSet.push(l.totalManagers);
              this.professorStatsFiltered.push(l);
            }
            if (value === 'corsiCollab') {
              this.myDataSet.push(l.totalCollaborations);
              this.professorStatsFiltered.push(l);
            }
            if (value === 'testSup') {
              this.myDataSet.push(l.passedTests);
              this.professorStatsFiltered.push(l);
            }
          }
        });
        console.log(this.myLabels);
        this.createChart();
      },
    });
  }

  onSelect(value: string) {
    this.selectGrandezza = value;
    console.log(this.selectGrandezza);
    this.getAllProfStats(
      value,
      this.filterProf.active !== null || this.filterProf.managers !== null
        ? this.filterProf
        : null
    );
  }

  filtriClick() {
    this.show = !this.show;
  }

  resetFilter() {
    this.selectedItemsResponsible = [];
    this.runFilter($localize);
  }

  runFilter($event: any){
    console.log('Submitted prof filter', $event);
    let profsIds: number[] = [];
    if(this.selectedItemsResponsible && this.selectedItemsResponsible.length > 0) {
      for(var i = 0; i<this.selectedItemsResponsible.length; i++){
        profsIds.push(this.selectedItemsResponsible[i].id);
      }
    }
    this.filterProf = {
      active: this.form.value.active,
      managers: profsIds,
    };
    console.log('filterProf in submit: ', this.filterProf);
    this.formSubmittedCourse.emit(this.filterProf);
    this.getAllProfStats(this.selectGrandezza, this.filterProf);
  }

  onSubmit($event: SubmitEvent) {
    console.log('Submitted course filter', $event);
    let managerIds: number[] = [];
    if (
      this.selectedItemsResponsible &&
      this.selectedItemsResponsible.length > 0
    ) {
      for (var i = 0; i < this.selectedItemsResponsible.length; i++) {
        managerIds.push(this.selectedItemsResponsible[i].id);
      }
    }
    this.filterProf = {
      active: this.form.value.active,
      managers: managerIds,
    };
    console.log('filterProf in submit: ', this.filterProf);
    this.formSubmittedCourse.emit(this.filterProf);
    this.getAllProfStats(this.selectGrandezza, this.filterProf);
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  back() {
    this.location.back();
  }
}
