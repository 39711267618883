<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/studenti">Studenti</a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="/dettaglio_studenti">{{ currentTest.studentName }}</a>
    </li>
    <li class="breadcrumb-item active">
      Test {{ currentTest.lessonTitle }}
    </li>
  </ol>
</nav>

<h4 class="mb-3 fw-bold">
  Test "{{ currentTest.lessonTitle }}" di {{ currentTest.studentName }}
</h4>
<p>Risposte esatte: <b>{{currentTest.correctAnswers}} / {{currentTest.questions.length}}</b></p>

<div class="bg-body-secondary mt-3 p-3">
  <!-- testo della domanda -->
  <div *ngFor="let domande of currentTest.questions" class="ms-2 mt-2">
    <p [innerHTML]="domande.text" class="fw-bold mt-2">{{ domande.text }}</p>
    <!-- elenco delle domande --- PROBLEMA: classe fw-bold non viene letta (mai o solo con il text-success??) -->
    <div *ngFor="let answer of domande.answers; let i= index">
      <ul class="ps-3">
        <li [ngClass]="{'text-success fw-bold': answer.checkedByStudent &&
          answer.correct, 'text-danger fw-bold' : answer.checkedByStudent &&
          answer.correct !== true,
          'text-secondary': answer.checkedByStudent !== true
          }">
          {{ answer.text }}
        </li>
      </ul>
    </div>
    <!-- resoconto della domanda -->
    <div *ngFor="let answer of domande.answers" class="ms-2">
      <p *ngIf="answer.checkedByStudent && answer.correct" class="text-success
        fw-bold">
        Risposta corretta
      </p>
      <div>
        <p *ngIf="answer.checkedByStudent && answer.correct !== true"
          class="text-danger fw-bold">Risposta errata</p>
        <p *ngIf="answer.correct && answer.checkedByStudent !== true">Risposta
          corretta: {{ answer.text }}</p>
      </div> </div>
    <hr class="nextQuestion">
    </div>
</div>

<div>
  <a class="btn btn-link me-3" (click)="back()">
    <svg class="icon me-2">
      <use xlink:href="assets/icons/annulla.svg#annulla"
        href="assets/icons/annulla.svg#annulla"></use>
    </svg>Torna indietro
  </a>
</div>
