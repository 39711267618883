<button class="btn btn-secondary float-end btn-sm" (click)="openModalProf()">
  <svg class="icon me-2">
    <use xlink:href="assets/icons/add-teacher.svg#add-teacher"
      href="assets/icons/add-teacher.svg#add-teacher"></use>
  </svg>
  Aggiungi docente
</button>
<h1 class="fw-bold mt-5">Docenti</h1>
<wave-eduxible-filter-teacher
  (formSubmittedProf)="raiseFormSubmittedProf($event)"></wave-eduxible-filter-teacher>
<div class="table-responsive overflow-x-scroll">
  <table class="table table-hover">
    <thead class="sticky-top">
      <tr class="align-middle border-bottom border-2">
        <th class="pe-0">
          <span class="visually-hidden">Attivo</span>
        </th>
        <th class="ps-0">
          <span class="visually-hidden">Admin</span>
        </th>
        <th>
          Nome
        </th>
        <th>
          Cognome
        </th>
        <th>
          Email
        </th>
        <th>
          Responsabile
        </th>
        <th>
          Collaboratore
        </th>
      </tr>
    </thead>
    <tbody>
      <!--  | slice: (page-1) * pageSize : page * pageSize-->
      <tr *ngFor="let prof of elencoProfessori">
        <td class="pe-0" *ngIf="prof.active; else notActive">
          <span class="d-inline-block" tooltip="Attivo" placement="top">
            <svg class="icon icon-green">
              <use xlink:href="assets/icons/user.svg#user"
                href="assets/icons/user.svg#user"></use>
            </svg>
          </span>
        </td>
        <ng-template #notActive>
          <td>
            <span class="d-inline-block" tooltip="Non attivo" placement="top">
              <svg class="icon icon-500">
                <use xlink:href="assets/icons/user.svg#user"
                  href="assets/icons/user.svg#user"></use>
              </svg>
            </span>
          </td>
        </ng-template>
        <td class="ps-0" *ngIf="prof.admin; else notAdmin">
          <span class="d-inline-block" tooltip="Amministratore" placement="top">
            <svg class="icon icon-blue-800">
              <use xlink:href="assets/icons/admin.svg#admin"
                href="assets/icons/admin.svg#admin"></use>
            </svg>
          </span>
        </td>
        <ng-template #notAdmin>
          <td></td>
        </ng-template>
        <td>{{ prof.name }}</td>
        <td>{{ prof.surname }}</td>
        <td>{{ prof.email }}</td>
        <td>
          <ul class="ps-3">
            <li *ngFor="let corso of prof.managerOf">{{corso.name}}</li>
          </ul>
        </td>
        <td>
          <ul class="ps-3">
            <li *ngFor="let corso of prof.collaboratorOf">{{corso.name}}</li>
          </ul>
        </td>
        <td>
          <div class="d-flex h-100 float-end">
            <a *ngIf="prof.active === true" role="button"
              (click)="editTeacher(prof)" class="text-primary h-100"
              tooltip="Modifica" placement="top">
              <svg class="icon me-3">
                <use xlink:href="assets/icons/modify.svg#modify"
                  href="assets/icons/modify.svg#modify"></use>
              </svg>
            </a>
            <a *ngIf="prof.active === true; else restoreNotActive" role="button"
              (click)="deleteTeacher(prof)" class="text-danger h-100"
              tooltip="Elimina" placement="top">
              <svg class="icon me-3">
                <use xlink:href="assets/icons/trashcan.svg#trashcan"
                  href="assets/icons/trashcan.svg#trashcan"></use>
              </svg>
            </a>
            <ng-template #restoreNotActive>
              <a role="button" (click)="restoreTeacher(prof)"
                tooltip="Ripristina" placement="top" class="d-inline
                text-primary">
                <svg class="icon">
                  <use xlink:href="assets/icons/restore.svg#restore"
                    href="assets/icons/restore.svg#restore"></use>
                </svg>
              </a>
            </ng-template>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div>
    <ngb-pagination
      (pageChange)="changePage($event)"
      [collectionSize]="totalElements"
      [(page)]="page"
      [maxSize]="10"
      [rotate]="true"
      [ellipses]="true"
      [boundaryLinks]="true"
      [pageSize]="pageSize">
    </ngb-pagination>
  </div>
</div>

<!-- <div class="btn btn-primary me-4 mb-4" style="float: right;">Aggiungi docente</div> -->
<!-- <div class="btn btn-primary me-4 mb-4" style="float: right;">Aggiungi docente</div> -->
