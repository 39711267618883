<div *ngIf="currentUser.role !== 'Student'; else stud">
  <h1 class="my-4">Ciao, {{currentUser.user}}!</h1>

  <div class="row row-cols-2 row-cols-lg-4">
    <div class="col p-3">
      <div class="card p-4">
        <p class="h6 mb-3">Responsabile:</p>
        <p class="h3 text-teal">{{profStats.managerCourses}} corsi</p>
      </div>
    </div>

    <div class="col p-3">
      <div class="card p-4">
        <p class="h6 mb-3">Collaboratore:</p>
        <p class="h3 text-teal">{{profStats.collaboratorCourses}} corsi</p>
      </div>
    </div>
    <div class="col p-3">
      <div class="card p-4">
        <p class="h6 mb-3">Studenti:</p>
        <p class="h3 text-teal">{{profStats.students}}</p>
      </div>
    </div>
    <div class="col p-3">
      <div class="card p-4">
        <p class="h6 mb-3">Test superati:</p>
        <p class="h3 text-teal">{{profStats.passedTestsPerc.toString().substring(0,4)}}%</p>
      </div>
    </div>
  </div>
  <div class="card mt-5 h-auto p-4 d-block">
    <div class="pt-3 d-flex align-items-center ms-3 mt-2">
      <h3 class="fw-bold mb-4">Trend ultimi 15 giorni</h3>
    </div>
      <canvas class="w-100" id="TrendChart">{{ chart }}</canvas>
  </div>
</div>

<ng-template #stud>
  <wave-eduxible-courses-overview-page>
  </wave-eduxible-courses-overview-page>
</ng-template>
