import { LoginFormComponent } from './components/login-page/login-form/login-form.component';
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginPageComponent} from "./components/login-page/login-page.component";
import { HomePageComponent } from '../home/home-page/home-page.component';

const routes: Routes = [
  { path: '', component: LoginPageComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
