import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgEventBus} from 'ng-event-bus';
import {Credentials} from "../../../login-model/credentials";

@Component({
  selector: 'wave-eduxible-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  @Output() login = new EventEmitter<Credentials>();
/*
  user!: Credentials;
*/
  form !: FormGroup;

  constructor(private fb: FormBuilder,
              private router: Router,
              private eventBus: NgEventBus) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    })
  }


  handleAccess(value: Credentials) {
    console.log(value);
    // console.log('login form inputs', this.form);
    // console.log(this.form.controls['email'].value);
    // console.log(this.form.controls['password'].value);
   /* this.user.email = value.email;
    this.user.password = value.password;*/
    this.login.emit(value);
  }
}
