import { Component } from '@angular/core';

@Component({
  selector: 'wave-eduxible-teacher-detail-page',
  templateUrl: './teacher-detail-page.component.html',
  styleUrls: ['./teacher-detail-page.component.scss']
})
export class TeacherDetailPageComponent {

}
