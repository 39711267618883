import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/modules/shared/model/user';
import { CompiledTest } from '../../models/data-model';
import { Location } from '@angular/common';

@Component({
  selector: 'wave-eduxible-student-test-detail',
  templateUrl: './student-test-detail.component.html',
  styleUrls: ['./student-test-detail.component.scss']
})
export class StudentTestDetailComponent implements OnInit{
  constructor(
    public location: Location
  ){}
  currentUser!: User;
  currentTest!: CompiledTest;

  ngOnInit(): void {
   // this.course = JSON.parse(localStorage.getItem('course')!);
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
    this.currentTest = JSON.parse(localStorage.getItem('testDetail')!);
  }

  back() {
    this.location.back();
  }
}
