import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CourseCombo, StudentFilter } from '../../../models/data-model';
import { CoursesService } from '../../../../services/courses.service';
import { StudentService } from '../../../../services/student.service';
import { ProfessorService } from '../../../../services/professor.service';

@Component({
  selector: 'wave-eduxible-filter-student',
  templateUrl: './filter-student.component.html',
  styleUrls: ['./filter-student.component.scss'],
})
export class FilterStudentComponent implements OnInit {
  form!: FormGroup;
  resetFilterStud!: StudentFilter;
  formFilter!: StudentFilter;
  filterStud!: StudentFilter;
  @Input() filterList!: StudentFilter;
  @Output() formSubmittedStud = new EventEmitter<StudentFilter>();
  show = false;

  get f() {
    return this.form.controls as any;
  }
  constructor(
    fb: FormBuilder,
    public router: Router,
    public courseService: CoursesService,
    public studentService: StudentService,
    public professorService: ProfessorService
    ) {
    this.form = fb.group({
      name: new FormControl(),
      surname: new FormControl(),
      email: new FormControl(),
      active: new FormControl('true'),
      class: new FormControl()
    });
  }

  ngOnInit() {
    if (this.filterList) {
      this.filterList.name !== null
        ? this.f.name.patchValue(this.filterList.name)
        : this.form.value.name;
      this.filterList.surname !== null
        ? this.f.surname.patchValue(this.filterList.surname)
        : this.form.value.surname;
      this.filterList.email !== null
        ? this.f.email.patchValue(this.filterList.email)
        : this.form.value.email;
      this.filterList.active !== null
        ? this.f.active.patchValue(this.filterList.active)
        : this.form.value.active;
      this.filterList.class !== null
        ? this.f.class.patchValue(this.filterList.class)
        : this.form.value.class;
    }
  }

  onSubmit($event: SubmitEvent) {
    console.log('Submitted student filter', $event);
    this.filterStud = {
      name: this.form.value.name,
      surname: this.form.value.surname,
      email: this.form.value.email,
      active: this.form.value.active,
      class: this.form.value.class,
    };
    console.log
    this.formSubmittedStud.emit(this.filterStud);
  }

  filtriClick() {
    this.show = !this.show;
  }

  resetFilter() {
    this.form.reset();
    this.filterStud = {};
    this.formSubmittedStud.emit(this.filterStud);
  }
}
