import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from "../environments/environment";
import {Subscription} from "rxjs";
import {MetaData, NgEventBus} from "ng-event-bus";
import {Router} from "@angular/router";
import { User } from './modules/shared/model/user';
import { SpinnerComponent } from './modules/shared/spinner/spinner.component';

@Component({
  selector: 'wave-eduxible-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'eduxiblefe';
  // utente = environment.user;
  user!: User;

  subscriptions: Subscription = new Subscription();
  public SpinnerComponent =  SpinnerComponent;

  constructor(private router: Router,private eventBus: NgEventBus) {
  }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')!);
    console.log('Pass User in APP_COMPONENT_TS')
  }
}
