import {Component, OnInit} from '@angular/core';
import {environment} from "@ng-bootstrap/ng-bootstrap/environment";

@Component({
  selector: 'wave-eduxible-courses-detail-teacher-page',
  templateUrl: './courses-detail-teacher-page.component.html',
  styleUrls: ['./courses-detail-teacher-page.component.scss']
})
export class CoursesDetailTeacherPageComponent implements  OnInit{
  utente!: string
  constructor() {
  }
  ngOnInit() {
    this.utente = localStorage.getItem('utente')!
  }
}
