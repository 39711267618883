import { MatIconModule } from '@angular/material/icon';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {JwtInterceptor} from "./interceptors/jwt.interceptor";
import { AdminModalComponent } from './components/modals/admin-modal/admin-modal.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ProfessorModalComponent } from './components/modals/professor-modal/professor-modal.component';
import { StudentModalComponent } from './components/modals/student-modal/student-modal.component';
import { CourseModalComponent } from './components/modals/course-modal/course-modal.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { WNotificationComponent } from './components/w-notification/w-notification.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { WDialogComponent } from './components/w-dialog/w-dialog.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@NgModule({
  declarations: [
    AdminModalComponent,
    ProfessorModalComponent,
    StudentModalComponent,
    CourseModalComponent,
    WNotificationComponent,
    WDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports:[
    AdminModalComponent,
    ProfessorModalComponent,
    StudentModalComponent,
    CourseModalComponent,
    WNotificationComponent,
    WDialogComponent,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
  ]
})
export class SharedModule {
}
