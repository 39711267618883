import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationConfig } from '../models/notification-models';

@Injectable({
  providedIn: 'root'
})
export class WNotificationService {

  constructor() { }

  private config$: Subject<NotificationConfig> = new Subject<NotificationConfig>();

  get configObs() {
    return this.config$.asObservable();
  }

  // funzione per open della dialog
  open(config: NotificationConfig) {
    this.config$.next(config);
  }
}
