<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/corsi">Corsi e lezioni</a></li>
    <li class="breadcrumb-item">
      <a routerLink="/dettaglio_corso">{{ course.name }}</a>
    </li>
    <li *ngIf="currentUser.role !== 'Student' && mode !== 'view'"
      class="breadcrumb-item">
      <a routerLink="/lezioni">Lezioni</a>
    </li>
    <li *ngIf="currentUser.role !== 'Student' && mode !== 'view'; else stud"
      class="breadcrumb-item
      active" aria-current="page">Modifica</li>
    <ng-template #stud>
      <li class="breadcrumb-item active" aria-current="page">{{titolo}}</li>
    </ng-template>
  </ol>
</nav>
<h1 class="mb-5">{{titolo}}</h1>
<!--{{mode}}-->

<div *ngIf="currentUser.role !== 'Student' && mode !== 'view'; else studente">
  <div class="d-flex">
    <div class="col-2 me-3"> <p class="d-inline-block">Vuoi importare la
        lezione?</p></div>
    <div class="d-flex">
      <input class="col me-3 form-control d-inline-block" type="file"
        (change)="onChange($event)">

      <button (click)="onUpload()"
        class="btn btn-primary d-flex flex-nowrap align-items-center">
        <svg class="icon me-2">
          <use xlink:href="assets/icons/cloud-download.svg#cloud-download"
            href="assets/icons/cloud-download.svg#cloud-download"></use>
        </svg>
        <span>Importa lezione</span>
      </button>
    </div>

  </div>
  <div class="container text-center jumbotron"
    *ngIf="shortLink">
    <h3> Visit Here</h3>
    <a href="{{shortLink}}">{{shortLink}}</a>
  </div>

  <div class="container" *ngIf="loading">
    <h3>Caricamento...</h3>
  </div>

  <div class="mt-5 mb-5">
    <label for="title" class="form-label fw-bold mt-0 ms-2">Titolo<span
        class="required ms-1">*</span></label>
    <input
      type="text"
      class="form-control border-0 border-bottom mb-2"
      id="title"
      [(ngModel)]="currentLesson.title"
      placeholder="Inserisci titolo"
      />
    <!-- value="{{currentLesson.title}}" -->
  </div>

  <div class="content mb-5">
    <h3 class="mb-3">Contenuto</h3>
    <div class="display">
    </div>
    <!-- <form [formGroup]="form"> -->
    <div class="editor">
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
      </ngx-editor-menu>
      <ngx-editor [editor]="editor"
        id="editorContent"
        [(ngModel)]="currentLesson.content"
        placeholder="Inserirsci un contenuto per la lezione...">
      </ngx-editor>
    </div>
    <!-- </form> -->
  </div>
</div>

<ng-template #studente>
  <div class="content mb-5">
    <h3 class="mb-3">Contenuto</h3>
    <p [innerHTML]="currentLesson.content">{{currentLesson.content}}</p>
  </div>
</ng-template>

<div *ngIf="currentUser.role !== 'Student' && mode !== 'view'; else
  studmaterial" class="col-4
  mb-5">
  <h3 class="mb-4">Materiali scaricabili</h3>
  <table class="small table table-hover">
    <!-- <ul class="list-group"> -->
    <tr class="align-middle border-bottom border-2">
      <td>
        <a role="button" class="text-primary">
          <svg class="icon me-2">
            <use xlink:href="assets/icons/pdfLogo.svg#pdfLogo"
              href="assets/icons/pdfLogo.svg#pdfLogo"></use>
          </svg>
          <span>Verbs.pdf (STATIC)</span>
        </a>
      </td>
      <td class="text-gray-900 float-start">23 Kb</td>
      <td class="pe-0 pb-1 float-end">
        <a role="button" class="text-danger" tooltip="Elimina" placement="top">
          <svg class="icon">
            <use xlink:href="assets/icons/trashcan.svg#trashcan"
              href="assets/icons/trashcan.svg#trashcan"></use>
          </svg>
        </a>
      </td>
    </tr>
  </table>
  <div class="mb-3">
    <button class="btn btn-outline-secondary btn-sm" type="button">
      <svg class="icon me-2">
        <use xlink:href="assets/icons/Upload.svg#upload"
          href="assets/icons/upload.svg#upload"></use>
      </svg>Carica file
    </button>
  </div>
  <p class="small">Sono permessi file in formato .pdf, .doc, .docx, .xls del
    peso massimo di
    1MB</p>
</div>

<ng-template #studmaterial>
  <div class="col-4 mb-5">
    <h3 class="mb-4">Materiali scaricabili</h3>
    <table class="small table table-hover">
      <!-- <ul class="list-group"> -->
      <tr class="align-middle border-bottom border-2">
        <td>
          <a role="button" class="text-primary">
            <svg class="icon me-2">
              <use xlink:href="assets/icons/pdfLogo.svg#pdfLogo"
                href="assets/icons/pdfLogo.svg#pdfLogo"></use>
            </svg>
            <span>Verbs.pdf (STATIC)</span>
          </a>
        </td>
        <td class="text-gray-900 float-start">23 Kb</td>
      </tr>
    </table>
  </div>
</ng-template>

<div *ngIf="currentUser.role !== 'Student' && mode !== 'view'; else studtest">
  <div class="col-4 mb-5">
    <h3 class="mb-4">Test</h3>
    <p *ngIf="currentLesson.nquestions == 0">Non è stato creato il test di
      questa lezione</p>
    <p>Domande totali: <b>{{currentLesson.nquestions}}</b></p>
    <p>Soglia di superamento: <b>{{currentLesson.threshold}} /
        {{currentLesson.nquestions}}</b></p>
    <p>Secondi a disposizione per domanda: <b>{{currentLesson.secondsPerQuestion}}</b></p>
    <p>Secondi a disposizione per test: <b>{{currentLesson.secondsTest}}</b></p>
    <p>Ultima modifica: <b *ngIf="currentLesson.updatedBy">{{currentLesson.updatedBy}}
        il {{currentLesson.updatedAt.substring(0, 10)}}</b></p>
    <button [disabled]="currentLesson.id <= 0" (click)="goToModifyTest()"
      role="button" class="btn btn-outline-secondary btn-small d-flex align-items-center"
      tooltip="Modifica" placement="top">
      <svg class="icon me-2">
        <use xlink:href="assets/icons/modify.svg#modify"
          href="assets/icons/modify.svg#modify"></use>
      </svg>
      Modifica / crea test
    </button>
  </div>

  <div class="d-flex justify-content-center">
    <button class="btn btn-outline-secondary me-3 d-flex align-items-center"
      (click)="goToLessons()">
      <svg class="icon me-2">
        <use xlink:href="assets/icons/annulla.svg#annulla"
          href="assets/icons/annulla.svg#annulla"></use>
      </svg>Annulla
    </button>
    <button (click)="saveLessonModify()" class="btn btn-primary d-flex
      align-items-center">
      <svg class="icon me-2">
        <use xlink:href="assets/icons/confirm.svg#confirm"
          href="assets/icons/confirm.svg#confirm"></use>
      </svg>Conferma
    </button>
  </div>
</div>
<ng-template #studtest>
  <div class="col-4 mb-5">
    <h3 class="mb-4">Test</h3>
    <table class="table table-hover">
      <!-- <ul class="list-group"> -->
      <tr class="align-middle border-bottom border-2">
        <td>
          <a role="button" (click)="goToModifyTest()" class="text-primary
            text-decoration-none">
            <svg class="icon me-2 mb-1" tooltip="Esegui test">
              <use xlink:href="assets/icons/test.svg#test"
                href="assets/icons/test.svg#test"></use>
            </svg>
            <span>Test {{titolo}}</span>
          </a>
        </td>
      </tr>
    </table>
  </div>

  <div>
    <a routerLink="/dettaglio_corso" class="btn btn-link me-3">
      <svg class="icon me-2">
        <use xlink:href="assets/icons/annulla.svg#annulla"
          href="assets/icons/annulla.svg#annulla"></use>
      </svg>Torna al corso
    </a>
  </div>
</ng-template>
