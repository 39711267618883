<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/corsi">Corsi e lezioni</a></li>
    <li class="breadcrumb-item">
      <a routerLink="/dettaglio_corso">{{ course!.name }}</a>
    </li>
    <li class="breadcrumb-item"><a routerLink="/lezioni">Lezioni</a></li>
    <li class="breadcrumb-item"><a routerLink="/dettaglio_lezione" (click)="goToLessonModify()">Modifica Lezione</a></li>
    <li class="breadcrumb-item active" aria-current="page">Modifica test</li>
  </ol>
</nav>

<h1>Gestisci test</h1>
<h2 class="h3">Lezione: {{lesson.title}}</h2>

<!-- messagistica di validazione -->
<div class="mt-4 mb-0 alert alert-danger" role="alert" *ngFor="let m of
  messages">
  {{m}}
</div>

<div class="question-list mt-5" cdkDropList [cdkDropListData]="questions"
  (cdkDropListDropped)="dropQuestion($event)">

  <div class="accordion" id="accordion-asnwers">
    <div class="accordion-item border-bottom" *ngFor="let q of questions; let
      i=index; ">
      <!-- Start question head -->
      <h2 class="accordion-header d-flex align-items-center"
        [attr.id]="'heading-' + i+1">
        <button data-bs-toggle="collapse" [attr.href]="'#panel-' + i+1"
          role="button" aria-expanded="false"
          [attr.aria-controls]="'panel-' + i+1"
          [ngClass]="i == questions.length-1 ? 'accordion-button h4 mb-0 d-flex
          flex-row-reverse col-11' : 'accordion-button collapsed h4 mb-0 d-flex
          flex-row-reverse col-11'">
          <span [ngClass]="arrayIsValid[i] ? 'ms-3 col text-truncate' : 'ms-3
            col text-truncate text-danger'">
            <span class="small">Domanda {{i+1}}:</span>
            {{stripHTML(questions[i].text)}}
          </span>
        </button>
        <div class="d-flex flex-row col-1 justify-content-end pe-3">
          <a class="text-primary bg-transparent cursor-grab"
            style="background:transparent!important;border:none!important"
            cdkDrag>
            <svg class="icon me-3" tooltip="Sposta" placement="top">
              <use xlink:href="assets/icons/move.svg#move"
                href="assets/icons/move.svg#move"></use>
            </svg>
          </a>
          <a role="button" (click)="removeQuestion(i, course, lesson, question)"
            tooltip="Elimina"
            placement="top" class="text-danger">
            <svg class="icon ">
              <use xlink:href="assets/icons/trashcan.svg#trashcan"
                href="assets/icons/trashcan.svg#trashcan"></use>
            </svg>
          </a>
        </div>
      </h2>
      <div [attr.id]="'panel-' + i+1" class="accordion-collapse collapse pb-3"
        [ngClass]="i == questions.length-1 ? 'accordion-collapse collapse pb-3
        show' : 'accordion-collapse collapse pb-3'"
        [attr.aria-labelledby]="'heading-' + i+1"
        data-bs-parent="#accordion-asnwers">
        <div class="accordion-body">
          <div>
            <label for="questionInput" class="h5 mt-4 mb-3">Testo della domanda</label>
            <div class="editor bg-white">
              <ngx-editor-menu [editor]="editors[i]" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor [editor]="editors[i]"
                [(ngModel)]="questions[i].text"
                placeholder="Inserisci come domanda...">
              </ngx-editor>
            </div>

            <div class="options col-12 col-md-10 col-lg-6 mb-3" cdkDropList
              [cdkDropListData]="q.answers"
              (cdkDropListDropped)="dropOption(i , $event)">
              <div>
                <h3 class="h5 mt-5 mb-3">Risposte</h3>
                <p *ngIf="q.answers.length <2">
                  Inserisci almeno 2 risposte
                </p>
                <table class="table" *ngIf="q.answers.length> 0">
                  <thead>
                    <tr class="border-bottom border-3">
                      <th scope="col">Sposta</th>
                      <th scope="col">Corretta</th>
                      <th scope="col">Testo</th>
                      <th scope="col"><span class="visually-hidden">Operazioni</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let o of q.answers; let j= index"
                      style="background-color:rgba(240,242, 245,0.9)" cdkDrag>
                      <td class="align-middle">
                        <a class="text-primary cursor-grab pe-5"
                          style="background:transparent!important;border:none!important">
                          <svg class="icon" tooltip="Sposta" placement="top">
                            <use xlink:href="assets/icons/move.svg#move"
                              href="assets/icons/move.svg#move"></use>
                          </svg>
                        </a>
                      </td>
                      <td class="align-middle">
                        <input class="cursor-pointer mt-1" type="radio"
                          name="option{{i}}" [checked]="o.correct"
                          (change)="changeIsCorrect(i, j)"
                          required>
                      </td>
                      <td class="align-middle">
                        <input type="text" id="optionInput" class="form-control"
                          [(ngModel)]="questions[i].answers[j].text" required
                          placeholder="inserisci risposta">
                      </td>
                      <td class="align-middle">
                        <a role="button" class="text-danger ms-3"
                          tooltip="Elimina" placement="top"
                          (click)="removeOption(i, j)">
                          <svg class="icon">
                            <use xlink:href="assets/icons/trashcan.svg#trashcan"
                              href="assets/icons/trashcan.svg#trashcan"></use>
                          </svg>
                          <span class="visually-hidden">Elimina risposta</span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button class="btn btn-sm btn-outline-secondary mt-2 d-flex
                align-items-center" type="button"
                (click)="addOption(i)">
                <svg class="icon me-1">
                  <use xlink:href="assets/icons/add.svg#add"
                    href="assets/icons/add.svg#add"></use>
                </svg>Aggiungi risposta
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End question body -->
    </div>
  </div>
  <div class="d-flex justify-content-around mt-5">
    <button class="btn btn-outline-tertiary d-flex align-items-center"
      type="button" (click)="addQuestion()">
      <svg class="icon me-1">
        <use xlink:href="assets/icons/add.svg#add"
          href="assets/icons/add.svg#add"></use>
      </svg>Aggiungi domanda
    </button>
  </div>
  <!-- GESTIONE SOGLIA SUPERAMENTO TEST -->
  <p class="mt-3 w-50 d-flex align-items-center">Numero minimo di risposte esatte:
    <input type="number" min="1" class="form-control w-25 ms-2 me-2" [(ngModel)]="soglia"> / {{this.questions.length}}
  </p>
  <p class="mt-3 w-50 d-flex align-items-center">Secondi a disposizione per domanda:
    <input type="number" min="1" class="form-control w-25 ms-2 me-2" [(ngModel)]="qseconds">
  </p>
  <p class="mt-3 w-50 d-flex align-items-center">Secondi a disposizione per test:
    <input type="number" min="1" class="form-control w-25 ms-2 me-2" [(ngModel)]="tseconds">
  </p>
  <div class="d-flex align-items-center justify-content-between w-100">
    <div class="text-primary btn btn-link">
      <a (click)="back()" class="btn btn-link me-3">
        <svg class="icon me-2">
          <use xlink:href="assets/icons/annulla.svg#annulla"
            href="assets/icons/annulla.svg#annulla"></use>
        </svg>Torna alle lezioni
      </a>
    </div>
    <div class="float-end">
      <button class="btn btn-primary"
        (click)="saveChanges()">
        <svg class="icon me-1">
          <use xlink:href="assets/icons/confirm.svg#confirm"
            href="assets/icons/confirm.svg#confirm"></use>
        </svg>Conferma
      </button>
    </div></div>
</div>
