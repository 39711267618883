<div *ngIf="dialogVisible" class="notification"
  [ngClass]="'notification-'+config.tipo" role="alert">
  <div class="d-flex align-items-center">
    <svg class="icon me-2 mb-3"
      [ngClass]="'icon-'+config.tipo">
      <ng-template [ngIf]="config.tipo === 'success'">
        <use xlink:href="assets/icons/success.svg#success"
          href="assets/icons/success.svg#success"></use>
      </ng-template>
      <ng-template [ngIf]="config.tipo === 'warning'">
        <use xlink:href="assets/icons/warning.svg#warning"
          href="assets/icons/warning.svg#warning"></use>
      </ng-template>
      <ng-template [ngIf]="config.tipo === 'info'">
        <use xlink:href="assets/icons/info.svg#info"
          href="assets/icons/info.svg#info"></use>
      </ng-template>
      <ng-template [ngIf]="config.tipo === 'danger'">
        <use xlink:href="assets/icons/danger.svg#danger"
          href="assets/icons/danger.svg#danger"></use>
      </ng-template>
    </svg>
    <p><b>{{config.titolo}}</b></p></div>
  <p [innerHTML]="config.messaggio"></p>
</div>
