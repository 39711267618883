<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/corsi">Corsi e lezioni</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{course.name}}</li>
  </ol>
</nav>
<div class="d-flex align-items-center">
  <!-- TODO -->
  <img *ngIf="course.icon && course.icon !== ''; else defaultIcon" width="48px"
    height="48px" [src]="'assets/icons/subjects/' + icon">
  <ng-template #defaultIcon>
    <img width="48px" height="48px" src="assets/icons/subjects/default.png">
  </ng-template>
  <h4 class="ms-2 fw-bold h1">{{course.name}}</h4>
</div>
<p class="card-text mt-2 mb-5 lead">{{course.description}}</p>
<p class="card-text">Docente responsabile: <strong>{{course.manager.surname}}
    {{course.manager.name}}</strong></p>
<p class="card-text">Anno accademico: <strong>{{course.year}}</strong></p>
<p class="card-text">Lezioni: <strong>{{elencoLezioni ?
  elencoLezioni.length : ''}}</strong></p>
<p *ngIf="course.collaborators.length> 0" class="card-text">Collaboratori:

  <span *ngFor="let collaborator of course.collaborators; let last= last">
    <strong>
      <span *ngIf="collaborator.fullname; else namesurn">{{collaborator.fullname}}<ng-container *ngIf="!last">, </ng-container></span>
      <ng-template #namesurn>{{collaborator.name}} {{collaborator.surname}}<ng-container *ngIf="!last">, </ng-container></ng-template>
    </strong>
  </span>
</p>
<p *ngIf="currentUser.role !== 'Student'" class="card-text mb-4">Studenti
  iscritti:
  <strong>{{totalElementsStudents}}</strong></p>

<div *ngIf="currentUser.role !== 'Student'" class="d-flex">
  <button *ngIf="course.active" class="btn btn-outline-danger me-4 mb-2 d-flex align-items-center"
    (click)="deleteCourse(course)">
    <svg class="icon">
      <use xlink:href="assets/icons/trashcan.svg#trashcan"
        href="assets/icons/trashcan.svg#trashcan"></use>
    </svg>
    <span class="d-sm-block d-none ms-2">Elimina corso</span>
  </button>

  <button *ngIf="course.active" class="btn btn-outline-secondary me-4 mb-2 d-flex align-items-center"
    (click)="editCourse(course)">
    <svg class="icon">
      <use xlink:href="assets/icons/modify.svg#modify"
        href="assets/icons/modify.svg#modify"></use>
    </svg>
    <span class="d-sm-block d-none ms-2">Modifica dati</span>
  </button>

  <button *ngIf="course.active" class="btn btn-primary d-flex mb-2 align-items-center"
    routerLink="/lezioni">
    <svg class="icon">
      <use xlink:href="assets/icons/lessons.svg#lessons"
        href="assets/icons/lessons.svg#lessons"></use>
    </svg>
    <span class="d-sm-block d-none ms-2">Gestisci lezioni</span>
  </button>

  <button *ngIf="!course.active" class="btn btn-primary d-flex mb-2 align-items-center" (click)="restoreCourse(course)">
  <svg class="icon">
    <use xlink:href="assets/icons/restore.svg#restore"
        href="assets/icons/restore.svg#restore"></use>
  </svg>
  <span class="d-sm-block d-none ms-2">Ripristina corso</span>
</button>
</div>

<div *ngIf="currentUser.role !== 'Student'; else stud" class="d-flex
  align-items-center mt-5">
  <h4 class="ms-2 fw-bold">Studenti iscritti</h4>
</div>

<ng-template #stud>
  <h4 class="mt-5 fw-bold">Lezioni</h4>
  <div *ngIf="elencoLezioniStat && elencoLezioniStat.length != 0; else noLessons">
    <div class="table-responsive overflow-x-scroll">
      <table class="table table-hover">
        <thead class="sticky-top">
          <tr class="align-middle border-bottom border-2">
            <th>
              Lezione
            </th>
            <th>
              n. Tentativi
            </th>
            <th class="col-4">
              Voto ultimo tentativo
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lesson of elencoLezioniStat">
            <td>
              <span [ngClass]="lesson.completed? 'text-success' :
                'text-secondary'">
                <svg class="icon me-2">
                  <use href="assets/icons/complete.svg#complete"></use>
                </svg>
              </span>
              Lezione {{lesson.progressiveNumber}} - {{lesson.title}}
            </td>
            <td>{{lesson.numOfAttempts}}</td>
            <td><span [ngClass]="lesson.completed ?
                'text-success':'text-secondary'" class="fw-bold
                text-align-center">{{lesson.score}}</span></td>
            <td class="col col-sm-3 col-xl-2">
              <div class="d-flex h-100 float-end">
                <a role="button" class="text-primary me-3"
                  tooltip="Visualizza" placement="top"
                  (click)="openLesson(lesson)"><svg class="icon">
                    <use href="assets/icons/eye.svg#eye"></use>
                  </svg></a>
                <a role="button" class="text-primary me-3" tooltip="Esegui test"
                  placement="top" (click)="goToTest(lesson)">
                  <svg class="icon">
                    <use xlink:href="assets/icons/test.svg#test"
                      href="assets/icons/test.svg#test"></use>
                  </svg>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div>
        <ngb-pagination
          (pageChange)="changePageLessons($event)"
          [collectionSize]="totalElementsLessonStats"
          [(page)]="pageLessons"
          [maxSize]="10"
          [rotate]="true"
          [ellipses]="true"
          [boundaryLinks]="true"
          [pageSize]="pageSize">
        </ngb-pagination>
      </div>
  </div>
  <ng-template #noLessons>Non sono ancora state aggiunte lezioni a questo corso,
    attendi che il docente carichi del materiale!</ng-template>
</ng-template>

<wave-eduxible-filter-student *ngIf="currentUser.role !== 'Student'"
  (formSubmittedStud)="raiseFormSubmittedStud($event)"></wave-eduxible-filter-student>
<div *ngIf="currentUser.role !== 'Student'" class="table table-responsive">
  <table class="table table-hover">
    <thead class="sticky-top">
      <tr class="align-middle border-bottom border-2">
        <th>
          Nome
        </th>
        <th>
          Cognome
        </th>
        <th class="col-4">
          Email
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let student of elencoStudenti">
        <td>{{student.name}}</td>
        <td>{{student.surname}}</td>
        <td>{{student.email}}</td>
        <td class="">
          <div class="d-flex h-100 float-end">
            <a *ngIf="student.active === true" role="button"
              class="text-primary"
              tooltip="Informazioni" placement="top">
              <svg class="icon me-3" (click)="goToStudentDetail(student)">
                <use xlink:href="assets/icons/info.svg#info"
                  href="assets/icons/info.svg#info"></use>
              </svg>
            </a>
            <a *ngIf="student.active === true" role="button"
              (click)="editStudent(student)"
              class="text-primary"
              tooltip="Modifica" placement="top">
              <svg class="icon me-3">
                <use xlink:href="assets/icons/modify.svg#modify"
                  href="assets/icons/modify.svg#modify"></use>
              </svg>
            </a>
            <a role="button"
              (click)="deleteStudent(student)"
              class="text-danger"
              tooltip="Rimuovere dal corso" placement="top">
              <svg class="icon me-3">
                <use xlink:href="assets/icons/rimuovi.svg#rimuovi"
                  href="assets/icons/rimuovi.svg#rimuovi"></use>
              </svg>
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div>
    <ngb-pagination
      (pageChange)="changePageStudents($event)"
      [collectionSize]="totalElementsStudents"
      [(page)]="pageStudents"
      [maxSize]="10"
      [rotate]="true"
      [ellipses]="true"
      [boundaryLinks]="true"
      [pageSize]="pageSize">
    </ngb-pagination>
  </div>
</div>

<h4 class="mt-5 fw-bold">Risultati</h4>
<div *ngIf="elencoAttemptStat && elencoAttemptStat.length> 0; else noResults">
  <div class="table table-responsive overflow-x-scroll">
    <table class="table table-hover">
      <thead class="sticky-top">
        <tr class="align-middle border-bottom border-2">
          <th *ngIf="currentUser.role !== 'Student'">
            Studente
          </th>
          <th>
            Lezione
          </th>
          <th>
            Data
          </th>
          <th class="col-4">
            Voto
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let attempts of elencoAttemptStat">
          <td *ngIf="currentUser.role !== 'Student'">{{attempts.studentName}}</td>
          <td>{{attempts.title}}</td>
          <td>{{attempts.testDate.substring(0,16)}}</td>
          <td><span [ngClass]="attempts.passed ?
              'text-success':'text-secondary'" class="fw-bold">{{attempts.score}}</span></td>
          <td class="col col-sm-3 col-xl-2">
            <div *ngIf="currentUser.role !== 'Student'" class="float-end">
              <a role="button"
                class="text-primary"
                tooltip="Visualizza" placement="top"
                (click)="openTestDetail(attempts)">
                <svg class="icon me-3">
                  <use xlink:href="assets/icons/eye.svg#eye"
                    href="assets/icons/eye.svg#eye"></use>
                </svg>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <ngb-pagination
        (pageChange)="changePageAttempts($event)"
        [collectionSize]="totalElementsAttempts"
        [(page)]="pageAttempts"
        [maxSize]="10"
        [rotate]="true"
        [ellipses]="true"
        [boundaryLinks]="true"
        [pageSize]="pageSize">
      </ngb-pagination>
    </div>
  </div>
</div>
<ng-template #noResults>Non sono ancora stati effettuati dei test.</ng-template>
<div class="mt-5">
  <a role="button" class="text-primary mt-2 btn btn-link" (click)="back()">
    <svg class="icon me-2">
      <use xlink:href="assets/icons/annulla.svg#annulla"
        href="assets/icons/annulla.svg#annulla"></use>
    </svg>
    <span>Torna indietro</span>
  </a>
</div>


