<button class="btn btn-primary float-end d-flex align-items-center"
  (click)="editStudent(student)">
  <svg class="icon me-2">
    <use xlink:href="assets/icons/modify.svg#modify"
      href="assets/icons/modify.svg#modify"></use>
  </svg>
  Modifica studente
</button>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/studenti">Studenti
      </a></li>
    <li class="breadcrumb-item active">
      {{ student.name }}
      {{ student.surname }}
    </li>
  </ol>
</nav>

<h1>{{student.name}} {{student.surname}}</h1>

<h2 class="mt-5">Corsi seguiti</h2>
<ul class="w-100 d-flex align-items-start flex-column p-0 mt-3">
  <li *ngFor="let course of student.courses"
    class="py-3 py-lg-2 row justify-content-start col-12 col-8 border-bottom
    align-items-center">
    <div class="d-flex flex-column flex-lg-row ">
      <div class="col-12 col-lg-7 d-flex align-items-center
        justify-content-start">
        <div class="d-flex align-items-center">
          <img *ngIf="course.icon && course.icon !== ''; else defaultIcon"
            width="32px" height="32px" [src]="'assets/icons/subjects/' +
            course.icon + '.png'">
          <ng-template #defaultIcon>
            <img width="32px" height="32px"
              src="assets/icons/subjects/default.png">
          </ng-template>
        </div>
        <p class="h4 ms-2 mb-0" id="courseProgress">{{course.name}}</p>
      </div>
      <div class="col-12 col-lg-5 d-flex align-items-center
        justify-content-end">
        <div class="col-11 pe-2">
          <div class="progress">
            <div class="progress-bar bg-success" role="progressbar"
              aria-valuenow="course.completedLessons"
              aria-valuemin="0" aria-valuemax="course.totalLessons"
              [ngStyle]="{'width':
              this.getPercentage(course.completedLessons,
              course.totalLessons)}"></div>
          </div>
        </div>
        <div class="col-1">
          <p class="fw-bold mt-3 text-end text-nowrap">{{course.completedLessons}}
            /
            {{course.totalLessons}}</p>
        </div>
      </div>
    </div>
  </li>
</ul>

<h2 class="mt-5">Lezioni completate</h2>

<button (click)="filtriClick()" type="button"
  [ngClass]="show ? 'btn btn-link mt-3 mb-2 px-0 active d-flex
  align-items-center' : 'btn btn-link mt-3 mb-2 px-0 d-flex
  align-items-center'">
  <svg class="icon me-2">
    <use xlink:href="assets/icons/filter.svg#filter"
      href="assets/icons/filter.svg#filter"></use>
  </svg>Filtri
</button>

<ng-template [ngIf]="show">
  <div class="row row-cols-1 row-cols-md-3 from-group g-4 mb-4">
    <div class="col">
      <label for="lessonName" class="form-label">Nome della lezione</label>
      <input id="lessonName" type="text" class="form-control"
        placeholder="Cerca" [(ngModel)]="nameFilter" />
    </div>
    <div class="col">
      <label id="courses" class="form-label">Corsi</label>
        <ng-multiselect-dropdown
          [placeholder]="'Scegli il corso...'"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          [(ngModel)]="selectedItems">
        </ng-multiselect-dropdown>
    </div>
    <!-- da mettere il range di valori accettabili! -->
    <div class="col">
      <label id="points" class="form-label">Punteggio</label>
      <div class="d-flex align-items-center">
      Da <input id="valMin" type="number" class="form-control w-50 ms-2" min="0" max="100" [(ngModel)]="scoreFromFilter">
      a <input id="valMax" type="number" class="form-control w-50 ms-2" min="0" max="100" [(ngModel)]="scoreToFilter">
    </div>
  </div>
  </div>

  <div class="d-flex mt-4 mb-3">
    <button (click)="resetFilter()" class=" btn btn-sm btn-outline-secondary
      reset" type="button">
      Azzera
    </button>
    <button class="btn btn-sm btn-primary ms-2" type="submit" (click)="runFilter()">
      Filtra
    </button>
  </div>
</ng-template>
<div class="table-responsive overflow-x-scroll">
  <table class="table table-hover">
    <thead class="sticky-top">
      <tr class="align-middle border-bottom border-2">
        <th>Corso
        </th>
        <th>Lezione
        </th>
        <th>Punteggio
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <tr *ngFor="let lessonStat of elencoLezioniStudStatFiltered">
          <td>{{lessonStat.courseName}}</td>
          <td>{{lessonStat.title}}</td>
          <td>{{lessonStat.score}}</td>
          <td>
            <div class="d-flex h-100 float-end">
              <a *ngIf="student.active === true" role="button"
                class="text-primary h-100" tooltip="Informazioni"
                placement="top" (click)="goToTestResult(lessonStat)" >
                <svg class="icon me-3">
                  <use xlink:href="assets/icons/info.svg#info"
                    href="assets/icons/info.svg#info"></use>
                </svg>
              </a>
            </div>
          </td>
        </tr>

      </tbody>
    </table>
  </div>

  <div>
    <ngb-pagination (pageChange)="changePage($event)"
      [collectionSize]="totalElements" [(page)]="page" [maxSize]="10"
      [rotate]="true" [ellipses]="true" [boundaryLinks]="true"
      [pageSize]="pageSize">
    </ngb-pagination>
  </div>

  <button class="btn btn-outline-secondary me-4 float-end align-items-center">
    <svg class="icon me-2">
      <use xlink:href="assets/icons/download.svg#download"
        href="assets/icons/download.svg#download"></use>
    </svg>
    Scarica risultati
  </button>

  <div class="mt-5">
    <a role="button" class="text-primary mt-4 btn btn-link" (click)="back()">
      <svg class="icon me-2">
        <use xlink:href="assets/icons/annulla.svg#annulla"
          href="assets/icons/annulla.svg#annulla"></use>
      </svg>
      <span>Torna indietro</span>
    </a>
  </div>
