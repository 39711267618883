import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Answer, Course, Lesson, LessonDetail, Question, UpdatableLesson } from '../../models/data-model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import jsonDoc from '../lesson-modify-page/doc';
import { Editor, toHTML, Toolbar } from 'ngx-editor';
import { QuestionsService } from '../../../services/questions.service';
import { WDialogService } from 'src/app/modules/shared/components/w-dialog/services/w-dialog.service';
import { DlgType } from 'src/app/modules/shared/components/w-notification/models/notification-models';
import { Router } from '@angular/router';
import { WNotificationService } from 'src/app/modules/shared/components/w-notification/services/w-notification.service';
import { Location  } from '@angular/common';
import { LessonsService } from '../../../services/lessons.service';
declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'wave-eduxible-test-modify-page',
  templateUrl: './test-modify-page.component.html',
  styleUrls: ['./test-modify-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TestModifyPageComponent implements OnInit {
  course!: Course;
  lesson!: LessonDetail;
  question!: Question;
  arrayIsValid!: boolean[];
  showQuestionModel = false;
  questions: Question[] = [];
  questionsToSave: Question[] = [];
  answersToSave!: Answer[];
  isNew: boolean = true;
  messages: string[] = [];
  soglia: number = 1;
  tseconds!: number;
  qseconds!: number;
  editors: Editor[] = [];
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    /*['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],*/
  ];
  form = new FormGroup({
    editorContent: new FormControl(
      { value: jsonDoc, disabled: false }
    ),
  });
  get doc(): AbstractControl | null {
    return this.form.get('editorContent');
  }

  constructor(private fb: FormBuilder, private dialogService: WDialogService,
    private alertService: WNotificationService,
    private router: Router, private questionsService: QuestionsService,
    public location: Location, private lessonService: LessonsService
    ) {
  }

  ngOnInit(): void {
    this.course = JSON.parse(localStorage.getItem('course')!);
    this.lesson = JSON.parse(localStorage.getItem('lesson')!);
    this.soglia = this.lesson.threshold;
    this.qseconds = this.lesson.secondsPerQuestion;
    this.tseconds = this.lesson.secondsTest;
    let editor = new Editor();
    this.editors.push(editor);
    let example: Question = {
      id: 0,
      text: "",
      deleted: false,
      answers: []
    };

    this.questions.push(example);

    this.arrayIsValid = [];
    this.arrayIsValid.push(true);

    if(this.lesson && this.lesson.id && this.course && this.course.id){
      this.questionsService.getQuestionList(this.course.id, this.lesson.id).subscribe(
        (res) => {
          if(res && res.content.length > 0){
            this.isNew = false;
            this.questions.splice(0);
            for(var i=0; i<res.content.length; i++){
              if(!res.content[i].deleted){
                let tmpQ: Question = res.content[i];
                let tmpA: Answer[] = res.content[i].answers.filter((a) => !a.deleted);
                tmpQ.answers = tmpA;
                this.questions.push(tmpQ);
              }
            }

            this.editors.splice(0);
            this.arrayIsValid = [];
            for(var i=0; i<this.questions.length; i++){
              let editor = new Editor();
              this.editors.push(editor);
              this.arrayIsValid.push(true);
            }
          }
        }
      )
    }
  }

  ngOnDestroy(): void {
    for (var i = 0; i < this.editors.length; i++) {
      this.editors[i].destroy();
    }
  }

  trackBy(index: number, item: any) {
    return index;
  }

  stripHTML(text: string): string {
    const regexForStripHTML = /<([^</> ]+)[^<>]*?>[^<>]*?<\/\1> */gi;
    return $(text).text();
  }

  checkQuestionFormValid(): boolean {
    var ret: boolean = true;
    this.messages = []
    this.arrayIsValid = [];
    for(var i=0; i<this.questions.length; i++){
      this.arrayIsValid.push(true);
      if(this.questions[i].text.length <= 0){
        ret = false;
        this.messages.push("Inserisci il testo della domanda numero " + (i+1) + ".");
        this.arrayIsValid[i] = false;
      }
      if(this.questions[i].answers.length <= 1){
        ret = false;
        this.messages.push("Inserisci almeno 2 risposte nella domanda numero " + (i+1) + ".");
        this.arrayIsValid[i] = false;
      }
      var hasAnswerFlagged = false;
      for(var j=0; j<this.questions[i].answers.length; j++){
        if(this.questions[i].answers[j].text.length <= 0){
          ret = false;
          this.messages.push("Inserisci il testo della risposta numero " + (j+1) + " della domanda numero " + (i+1) + ".");
          this.arrayIsValid[i] = false;
        }
        if(this.questions[i].answers[j].correct){
          hasAnswerFlagged = true;
        }
      }
      if(!hasAnswerFlagged){
        ret = false;
        this.messages.push("Seleziona la risposta corretta della domanda numero " + (i+1) + ".");
        this.arrayIsValid[i] = false;
      }
    }
    return ret;
  }

  addQuestion() {
    if(!this.checkQuestionFormValid()){
      return;
    }

    this.arrayIsValid.push(true);

    let editor = new Editor();
    this.editors.push(editor);

    let example: Question = {
      id: 0,
      text: "",
      deleted: false,
      answers: []
    };

    this.questions.push(example);
  }

  addOption(index: number) {
    let op: Answer = {
      id: 0,
      text: "",
      correct: false,
      deleted: false,
      checkedByStudent: false
    };
    this.questions[index].answers.push(op);
  }

  removeOption(index: number, index2: number) {
    this.questionsService.deleteAnswer(this.course.id, this.lesson.id, this.questions[index].id, this.questions[index].answers[index2].id).subscribe();
    this.questions[index].answers.splice(index2, 1);
  }

  removeQuestion(index: number, course: Course, lesson: LessonDetail, question: Question) {
    if (this.questions[index].id > 0) {
      this.questionsService.deleteQuestion(course.id, lesson.id, this.questions[index].id).subscribe();
    }
    this.editors.splice(index, 1);
    this.questions.splice(index, 1);
  }

  // SPOSTARE IL RISPOSTA
  dropOption(
    questionIndex: number,
    event: CdkDragDrop<any>
  ) {
    const question = this.questions[questionIndex];
    moveItemInArray(question.answers, event.previousIndex, event.currentIndex);
  }

  //SPOSTARE IL DOMANDE
  dropQuestion(event: CdkDragDrop<any>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
    moveItemInArray(this.arrayIsValid, event.previousIndex, event.currentIndex);
  }

  changeIsCorrect(i: number, j: number): void {
    for(var k=0; k<this.questions[i].answers.length; k++){
      this.questions[i].answers[k].correct = false;
    }
    this.questions[i].answers[j].correct = true;
  }

  back(){
    this.location.back();
  }

  saveChanges() {
    if(!this.checkQuestionFormValid()){
      return;
    }
    if(this.soglia > this.questions.length){
      this.messages = [];
      this.messages.push("Imposta un numero minimo di risposte esatte minore del totale delle domande (" + this.questions.length + ")");
      return;
    }
    const updatableLesson: UpdatableLesson = {
      progressiveNumber: this.lesson.progressiveNumber,
      professorId: this.lesson.professor.id,
      title: this.lesson.title,
      content: this.lesson.content,
      threshold: this.soglia,
      secondsPerQuestion: this.qseconds,
      secondsTest: this.tseconds
    };
    this.lessonService.modifyLesson(this.course.id, this.lesson.id, updatableLesson).subscribe(
      {next: (lessonModified)=>{
        console.log('LMD', lessonModified);

        console.log('questions', this.questions);
        //for (var i = 0; i < this.questions.length; i++) {
        var len = this.questions.length;
        var cnt = 0;
        this.questions.forEach(q => {
          if(q.id == 0){
            this.questionsService.addQuestion(this.course.id, this.lesson.id, q).subscribe(
              (res) => {
                cnt++;
                if(cnt == len) {
                  this.alertService.open({
                    titolo: "Successo",
                    tipo: DlgType.SUCCESS,
                    messaggio: `Test inserito correttamente.`,
                    cancelLabel: "",
                    cancelVisible: true,
                    okLabel: "",
                    codErrore: 0
                  });
                  this.router.navigate(['/lezioni']);
                }
              },
              err => {
                //alert("errore nel salvataggio");
                /*this.alertService.open({
                  titolo: "Errore",
                  tipo: DlgType.ERROR,
                  messaggio: `Errore nel salvataggio.`,
                  cancelLabel: "",
                  cancelVisible: true,
                  okLabel: "",
                  codErrore: err
                });*/
              }
            )
          } else {
            this.questionsService.editQuestion(this.course.id, this.lesson.id, q).subscribe(
              (res) => {
                cnt++;
                if(cnt == len) {
                  this.alertService.open({
                    titolo: "Successo",
                    tipo: DlgType.SUCCESS,
                    messaggio: `Test salvato correttamente.`,
                    cancelLabel: "",
                    cancelVisible: true,
                    okLabel: "",
                    codErrore: 0
                  });
                  this.router.navigate(['/lezioni']);
                }
              },
              err => {
                //alert("errore nel salvataggio");
                /*this.alertService.open({
                  titolo: "Errore",
                  tipo: DlgType.ERROR,
                  messaggio: `Errore nel salvataggio.`,
                  cancelLabel: "",
                  cancelVisible: true,
                  okLabel: "",
                  codErrore: err
                });*/
              }
            )
          }
        });
      }
    });
  }

  goToLessonModify() {
    this.router.navigate(['/dettaglio_lezione'], { state: {countLess: 0}});
  }
}

