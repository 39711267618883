import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import { Router } from '@angular/router';
import { ProfessorFilter } from '../../../models/data-model';
import { User } from 'src/app/modules/shared/model/user';

@Component({
  selector: 'wave-eduxible-filter-teacher',
  templateUrl: './filter-teacher.component.html',
  styleUrls: ['./filter-teacher.component.scss']
})
export class FilterTeacherComponent implements OnInit{
  form!: FormGroup;
  resetFilterProf !: ProfessorFilter;
  formFilter!: ProfessorFilter;
  filterProf!: ProfessorFilter;
  @Input() filterList!: ProfessorFilter;
  @Output() formSubmittedProf = new EventEmitter<ProfessorFilter>();
  user!: User;

  get f() {
    return this.form.controls as any;
  }
  show = false;
  constructor(fb: FormBuilder,public router: Router) {
    this.form = fb.group({
      name: new FormControl(),
      surname: new FormControl(),
      email: new FormControl(),
      active: new FormControl('true'),
      admin: new FormControl()
    })
}

  ngOnInit() {
    if(this.filterList){
      this.filterList.name !== null ? this.f.name.patchValue(this.filterList.name) : this.form.value.name;
      this.filterList.surname !== null ? this.f.surname.patchValue(this.filterList.surname) : this.form.value.surname;
      this.filterList.email !== null ? this.f.email.patchValue(this.filterList.email) : this.form.value.email;
      this.filterList.active !== null ? this.f.active.patchValue(this.filterList.active) : this.form.value.active;
      this.filterList.admin !== null ? this.f.admin.patchValue(this.filterList.admin) : this.form.value.admin;
    }
  }

  onSubmit($event: SubmitEvent) {
    console.log('Submitted prof filter', $event);

    this.filterProf = {
      name: this.form.value.name,
      surname: this.form.value.surname,
      email: this.form.value.email,
      active: this.form.value.active,
      admin: this.router.url !== '/utenti' ? this.form.value.admin : 'true'
    }
    this.formSubmittedProf.emit(this.filterProf);
  }
  filtriClick(){
    this.show = !this.show;
  }

  resetFilter() {
    this.form.reset();
    this.filterProf = {
      name: this.form.value.name,
      surname: this.form.value.surname,
      email: this.form.value.email,
      active: this.form.value.active,
      admin: this.router.url !== '/utenti' ? this.form.value.admin : 'true'
    }
    this.formSubmittedProf.emit(this.filterProf);
  }
}
