import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StudentModalComponent } from 'src/app/modules/shared/components/modals/student-modal/student-modal.component';
import { GenericHttpService } from 'src/app/modules/shared/services/generic-http.service';
import { environment } from 'src/environments/environment';
import { CoursesService } from '../../../services/courses.service';
import { LessonsService } from '../../../services/lessons.service';
import { StudentService } from '../../../services/student.service';
import {
  Course,
  CourseCombo,
  CoursePage,
  Lesson,
  LessonDetail,
  LessonFilter,
  LessonPage,
  LessonStat,
  Student,
  StudentFilter,
  StudentStatsTest,
  StudentTestStatsPage,
} from '../../models/data-model';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'wave-eduxible-student-detail-page',
  templateUrl: './student-detail-page.component.html',
  styleUrls: ['./student-detail-page.component.scss'],
})
export class StudentDetailPageComponent {
  student!: Student;
  course!: Course;
  lesson!: LessonStat;
  elencoLezioni!: LessonDetail[];
  page = 1;
  totalElements!: number;
  elencoLezioniEliminate!: Lesson[];
  elencoCorsi: Course[] = [];
  @Output() filterStud!: StudentFilter;
  filterStudDetail!: LessonFilter;
  baseURL = '/api';
  pageSize: number = environment.size;
  elencoLezioniStat!: LessonStat[];
  elencoLezioniStudStat!: StudentStatsTest[];
  elencoLezioniStudStatFiltered!: StudentStatsTest[];
  show = false;
  dropdownSettings = {};
  dropdownList: CourseCombo[] = [];
  selectedItems: CourseCombo[] = [];
  nameFilter: string = "";  
  scoreFromFilter: number = 0;
  scoreToFilter: number = 100;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private genericHttpService: GenericHttpService,
    private lessonService: LessonsService,
    private studentService: StudentService,
    private coursesService: CoursesService,
    public location: Location
  ) {
    this.filterStud = {};
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.course = JSON.parse(localStorage.getItem('course')!);
    this.student = JSON.parse(localStorage.getItem('student')!);
    console.log('Studente', this.student);

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleziona tutti',
      unSelectAllText: 'Deseleziona tutti',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      searchPlaceholderText: 'Cerca...'
    };

    this.getLessonList(0, this.course.id);
    this.getStudentStats(this.student.id, 0);
  }

  editStudent(student: Student) {
    console.log(student);
    const modalRef = this.modalService.open(StudentModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.student = student;
    modalRef.componentInstance.action = 'edit';
    modalRef.componentInstance.title = 'Modifica studente';
    modalRef.componentInstance.confirmButton = 'Modifica';
    modalRef.result
      .then((editForm) => {
        window.location.reload();
        console.log('editForm: ', editForm);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  filtriClick() {
    this.show = !this.show;
  }

  resetFilter(){
    this.scoreFromFilter = 0;
    this.scoreToFilter = 100;
    this.nameFilter = "";
    this.selectedItems = [];
    this.elencoLezioniStudStatFiltered = this.elencoLezioniStudStat;
  }

  runFilter(){
    this.elencoLezioniStudStatFiltered = this.elencoLezioniStudStat.filter((obj) => {
      return (this.nameFilter.length == 0 || (this.nameFilter.length > 0 && obj.title.toLocaleLowerCase().includes(this.nameFilter.toLocaleLowerCase()))) &&
             (this.selectedItems.length == 0 || (this.selectedItems.length > 0  && this.selectedItems.some(item => item.id == obj.courseId))) &&
             (this.scoreFromFilter <= obj.score && this.scoreToFilter >= obj.score)
    });
  }

  getLessonList(page: number, courseID: number) {
    this.lessonService.getLessonList(page, courseID).subscribe({
      next: (lesson: LessonPage) => {
        console.log(lesson.content);
        this.elencoLezioni = lesson.content;
      },
    });
  }

  changePage($event: number) {
    console.log('Page:', $event);
    this.page = $event;
    this.getStudentStats(this.page - 1, this.student.id);
  }

  getPercentage(completedLesson: number, totalLesson: number): string {
    const perc = Math.round((completedLesson / totalLesson) * 100);
    return perc + '%';
  }

  getStudentStats(studentId: number, page: number) {
    this.studentService.getStudentStats(studentId, page).subscribe({
      next: (stats: StudentTestStatsPage) => {
        console.log('STAT LESS', stats);
        this.elencoLezioniStudStat = stats.content;
        this.totalElements = stats.totalElements;
        // popola dropdown corsi
        this.dropdownList = [];
        this.elencoLezioniStudStat.forEach(item => {
          item.score = (Math.round(item.score))/10;
          
          let c: CourseCombo = {
            id: item.courseId,
            name: item.courseName,
            active: true
          };
          if((this.dropdownList.find(item => item.id == c.id)) === undefined){
            this.dropdownList.push(c);
          }
        });
        this.elencoLezioniStudStatFiltered = this.elencoLezioniStudStat;
      },
    });
  }

  goToTestResult(les: StudentStatsTest){
      this.lessonService
      .getTestDetail(les.courseId, les.lessonId, les.testId)
      .subscribe((testDetail) => {
        localStorage.removeItem('testDetail');
        console.log(testDetail);
        localStorage.setItem('testDetail', JSON.stringify(testDetail));
        this.router.navigate(['/test_studente'], {});
      });
  }

  back() {
    this.location.back();
  }
}
