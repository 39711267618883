import { Component, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs';
import { DlgType, NotificationConfig } from './models/notification-models';
import { WNotificationService } from './services/w-notification.service';

@Component({
  selector: 'wave-eduxible-w-notification',
  templateUrl: './w-notification.component.html',
  styleUrls: ['./w-notification.component.scss']
})
export class WNotificationComponent implements OnInit {

  private subscribed: boolean = true;
  config!: NotificationConfig;
  dialogVisible: boolean = false;
  constructor(private alertService: WNotificationService) { }

  ngOnInit(): void {
    this.alertService.configObs
      .pipe(takeWhile(() => this.subscribed))
      .subscribe((config) => {
        // console.log('subscribe');
        if (config)
          this.open(config);
      });
  }

  private open(config: NotificationConfig) {
    this.config = {
      tipo: config.tipo ?? DlgType.INFO,
      codErrore: config.codErrore ?? 0,
      titolo: config.titolo ?? 'Avviso',
      messaggio: config.messaggio ?? 'Avviso',
      cancelVisible: config.cancelVisible ?? false,
      okLabel: config.okLabel ?? 'OK',
      cancelLabel: config.cancelLabel ?? 'Cancel',
      // okHandler: config.okHandler ?? (() => {})
    }, {
      tipo: config.tipo ?? DlgType.SUCCESS,
      codErrore: config.codErrore ?? 0,
      titolo: config.titolo ?? 'Successo',
      messaggio: config.messaggio ?? 'Successo',
      cancelVisible: config.cancelVisible ?? false,
      okLabel: config.okLabel ?? 'OK',
      cancelLabel: config.cancelLabel ?? 'Cancel',
    }, {
      tipo: config.tipo ?? DlgType.WARNING,
      codErrore: config.codErrore ?? 0,
      titolo: config.titolo ?? 'Attenzione',
      messaggio: config.messaggio ?? 'Attenzione',
      cancelVisible: config.cancelVisible ?? false,
      okLabel: config.okLabel ?? 'OK',
      cancelLabel: config.cancelLabel ?? 'Cancel',
    }, {
      tipo: config.tipo ?? DlgType.ERROR,
      codErrore: config.codErrore ?? 0,
      titolo: config.titolo ?? 'Errore',
      messaggio: config.messaggio ?? "Errore",
      cancelVisible: config.cancelVisible ?? false,
      okLabel: config.okLabel ?? 'OK',
      cancelLabel: config.cancelLabel ?? 'Cancel',
    } as NotificationConfig;
    this.dialogVisible = true;
    setTimeout(() => this.dialogVisible = false, 5000)
  }

  // close(okClicked: boolean){
  //   if (okClicked) {
  //     this.config.okHandler();
  //   }
  //   this.dialogVisible = false;
  // }
}
