<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/progressi">I miei progressi</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Dettaglio corso</li>
    </ol>
  </nav>
  <div class="d-flex">
    <ul
      ngbNav
      #nav="ngbNav"
      [(activeId)]="active"
      class="nav-pills"
      orientation="vertical"
    >
      <li *ngFor="let lesson of elencoLezioni" ngbNavItem="{{lesson.id}}" >
        <a (click)="getLessonDetail(this.course.id, lesson.id)" ngbNavLink >
          {{lesson.title}}</a>
        <ng-template ngbNavContent>
          <h2>{{course.name}}</h2>
         <h5>{{lesson.title}}</h5>
         <ng-template [ngIf]="!test">
         <h6 class="pt-3">Contenuti della lezione</h6>
          <p [innerHTML]="detail?.content">
           {{detail?.content}}
          </p>
          <button class="btn btn-primary" (click)="goToTest()">Vai al test</button>
        </ng-template>
        <ng-template [ngIf]="test">
          <wave-eduxible-student-test></wave-eduxible-student-test>
          <button class="btn btn-primary" (click)="backToLesson()">Torna alla lezione</button>
        </ng-template>

      </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="ms-4"></div>
  </div>


