import {NgModule} from '@angular/core';
import {AuthModule} from 'angular-auth-oidc-client';
import {AppRoutingModule} from "../../../../app-routing.module";


@NgModule({
    imports: [AuthModule.forRoot({
        config: {
              authority: 'https://auth.cloud.apeironlab.tech/realms/Eduxible',
              redirectUrl: window.location.origin,
              postLoginRoute: window.location.origin + '/home',
              postLogoutRedirectUri: window.location.origin,
              clientId: 'webapp',
              scope: 'openid profile',
              responseType: 'code',
              silentRenew: true,
              silentRenewUrl: window.location.origin + '/silent-renew.html',
              useRefreshToken: true,
              renewTimeBeforeTokenExpiresInSeconds: 1,
              ignoreNonceAfterRefresh: true
          }
      }),
    AppRoutingModule],
    exports: [AuthModule],
})
export class AuthConfigModule {}
