<button class="btn btn-secondary float-end btn-sm" (click)="openModalStudent()">
  <svg class="icon me-2">
    <use xlink:href="assets/icons/add-student.svg#add-student"
      href="assets/icons/add-student.svg#add-student"></use>
  </svg>
  Aggiungi studente</button>
<h1 class="fw-bold mt-5">Studenti</h1>
<wave-eduxible-filter-student
  (formSubmittedStud)="raiseFormSubmittedStud($event)"></wave-eduxible-filter-student>
<div class="table-responsive overflow-x-scroll">
  <table class="table table-hover">
    <thead class="sticky-top">
      <tr class="align-middle border-bottom border-2">
        <th class="pe-0">
          <span class="visually-hidden">Attivo</span>
        </th>
        <th>
          Nome
        </th>
        <th>
          Cognome
        </th>
        <th>
          Email
        </th>
        <th>
          Corsi
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let student of elencoStudenti">
        <td class="pe-0" *ngIf="student.active; else notActive">
          <span class="d-inline-block" tooltip="Attivo" placement="top">
            <svg class="icon icon-green">
              <use xlink:href="assets/icons/user.svg#user"
                href="assets/icons/user.svg#user"></use>
            </svg>
          </span>
        </td>
      <ng-template #notActive>
        <td>
          <span class="d-inline-block" tooltip="Non attivo" placement="top">
            <svg class="icon icon-500">
              <use xlink:href="assets/icons/user.svg#user"
                href="assets/icons/user.svg#user"></use>
            </svg>
          </span>
        </td>
      </ng-template>

        <td>{{student.name}}</td>
        <td>{{student.surname}}</td>
        <td>{{student.email}}</td>
        <td>
          <ul class="ps-3">
            <li *ngFor="let course of student.courses">{{course.name}}</li>
          </ul>
        </td>
        <td>
          <div class="d-flex h-100 float-end">
            <a *ngIf="student.active === true" role="button"
              class="text-primary h-100"
              tooltip="Informazioni" placement="top"
              (click)="goToStudentDetail(student)">
              <svg class="icon me-3">
                <use xlink:href="assets/icons/info.svg#info"
                  href="assets/icons/info.svg#info"></use>
              </svg>
            </a>
            <a *ngIf="student.active === true" role="button"
              (click)="editStudent(student)"
              class="text-primary h-100"
              tooltip="Modifica" placement="top">
              <svg class="icon me-3">
                <use xlink:href="assets/icons/modify.svg#modify"
                  href="assets/icons/modify.svg#modify"></use>
              </svg>
            </a>
            <a *ngIf="student.active === true; else noActive" role="button"
              (click)="deleteStudent(student)"
              class="text-danger h-100"
              tooltip="Elimina" placement="top">
              <svg class="icon me-3">
                <use xlink:href="assets/icons/trashcan.svg#trashcan"
                  href="assets/icons/trashcan.svg#trashcan"></use>
              </svg>
            </a>
            <ng-template #noActive>
              <a role="button" (click)="restoreStudent(student)"
                tooltip="Ripristina" placement="top" class=" btn-link
                align-items-center justify-content-end">
                <svg class="icon">
                  <use xlink:href="assets/icons/restore.svg#restore"
                    href="assets/icons/restore.svg#restore"></use>
                </svg>
              </a>
            </ng-template>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div>
  <ngb-pagination
    (pageChange)="changePage($event)"
    [collectionSize]="totalElements"
    [(page)]="page"
    [maxSize]="10"
    [rotate]="true"
    [ellipses]="true"
    [boundaryLinks]="true"
    [pageSize]="pageSize">
  </ngb-pagination>
</div>
