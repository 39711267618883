import { HomeModule } from './modules/home/home.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginPageComponent} from "./modules/login/components/login-page/login-page.component";
import {LoginModule} from "./modules/login/login.module";

const routes: Routes = [
  {path: 'login', loadChildren:() => import('./modules/login/login.module').then(m=>m.LoginModule)},
 // {path: '', component: LoginModule},
  {path: 'home', loadChildren:() => import('./modules/home/home.module').then(m=>m.HomeModule)}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
