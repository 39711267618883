import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../model/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CourseCombo,
  Professor,
  UpdatableProfessor,
} from '../../../../home/home-page/home-components/models/data-model';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfessorService } from 'src/app/modules/home/home-page/services/professor.service';
import { DlgType } from '../../w-notification/models/notification-models';
import { WNotificationService } from '../../w-notification/services/w-notification.service';

@Component({
  selector: 'wave-eduxible-admin-modal',
  templateUrl: './admin-modal.component.html',
  styleUrls: ['./admin-modal.component.scss'],
})
export class AdminModalComponent implements OnInit {
  @Input() title!: string;
  @Input() user!: User;
  @Input() prof!: Professor;
  @Input() action!: string;
  @Input() confirmButton!: string;

  modalForm!: FormGroup;
  message!: string;
  showMessage: boolean = false;
  errorEmail: boolean = true;
  selectedItemsResp: CourseCombo[] = [];
  selectedItemsCollab: CourseCombo[] = [];
  pwd: string = 'passwrd';
  foundError: boolean = false;
  confirmed: boolean = false;
  constructor(
    public modal: NgbActiveModal,
    private router: Router,
    private _fb: FormBuilder,
    public professorService: ProfessorService,
    private alertService: WNotificationService
  ) {}

  ngOnInit() {
    this.selectedItemsResp = this.prof ? this.prof.managerOf : [];
    this.selectedItemsCollab = this.prof ? this.prof.collaboratorOf : [];

    this.modalForm = this._fb.group({
      nome: [this.prof ? this.prof.name : '', Validators.required],
      cognome: [this.prof ? this.prof.surname : '', Validators.required],
      email: [
        this.prof ? this.prof.email : '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      respCorsi: [this.selectedItemsResp],
      collabCorsi: [this.selectedItemsCollab],
    });
    /* collabCorsi: new FormControl('',Validators.required),
       respCorsi: new FormControl('',Validators.required),
      */
    /* ,
       titolo: new FormControl('', Validators.required),
       psw: new FormControl('', Validators.required),*/
  }

  submitForm(formValue: any) {
    this.confirmed = true;
    console.log('FORMVALUE:', formValue);
    let collaboratorOf: number[] = [];
    for (let i = 0; i < this.selectedItemsCollab.length; i++) {
      collaboratorOf.push(this.selectedItemsCollab[i].id);
    }
    let managerOf: number[] = [];
    for (let i = 0; i < this.selectedItemsResp.length; i++) {
      managerOf.push(this.selectedItemsResp[i].id);
    }
    switch (this.action) {
      case 'add':
        let prof: UpdatableProfessor = {
          name: formValue.nome,
          surname: formValue.cognome,
          email: formValue.email,
          managerCourses: managerOf,
          collaboratorCourses: collaboratorOf,
          admin: true,
          title: '',
          pwd: this.pwd,
        };
        this.professorService.addProfessorOrAdmin(prof, true).subscribe({
          next: (val) => {
            this.alertService.open({
              titolo: "Successo",
              tipo: DlgType.SUCCESS,
              messaggio: `Amministratore inserito correttamente.`,
              cancelLabel: "",
              cancelVisible: true,
              okLabel: "",
              codErrore: 0
            });
            this.modal.close(formValue);
          },
          error: (error: HttpErrorResponse) => {
            this.showMessage = true;
            this.message = error.message;
            /*this.alertService.open({
              titolo: "Errore",
              tipo: DlgType.ERROR,
              messaggio: `Errore nell'inserimento dell'amministratore.`,
              cancelLabel: "",
              cancelVisible: true,
              okLabel: "",
              codErrore: 0
            });*/
          },
        });
        break;
      case 'edit':
        let updatableProfessor: UpdatableProfessor = {
          email: formValue['email'],
          name: formValue['nome'],
          surname: formValue['cognome'],
          title: this.prof.title,
          admin: this.prof.admin,
          managerCourses: managerOf,
          collaboratorCourses: collaboratorOf,
          pwd: this.pwd,
        };
        console.log(updatableProfessor);
        let ret;
        this.professorService
          .editProfessor(this.prof.id, updatableProfessor)
          .subscribe({
            next: (val) => {
              ret = val;
              //console.log('STATUS: ', ret);
              this.alertService.open({
                titolo: "Successo",
                tipo: DlgType.SUCCESS,
                messaggio: `Amministratore modificato correttamente.`,
                cancelLabel: "",
                cancelVisible: true,
                okLabel: "",
                codErrore: 0
              });
              this.modal.close(formValue);
            },
            error: (error: any) => {
              console.log('ERROR MESS', error);
              /*this.alertService.open({
                titolo: "Errore",
                tipo: DlgType.ERROR,
                messaggio: `Errore nella modifica dell'amministratore.`,
                cancelLabel: "",
                cancelVisible: true,
                okLabel: "",
                codErrore: 0
              });*/
            },
          });
        break;
    }
  }

  close() {
    this.modal.close();
    this.errorEmail = false;
  }
}
