<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/corsi">Corsi e lezioni</a></li>
    <li class="breadcrumb-item">
      <a routerLink="/dettaglio_corso">{{ course.name }}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Lezioni</li>
  </ol>
</nav>

<h1>Lezioni</h1>
<div class="col-12">
  <div class="w-100 " cdkDropList (cdkDropListDropped)="drop($event, elencoLezioni)">
    <div class="w-100 ">
      <div class="w-100  table-responsive overflow-x-scroll" *ngIf="elencoLezioni?.length !== 0; else noLesson">
        <table class="w-100 table mt-0 mb-0 table-hover align-middle">
          <thead>
            <tr>
              <th scope="col" class="visually-hidden">Cambia ordinamento</th>
              <th scope="col">Titolo</th>
              <th scope="col">Autore</th>
              <th scope="col" class="text-end">Azioni</th>
            </tr>
          </thead>
          <tbody>
            <tr class="py-4" cdkDrag *ngFor="let lesson of
            elencoLezioni">
              <td width="4%" class="cursor-grab">
                <div class="d-flex  align-items-center">
                  <button class="cursor-grab btn btn-icon text-primary p-0">
                    <svg class="icon">
                      <use xlink:href="assets/icons/move.svg#move" href="assets/icons/move.svg#move"></use>
                    </svg>
                    <span class="visually-hidden">Riordina lezione</span>
                  </button>
                  <svg *ngIf="lesson.nquestions < 1" class="icon ms-3 icon-warning" tooltip="Test mancante" placement="top">
                    <use href="assets/icons/warning.svg#warning"></use>
                  </svg>
                </div>
              </td>
              <td width="71%" class="cursor-grab">
                <span class="lead">{{lesson.progressiveNumber}} – <b>{{lesson.title}}</b></span>
              </td>
              <td width="15%" class="cursor-grab">
                <small class="fst-italic">{{lesson.professor.name}} {{lesson.professor.surname}}</small>
              </td>
              <td width="10%" class="cursor-grab">
                <div class="justify-content-end d-flex align-items-center">
                  <a role="button" class="text-primary me-3" tooltip="Test" placement="top" (click)="goToTest(lesson)">
                    <svg class="icon">
                      <use xlink:href="assets/icons/test.svg#test" href="assets/icons/test.svg#test"></use>
                    </svg>
                  </a>
                  <a role="button" (click)="goToLessonModify(lesson.id)" class="text-primary me-3" tooltip="Modifica"
                    placement="top">
                    <svg class="icon">
                      <use xlink:href="assets/icons/modify.svg#modify" href="assets/icons/modify.svg#modify"></use>
                    </svg>
                  </a>
                  <a role="button" class="text-danger" (click)="deleteLesson(course, lesson)" tooltip="Elimina"
                    placement="top">
                    <svg class="icon">
                      <use xlink:href="assets/icons/trashcan.svg#trashcan" href="assets/icons/trashcan.svg#trashcan">
                      </use>
                    </svg>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #noLesson>
    <p class="lead mt-3">
      Non sono ancora state aggiunte lezioni
    </p>
  </ng-template>

  <div class="d-flex justify-content-center mt-4">
    <button (click)="addLesson()" class="btn btn-primary me-3 d-flex
      align-items-center">
      <svg class="icon me-2">
        <use xlink:href="assets/icons/add-lesson.svg#add-lesson" href="assets/icons/add-lesson.svg#add-lesson"></use>
      </svg>Aggiungi lezione
    </button>
  </div>

  <div *ngIf="elencoLezioniEliminate && elencoLezioniEliminate.length> 0" class="accordion mt-5" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
          aria-expanded="true" aria-controls="collapseOne">
          <svg class="icon">
            <use xlink:href="assets/icons/archive.svg#archive" href="assets/icons/archive.svg#archive"></use>
          </svg>
          <span class="ms-3 col">
            Lezioni eliminate
          </span>
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
        data-bs-parent="#accordionExample">
        <div class="accordion-body pb-3">
          <table class="table mt-3 table-hover">
            <tbody>
              <tr *ngFor="let lesson of elencoLezioniEliminate" class="d-flex"
                style="background-color:rgba(255,255,255,0.9)">
                <td class="py-3 col d-flex align-items-center">
                  <span class="lead">{{lesson.progressiveNumber}} – <b>{{lesson.title}}</b></span>
                </td>
                <td class="py-3 col col-sm-3 col-xl-2 justify-content-end d-flex
                  align-items-center">
                  <a role="button" (click)="restoreLesson(course, lesson)" tooltip="Ripristina" placement="top" class="d-inline me-4
                    text-primary">
                    <svg class="icon">
                      <use xlink:href="assets/icons/restore.svg#restore" href="assets/icons/restore.svg#restore"></use>
                    </svg>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-5">
    <a role="button" class="text-primary mt-4 btn btn-link d-flex align-items-center" (click)="back()">
      <svg class="icon me-2">
        <use xlink:href="assets/icons/annulla.svg#annulla" href="assets/icons/annulla.svg#annulla"></use>
      </svg>
      <span>Torna indietro</span>
    </a>
  </div>
