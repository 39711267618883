import { SharedModule } from './../shared/shared.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './home-page/home-components/header/header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page/home-page.component';
import { SideMenuComponent } from './home-page/home-components/side-menu/side-menu.component';
import { InformationPageComponent } from './home-page/home-components/pages/information-page/information-page.component';
import { HomeRoutingModule } from './home-routing.module';
import { ProgressPageComponent } from './home-page/home-components/pages/progress-page/progress-page.component';
import { CoursesDetailTeacherPageComponent } from './home-page/home-components/pages/courses-detail-teacher-page/courses-detail-teacher-page.component';
import { StudentDetailPageComponent } from './home-page/home-components/pages/student-detail-page/student-detail-page.component';
import { TeacherDetailPageComponent } from './home-page/home-components/pages/teacher-detail-page/teacher-detail-page.component';
import { StatisticsPageComponent } from './home-page/home-components/pages/statistics-page/statistics-page.component'; 
import { StudentCoursesDetailPageComponent } from './home-page/home-components/pages/student-course-detail-page/student-courses-detail-page.component';
import { TeachersOverviewPageComponent } from './home-page/home-components/pages/teachers-overview-page/teachers-overview-page.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterTeacherComponent } from './home-page/home-components/components/filter-components/filter-teacher/filter-teacher.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StudentsOverviewPageComponent } from './home-page/home-components/pages/students-overview-page/students-overview-page.component';
import { FilterStudentComponent } from './home-page/home-components/components/filter-components/filter-student/filter-student.component';
import { CoursesOverviewPageComponent } from './home-page/home-components/pages/courses-overview-page/courses-overview-page.component';
import { FilterCourseComponent } from './home-page/home-components/components/filter-components/filter-course/filter-course.component';
import { UsersPageComponent } from './home-page/home-components/pages/users-page/users-page.component';
import { DashboardPageComponent } from './home-page/home-components/pages/dashboard-page/dashboard-page.component';
import { CourseDetailPageComponent } from './home-page/home-components/pages/course-detail-page/course-detail-page.component';
import { ProfilePageComponent } from './home-page/home-components/pages/profile-page/profile-page.component';
import { LessonPageComponent } from './home-page/home-components/pages/lessons-page/lesson-page.component';
import { LessonModifyPageComponent } from './home-page/home-components/pages/lesson-modify-page/lesson-modify-page.component';
import { NgxEditorModule } from 'ngx-editor';
import { TestModifyPageComponent } from './home-page/home-components/pages/test-modify-page/test-modify-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { StudentTestComponent } from './home-page/home-components/pages/student-test/student-test.component';
import { StudentLessonPageComponent } from './home-page/home-components/pages/student-lesson-page/student-lesson-page.component';
import { SurveyModule } from 'survey-angular-ui';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {StatProfComponent} from './home-page/home-components/pages/statistics-components/stat-prof/stat-prof.component';
import { StatStudComponent } from './home-page/home-components/pages/statistics-components/stat-stud/stat-stud.component';
import { StatCoursesComponent } from './home-page/home-components/pages/statistics-components/stat-courses/stat-courses.component';
import { StatAdvancedComponent } from './home-page/home-components/pages/statistics-components/stat-advanced/stat-advanced.component';
import { StudentTestDetailComponent } from './home-page/home-components/pages/student-test-detail/student-test-detail.component';

@NgModule({
  declarations: [
    HomePageComponent,
    SideMenuComponent,
    HeaderComponent,
    InformationPageComponent,
    ProgressPageComponent,
    CoursesDetailTeacherPageComponent,
    StudentDetailPageComponent,
    TeacherDetailPageComponent,
    StatisticsPageComponent,
    StudentCoursesDetailPageComponent,
    TeachersOverviewPageComponent,
    FilterTeacherComponent,
    StudentsOverviewPageComponent,
    FilterStudentComponent,
    CoursesOverviewPageComponent,
    FilterCourseComponent,
    UsersPageComponent,
    DashboardPageComponent,
    CourseDetailPageComponent,
    ProfilePageComponent,
    LessonPageComponent,
    LessonModifyPageComponent,
    TestModifyPageComponent,
    StudentTestComponent,
    StudentLessonPageComponent,
    StatProfComponent,
    StatStudComponent,
    StatCoursesComponent,
    StatAdvancedComponent,
    StudentTestDetailComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    RouterModule,
    NgbPaginationModule,
    ReactiveFormsModule,
    SharedModule,
    NgxEditorModule,
    DragDropModule,
    FormsModule,
    DragDropModule,
    TooltipModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgbNavModule,
    SurveyModule,
    NgCircleProgressModule.forRoot(),
  ],
  exports: [ProfilePageComponent],
})
export class HomeModule {}
