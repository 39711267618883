import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/modules/shared/model/user';
import { AdminModalComponent } from '../../../../shared/components/modals/admin-modal/admin-modal.component';

@Component({
  selector: 'wave-eduxible-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  closeResult = '';
  user!: User;
  isCollapsed: any;

  constructor(private router: Router, private modalService: NgbModal) {}
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')!);
    // console.log(this.user);
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['']);
  }
  /*  open(content: any) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'admin-modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `${result}`;
      },
      (reason) => {
        this.closeResult = `${this.getDismissReason(reason)}`;
      },
    );
  }*/

  /*  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return '';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return '';
    } else {
      return `${reason}`;
    }
  }*/
  /*
  openModal(content: string) {
  console.log('content:',content);
    const modalRef = this.modalService.open(AdminModalComponent, { size: 'lg', centered: true});
    modalRef.componentInstance.user = this.user;
    switch (content){
    case 'course':
      modalRef.componentInstance.title =  'AGGIUNGI CORSO'
      modalRef.componentInstance.type = 'course'
      modalRef.result.then((courseForm) => {
        console.log('courseForm: ', courseForm);
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
      })
      break;
    case 'student':
      modalRef.componentInstance.title =  'AGGIUNGI STUDENTE'
      modalRef.componentInstance.type = 'student'
      modalRef.result.then((studentForm) => {
        console.log('studentForm: ', studentForm);
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
      })
      break;
    case 'professor':
      modalRef.componentInstance.title =  'AGGIUNGI PROFESSORE'
      modalRef.componentInstance.type = 'professor'
      modalRef.result.then((professorForm) => {
        console.log('professorForm: ', professorForm);
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
      })
      break;
    case 'user':
      modalRef.componentInstance.title =  'AGGIUNGI ADMIN'
      modalRef.componentInstance.type = 'admin'
      modalRef.result.then((adminForm) => {
        console.log('adminForm: ', adminForm);
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
      })
      break;
  }}*/

  
}
