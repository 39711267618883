import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogConfig } from '../models/dialog-models';

@Injectable({
  providedIn: 'root'
})
export class WDialogService {

  private config$: Subject<DialogConfig> = new Subject<DialogConfig>();

  get configObs() {
    return this.config$.asObservable();
  }

  // funzione per open della dialog
  open(config: DialogConfig) {
    this.config$.next(config);
  }


}
