<h1>I miei progressi</h1>

<p class="mt-4">Stai andando alla grande, {{utente.user}}! Ecco il dettaglio dei
  tuoi progressi:</p>

<div class="w-100 d-flex align-items-center flex-column">
  <div *ngFor="let course of elencoCorsi" class="mt-4 mb-4 row rounded-4 shadow bg-body col-12 justify-content-center">
    <div class="col-12 ms-4 ms-md-5 mt-4 mb-4 me-4 d-flex align-items-center justify-content-between">
      <div class="col-4 d-flex align-items-center justify-content-start gap-5 gap-lg-4">
        <div class="col-1">
          <img *ngIf="course.icon && course.icon !== ''; else defaultIcon" width="48px" height="48px" [src]="'assets/icons/subjects/' + course.icon + '.png'">
          <ng-template #defaultIcon>
            <img width="48px" height="48px" src="assets/icons/subjects/default.png">
          </ng-template>
        </div>
        <div class="col-3">
          <a class="fw-bold text-dark mt-3 cursor-pointer text-decoration-none linktitle" id="courseProgress" (click)="goToCourseDetail(course)">{{course.name}}</a>
        </div>
      </div>
      <div class="col-8 d-flex align-items-center justify-content-end">
        <div class="col-5 col-sm-6 progress">
          <div class="progress-bar bg-success" role="progressbar" aria-valuenow="course.completedLessons" aria-valuemin="0" aria-valuemax="course.totalLessons" [ngStyle]="{'width': this.getPercentage(course.completedLessons, course.totalLessons)}"></div>
        </div>
        <div class="col-3 col-sm-2 col-lg-1 ms-3">
          <p class="fw-bold mt-3">{{course.completedLessons}} / {{course.totalLessons}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
