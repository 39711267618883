import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor() {}
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const user = JSON.parse(localStorage.getItem('user')!);
    if(user)
      req = req.clone({
         setHeaders: {'Authorization': `Bearer ${user.token}`},
      });
    return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(() => error);
      }),
      //tap(data => console.log(data))
    );
  }
}
