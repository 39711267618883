import {
  StatAdvanced, TrendStat,
} from '../home-components/models/data-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GenericHttpService } from 'src/app/modules/shared/services/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class StatService {
  baseURL = '/api';

  constructor(
    private httpClient: HttpClient,
    private genericHttpService: GenericHttpService
  ) {}

  getAdvancedStat(): Observable<StatAdvanced[]> {
    console.log('GET COURSES');
    return this.genericHttpService.httpGet<StatAdvanced[]>(
      this.baseURL + '/stats/advanced');
  }
  
  getTrendStat(nSub: number, aggr: string): Observable<TrendStat[]>{
    return this.genericHttpService.httpGet<TrendStat[]>(
      this.baseURL + '/stats/trends/tests?aggregation=' + aggr + '&n=' + nSub
    )
  }
}
