import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Editor, Toolbar, Validators } from 'ngx-editor';
import { User } from 'src/app/modules/shared/model/user';
import { AngularComponentFactory } from 'survey-angular-ui';
import { FileUploadService } from '../../../services/file-upload.service';
import { LessonsService } from '../../../services/lessons.service';
import { Course, Lesson, LessonDetail, NewLesson, UpdatableLesson } from '../../models/data-model';

import jsonDoc from './doc';
import { DlgType } from 'src/app/modules/shared/components/w-notification/models/notification-models';
import { WNotificationService } from 'src/app/modules/shared/components/w-notification/services/w-notification.service';
@Component({
  selector: 'wave-eduxible-lesson-modify-page',
  templateUrl: './lesson-modify-page.component.html',
  styleUrls: ['./lesson-modify-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LessonModifyPageComponent implements OnInit, OnDestroy {
  elencoLezioni!: Lesson[];
  elencoLezDettaglio!: LessonDetail[];
  editordoc = jsonDoc;
  editor!: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  form = new FormGroup({
    editorContent: new FormControl(
      { value: jsonDoc, disabled: false },
      Validators.required()
    ),
  });

  course!: Course;
  currentLesson!: LessonDetail;
  currentUser!: User;
  titolo : string = '';
  count!: number;
  mode!:string;
  // Variable to store shortLink from api response
  shortLink: string = '';
  loading: boolean = false; // Flag variable
  file!: File; // Variable to store file
  constructor(
    private router: Router,
    private fileUploadService: FileUploadService,
    private alertService: WNotificationService,
    private lessonService: LessonsService
  ) {
    this.count = this.router.getCurrentNavigation()?.extras.state!['countLess'];
  }
  get doc(): AbstractControl | null {
    return this.form.get('editorContent');
  }

  ngOnInit(): void {
    this.course = JSON.parse(localStorage.getItem('course')!);
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
    this.currentLesson = JSON.parse(localStorage.getItem('lesson')!);
    this.mode = localStorage.getItem('mode')!;

    // this.myLesson = this.currentLesson;
    if(this.currentLesson == null){
    this.titolo = 'Aggiungi lezione';
    this.currentLesson = {
      id: -1,
      progressiveNumber: 0,
      deleted: false,
      professor: {
        id: 0,
        name: '',
        surname: '',
        active: true,
      },
      professorIsManager: false,
      title: '',
      nquestions: 0,
      secondsPerQuestion: 0,
      secondsTest: 0,
      content: '',
      threshold: 0,
      updatedAt: '',
      updatedBy: ''
    };
   }
   else{
    this.titolo = this.mode !== 'view' ? 'Modifica ' + this.currentLesson.title : this.currentLesson.title;
   }
   if(this.currentUser.role === 'Student'){
    this.titolo = this.currentLesson.title;
   }
    console.log('CURRENT LESSON',this.currentLesson);

    this.editor = new Editor();
    window.scrollTo(0,0);
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
  onChange(event: any) {
    this.file = event.target.files[0];
  }
  onUpload() {
    this.loading = !this.loading;
    console.log(this.file);
    this.fileUploadService.upload(this.file).subscribe((event: any) => {
      if (typeof event === 'object') {
        // Short link via api response
        this.shortLink = event.link;

        this.loading = false; // Flag variable
      }
    });
  }

  goToModifyTest() {
    if(this.currentUser.role == 'Student'){
      localStorage.removeItem('lesson');
      localStorage.setItem('lesson', JSON.stringify(this.currentLesson));
      this.router.navigate(['/test']);
    } else {
      this.router.navigate(['/dettaglio_test']);
    }
  }

  goToLessons(){
    this.router.navigate(['/lezioni']);
  }

  saveLessonModify(){
    let local = JSON.parse(localStorage.getItem('lesson')!);

    if(local !== null ){
      const updatableLesson: UpdatableLesson = {
        progressiveNumber: this.currentLesson.progressiveNumber,
        professorId: this.currentLesson.professor.id,
        title: this.currentLesson.title,
        content: this.currentLesson.content,
        threshold: this.currentLesson.threshold,
        secondsPerQuestion: this.currentLesson.secondsPerQuestion,
        secondsTest: this.currentLesson.secondsTest
      };
      this.lessonService.modifyLesson(this.course.id, this.currentLesson.id, updatableLesson).subscribe(
        {next: (lessonModified)=>{
          console.log('LMD', lessonModified);
          this.alertService.open({
            titolo: "Successo",
            tipo: DlgType.SUCCESS,
            messaggio: `Lezione salvata correttamente.`,
            cancelLabel: "",
            cancelVisible: true,
            okLabel: "",
            codErrore: 0
          });
          this.router.navigate(['/lezioni']);
        }}
      );
    }else{
      const newLesson: NewLesson = {
        progressiveLessonNumber: this.count + 1,
        title: this.currentLesson.title,
        content: this.currentLesson.content,
      };
      this.lessonService.addLesson(this.course.id, newLesson).subscribe(
        {next: (newLesson)=>{
          console.log('NEW LESSON', newLesson);
          this.alertService.open({
            titolo: "Successo",
            tipo: DlgType.SUCCESS,
            messaggio: `Lezione inserita correttamente.`,
            cancelLabel: "",
            cancelVisible: true,
            okLabel: "",
            codErrore: 0
          });
          this.router.navigate(['/lezioni']);
        }}
      );
    }
    }
}
