import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/modules/shared/model/user';
import { environment } from '../../../../../../../environments/environment';
import { LessonsService } from '../../../services/lessons.service';

import {
  Course,
  Lesson,
  LessonDetail,
  LessonPage,
} from '../../models/data-model';

@Component({
  selector: 'wave-eduxible-student-courses-detail-page',
  templateUrl: './student-courses-detail-page.component.html',
  styleUrls: ['./student-courses-detail-page.component.scss'],
})
export class StudentCoursesDetailPageComponent implements OnInit {
  utente!: User;
  course!: Course;
  lesson!: LessonDetail;
  elencoLezioni!: LessonDetail[];
  detail!: LessonDetail | null;
  active!: string;
  test : Boolean = false;
  constructor(private router: Router,
    private lessonService: LessonsService) {}

  ngOnInit() {
    this.utente = JSON.parse(localStorage.getItem('user')!);
    this.course = JSON.parse(localStorage.getItem('course')!);
    console.log('UTENTE',this.utente);
    console.log('COURSE',this.course);
    this.getLessonList(0);
  }
  getLessonList(page: number) {
    this.lessonService.getLessonList(page, this.course.id).subscribe({
      next: (lessons: LessonPage) => {
        this.elencoLezioni = lessons.content.filter((l) => {
          this.active = lessons.content[0].id.toString();
          this.getLessonDetail(this.course.id, lessons.content[0].id);
          return !l.deleted;
        });
        console.log('Lezioni', this.elencoLezioni);
      },
    });
  }
  getLessonDetail(courseID: number, lessonID: number) {
    this.lessonService.getLessonDetail(courseID, lessonID).subscribe({
      next: (detail: LessonDetail) => {
        this.detail = detail;
        console.log('DETTAGLIO', detail);
      },
    });
  }
  goToTest(){
    this.test = true;
    console.log('Test Cliccato',this.test);
    localStorage.removeItem('lesson');
    localStorage.setItem('lesson', JSON.stringify(this.detail));
  }
  backToLesson(){
    this.test = false;
  }
}
