import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Chart from 'chart.js/auto';
import { CoursesService } from '../../../../services/courses.service';
import { StudentService } from '../../../../services/student.service';
import {
  Course,
  Student,
  StudentFilterStats,
  StudentStat,
} from '../../../models/data-model';
import { Location } from '@angular/common';

@Component({
  selector: 'wave-eduxible-stat-stud',
  templateUrl: './stat-stud.component.html',
  styleUrls: ['./stat-stud.component.scss'],
})
export class StatStudComponent implements OnInit {
  course!: Course;
  elencoStudenti: Student[] = [];
  myLabels: string[] = [];
  totalElements!: number;
  public chart: any;
  myDataSet: any = [];
  selectGrandezza!: any;
  //per filtri
  form!: FormGroup;
  show = false;
  resetFilterCourse!: StudentFilterStats;
  formFilter!: StudentFilterStats;
  filterStud!: StudentFilterStats;
  studentStats!: StudentStat[];
  studentStatsFiltered!: StudentStat[];
  @Input() filterList!: StudentFilterStats;
  dropdownListStudents: Student[] = [];
  selectedItemsStudents: any = [];
  dropdownSettingsStudents = {};
  //fine
  createChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart('StudChart', {
      type: 'bar', //this denotes tha type of chart

      data: {
        // values on X-Axis
        labels: this.myLabels,
        datasets: [
          {
            label: '',
            data: this.myDataSet,
            backgroundColor: ['rgb(0, 129, 143)'],
            borderColor: ['rgb(0, 129, 143)'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        aspectRatio: 2.5,
        plugins: {
          legend: {
            display: false
          }
        }
      },
    });
  }
  constructor(
    private studentService: StudentService,
    fb: FormBuilder,
    public courseService: CoursesService,
    public location: Location
  ) {
    this.filterStud = {};
    this.filterStud.active = true;
    this.filterStud.students = [];
    this.form = fb.group({
      active: new FormControl('true'),
      students: new FormControl(),
    });
  }
  get f() {
    return this.form.controls as any;
  }
  ngOnInit(): void {
    this.selectGrandezza = 'totCorsi';
    this.getAllStudStats(this.selectGrandezza, null);
    if (this.filterList) {
      this.filterList.active !== null
        ? this.f.active.patchValue(this.filterList.active)
        : this.form.value.active;
      this.filterList.students !== null
        ? this.f.students.patchValue(this.filterList.students)
        : this.form.value.students;
    }

    this.dropdownSettingsStudents = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullname',
      searchPlaceholderText: 'Cerca...',
      noFilteredDataAvailablePlaceholderText: 'Nessun dato trovato',
      selectAllText: 'Seleziona tutti',
      unSelectAllText: 'Deseleziona tutti',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  getAllStudStats(value: string, filter: any) {
    this.studentService.getAllStudStats().subscribe({
      next: (res: any) => {
        console.log('StatsSTUD', res);
        this.dropdownListStudents = [];
        this.studentStats = res.content;
        for(var i=0; i<this.studentStats.length; i++){
          let stud: Student = new Student();
          stud.id = this.studentStats[i].id;
          stud.name = this.studentStats[i].name;
          stud.surname = this.studentStats[i].surname;
          stud.active = this.studentStats[i].active;
          stud.fullname = this.studentStats[i].surname + ' ' + this.studentStats[i].name;                        
          this.dropdownListStudents.push(stud);
        }
        this.myDataSet = [];
        this.myLabels = [];
        this.studentStatsFiltered = [];
        this.studentStats.forEach((l) => {
          l.avgProgressCourses = Math.round(l.avgProgressCourses);
          l.avgScoresLessons = Math.round(l.avgScoresLessons) / 10;
          if (filter !== null) {
            if (filter.active == 'true') {
              if (!l.active) {
                return;
              }
            } else if (filter.active == 'false') {
              if (l.active) {
                return;
              }
            }
            if (filter.students !== null && filter.students.length > 0) {
              if (!filter.students.find((m: number) => l.id == m)) {
                return;
              }
            }
          }
          this.myLabels.push(l.surname + ' ' + l.name);
          if (value === 'totCorsi') {
            this.myDataSet.push(l.totalCourses);
            this.studentStatsFiltered.push(l);
          }
          if (value === 'avanz') {
            this.myDataSet.push(l.avgProgressCourses);
            this.studentStatsFiltered.push(l);
          }
          if (value === 'puntMed') {
            this.myDataSet.push(l.avgScoresLessons);
            this.studentStatsFiltered.push(l);
          }
        });
        console.log(this.myLabels);
        this.createChart();
      },
    });
  }

  onSelect(value: string) {
    this.selectGrandezza = value;
    console.log(this.selectGrandezza);
    this.getAllStudStats(
      value,
      this.filterStud.active !== null || this.filterStud.students !== null
        ? this.filterStud
        : null
    );
  }

  filtriClick() {
    this.show = !this.show;
  }

  resetFilter() {
    this.selectedItemsStudents = [];
    this.runFilter($localize);
  }

  runFilter($event:any){
    console.log('Submitted course filter', $event);
    let studentsIds: number[] = [];
    if (this.selectedItemsStudents && this.selectedItemsStudents.length > 0) {
      for (var i = 0; i < this.selectedItemsStudents.length; i++) {
        studentsIds.push(this.selectedItemsStudents[i].id);
      }
    }
    this.filterStud = {
      active: this.form.value.active,
      students: studentsIds,
    };
    console.log('filterCourse in submit: ', this.filterStud);
    this.getAllStudStats(this.selectGrandezza, this.filterStud);
  }

  back() {
    this.location.back();
  }
}
