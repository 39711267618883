import { WNotificationService } from './../components/w-notification/services/w-notification.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { NgEventBus } from 'ng-event-bus';
import {ErrorRest, Messaggi, ServiziError, TypeErrorRest,AlertTypeDesc} from 'src/app/common/messaggi';
import { DlgType } from '../components/w-notification/models/notification-models';

@Injectable({
  providedIn: 'root'
})
export class GenericHttpService {

  constructor(private httpClient: HttpClient,
    private eventBus: NgEventBus,
    private router: Router,
    private alertService: WNotificationService) {
  }

  httpGet<T>(apiURL: string, options?: any, sendMessage?: boolean): Observable<T> {
    return this.httpClient.get<T>(apiURL, options).pipe(
      catchError((err) => this.handleError(err,sendMessage))
    ) as Observable<T>;
  }

  httpPost<V, T = void>(apiURL: string, body?: V, options?: any, sendMessage?: boolean): Observable<T> {
    return this.httpClient.post<T>(apiURL, body, options).pipe(
      catchError((err) => this.handleError(err,sendMessage))
    ) as Observable<T>;
  }

  httpPatch<V, T = void>(apiURL: string, body?: T, options?: any, sendMessage?: boolean): Observable<T> {
    return this.httpClient.patch<T>(apiURL, body, options).pipe(
      catchError((err) => this.handleError(err,sendMessage))
    ) as Observable<T>;
  }

  httpPut<V, T = void>(apiURL: string, body?: T, options?: any, sendMessage?: boolean): Observable<T> {
    return this.httpClient.put<T>(apiURL, body, options).pipe(
        catchError((err) => this.handleError(err,sendMessage))
    ) as Observable<T>;
  }


  httpDelete<T>(apiURL: string, options?: any, sendMessage?: boolean): Observable<T> {
    return this.httpClient.delete<T>(apiURL, options).pipe(
      catchError((err) => this.handleError(err,sendMessage))
    ) as Observable<T>;
  }

  protected handleError(error: HttpErrorResponse, sendMessage? :boolean){
    // Handle the HTTP error here
    let msg ;
    // let send: boolean = sendMessage ? sendMessage : true;
    let send : boolean = true;
    if ((sendMessage !== undefined) && !sendMessage){
      send = sendMessage;
    }
    else{
      send = true;
    }
    console.error('Errore invocazione servizio http: ', error.error);

    switch (error.status) {
      case 0: {
        msg =  ServiziError.GENERIC;
        if (send) this.eventBus.cast('alert:set', { text: msg, type: AlertTypeDesc.ERROR} );
        break;
      }
      case 401: {
        msg = ServiziError.UNAUTHORIZED;
        this.router.navigate(['forbidden']);
        break;
      }
      case 403: {
        msg = ServiziError.UNAUTHORIZED;
        this.router.navigate(['forbidden']);
        break;
      }
      case 404: {
        msg = ServiziError.NOT_FOUND;
        if (send) this.eventBus.cast('alert:set', { text: msg, type: AlertTypeDesc.ERROR} );
        break;
      }
      case 400: {
        console.error(`Errore Backend http code ${error.status}, body: `, error.error);
        // msg = error?.error?.code?.startsWith('MOONBO') ? error?.error?.code + ' - ' : '';
        msg += (error?.error?.msg !== undefined ? error.error.msg : error.status);
        // fixme impostare codice corretto
        // if(error?.error?.code === 'MOONXXXXX'){
        //     msg = Messaggi.messaggioUtenteNoAuth;
        // }
        if (send) this.eventBus.cast('alert:set', { text: msg, type: AlertTypeDesc.ERROR} );
        break;
      }
      //  case 412:
      //    this.alertService.open({
      //      titolo: "Errore",
      //      codErrore: 412,
      //      tipo: DlgType.ERROR,
      //      messaggio: `Utente già creato`,
      //      cancelLabel: "",
      //      cancelVisible: true,
      //       //okHandler: () => undefined,
      //      okLabel: "",
      //    });
      //    break;
      default: {
        console.error(`Errore Backend http code ${error.status}, body: `, error.error);
        msg = (error?.error?.code || '') + ' Invocazione servizi business-codice: ' + (error?.error?.msg !== undefined ? error.error.msg : error.status);
        if (send) this.eventBus.cast('alert:set', { text: msg, type: AlertTypeDesc.ERROR} );
        break;
      }
    }

    this.alertService.open({
      titolo: 'Errore',
      tipo: DlgType.ERROR,
      messaggio: error != null ? error.error.message : 'Problema sul server. Riprova più tardi.',
      cancelLabel: '',
      cancelVisible: true,
      okLabel: '',
      codErrore: 0,
    });

    let err = new ErrorRest(TypeErrorRest.HTTP, msg, error.status, error.error?.title);

    return throwError(() => err);

  }
}
