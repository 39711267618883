import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/modules/shared/model/user';
import { ProfessorService } from '../../../services/professor.service';
import { StudentService } from '../../../services/student.service';
import { Course, Professor, Student } from '../../models/data-model';

@Component({
  selector: 'wave-eduxible-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent {
  user!: User;
  modalForm!: FormGroup;
  studente!: Student;
  professore!: Professor;
  constructor(public router: Router, private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user')!);
    console.log('user in profile-page', this.user);
    this.modalForm = this._fb.group({
      nome: [this.user.user, Validators.required],
      cognome: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
    });
    if (this.user.role === 'Student') {
      this.modalForm.disable();
    }
  }
}
