<div class="d-flex flex-column min-h-100-vh">
  <wave-eduxible-header></wave-eduxible-header>
  <div [ngClass]="user.role !== 'Student' ? 'main-grid-template col' : ''">
    <ng-template [ngIf]="user.role === 'Professor' || user.role === 'Admin'">
    <div class="side-menu border-end h-100 d-none d-md-block">
        <wave-eduxible-side-menu></wave-eduxible-side-menu>
      </div>
    </ng-template>
    <div class="p-4 main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>