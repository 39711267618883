import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/modules/shared/model/user';
import { StudentService } from '../../../services/student.service';
import { Course, CourseFilter, CoursePage, Student, StudentFilter, StudentPage } from '../../models/data-model';

@Component({
  selector: 'wave-eduxible-progress-page',
  templateUrl: './progress-page.component.html',
  styleUrls: ['./progress-page.component.scss']
})
export class ProgressPageComponent implements OnInit{
  utente!: User;
  elencoCorsi!: Course[];
  elencoStudenti!: Student[];
  courseFilter!: CourseFilter;
  student!: Student;
  icon: string = "";
  constructor(private router: Router, private studentService: StudentService) {
    this.courseFilter = {};
  }

  ngOnInit() {
    this.utente = JSON.parse(localStorage.getItem('user')!);
    this.getStudentCourseList(0, this.courseFilter , this.utente.id);
  }

  getStudentCourseList(page: number, filter: CourseFilter, studentID: number,) {
    this.studentService.getStudentCoursesList(page, filter, studentID,).subscribe({
      next: (courses: CoursePage) => {
        console.log(courses.content);
        console.log('Courses list');
        this.elencoCorsi = courses.content;
      },
    });
  }
  getPercentage (completedLesson: number, totalLesson: number): string{
    const perc = (completedLesson/totalLesson)*100;
    return perc+'%';
  }
  goToCourseDetail(course: Course) {
    //this.courses = JSON.parse(localStorage.getItem('courses')!);
    localStorage.removeItem('course');
    localStorage.setItem('course', JSON.stringify(course));
    this.router.navigate(['/dettaglio_corso']);
  }
}
