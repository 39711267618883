import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/modules/shared/model/user';

@Component({
  selector: 'wave-eduxible-statistics-page',
  templateUrl: './statistics-page.component.html',
  styleUrls: ['./statistics-page.component.scss']
})
export class StatisticsPageComponent implements OnInit {
  user!: User;

  
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')!);
  }
}
