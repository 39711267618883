import { NgModule, Component } from '@angular/core';
import { ResolveFn, RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { InformationPageComponent } from './home-page/home-components/pages/information-page/information-page.component';
import { ProgressPageComponent } from './home-page/home-components/pages/progress-page/progress-page.component';
import { LessonPageComponent } from './home-page/home-components/pages/lessons-page/lesson-page.component';
//import * as path from "path";
import { combineAll } from 'rxjs';
import { CoursesDetailTeacherPageComponent } from './home-page/home-components/pages/courses-detail-teacher-page/courses-detail-teacher-page.component';
import { StudentCoursesDetailPageComponent } from './home-page/home-components/pages/student-course-detail-page/student-courses-detail-page.component';
import { StatisticsPageComponent } from './home-page/home-components/pages/statistics-page/statistics-page.component';
import { StudentDetailPageComponent } from './home-page/home-components/pages/student-detail-page/student-detail-page.component';
import { TeacherDetailPageComponent } from './home-page/home-components/pages/teacher-detail-page/teacher-detail-page.component';
import { TeachersOverviewPageComponent } from './home-page/home-components/pages/teachers-overview-page/teachers-overview-page.component';
import { StudentsOverviewPageComponent } from './home-page/home-components/pages/students-overview-page/students-overview-page.component';
import { CoursesOverviewPageComponent } from './home-page/home-components/pages/courses-overview-page/courses-overview-page.component';
import { UsersPageComponent } from './home-page/home-components/pages/users-page/users-page.component';
import { DashboardPageComponent } from './home-page/home-components/pages/dashboard-page/dashboard-page.component';
import { CourseDetailPageComponent } from './home-page/home-components/pages/course-detail-page/course-detail-page.component';
import { ProfilePageComponent } from './home-page/home-components/pages/profile-page/profile-page.component';
import { LessonModifyPageComponent } from './home-page/home-components/pages/lesson-modify-page/lesson-modify-page.component';
import { TestModifyPageComponent } from './home-page/home-components/pages/test-modify-page/test-modify-page.component';
import { StudentTestComponent } from './home-page/home-components/pages/student-test/student-test.component';
import { StatStudComponent } from './home-page/home-components/pages/statistics-components/stat-stud/stat-stud.component';
import { StatProfComponent } from './home-page/home-components/pages/statistics-components/stat-prof/stat-prof.component';
import { StatCoursesComponent } from './home-page/home-components/pages/statistics-components/stat-courses/stat-courses.component';
import { StatAdvancedComponent } from './home-page/home-components/pages/statistics-components/stat-advanced/stat-advanced.component';
import { StudentTestDetailComponent } from './home-page/home-components/pages/student-test-detail/student-test-detail.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    children: [
      {
        path: 'informazioni', //informazioni
        component: InformationPageComponent,
      },
      {
        path: 'progressi', //progressi
        component: ProgressPageComponent,
      },
      {
        path: 'lezioni', //lezioni
        component: LessonPageComponent,
      },
      {
        path: 'teacher-courses-detail',
        component: CoursesDetailTeacherPageComponent,
      },
      {
        path: 'student-course-detail',
        component: StudentCoursesDetailPageComponent,
      },
      {
        path: 'statistiche', //statistiche
        component: StatisticsPageComponent,
      },
      {
        path: 'dettaglio_studenti', //dettaglio_studenti
        component: StudentDetailPageComponent,
      },
      {
        path: 'dettaglio_docenti', //dettaglio_professori non ancora usato
        component: TeacherDetailPageComponent,
      },
      {
        path: 'docenti', //professori
        component: TeachersOverviewPageComponent,
      },
      {
        path: 'studenti', //studenti
        component: StudentsOverviewPageComponent,
      },
      {
        path: 'test', //test
        component: StudentTestComponent,
      },
      {
        path: 'corsi', //corsi
        component: CoursesOverviewPageComponent,
      },
      {
        path: 'amministratori', //utenti
        component: UsersPageComponent,
      },
      {
        path: 'dashboard',
        component: DashboardPageComponent,
      },
      {
        path: 'dettaglio_corso', //dettaglio corso
        component: CourseDetailPageComponent,
      },
      {
        path: 'profilo', //profilo
        component: ProfilePageComponent,
      },
      {
        path: 'dettaglio_lezione', //dettaglio_lezione
        component: LessonModifyPageComponent,
      },
      {
        path: 'dettaglio_test', //dettaglio test
        component: TestModifyPageComponent,
      },
      {
        path: 'statistiche_studenti',
        component: StatStudComponent,
      },
      {
        path: 'statistiche_docenti',
        component: StatProfComponent,
      },
      {
        path: 'statistiche_corsi',
        component: StatCoursesComponent,
      },
      {
        path: 'statistiche_avanzate',
        component: StatAdvancedComponent,
      },
      {
        path: 'test_studente',
        component: StudentTestDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
