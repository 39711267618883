<div class="modal-lg modal-content modal-xs">
  <div class="modal-header">
    <h3 class="modal-title w-100 text-center" id="modal_title_4">{{ title }}</h3>
  </div>
  <div class="modal-body">
    <form [formGroup]="modalForm" (ngSubmit)="submitForm(modalForm.value)">
      <div class="col-md-12 mb-4">
        <label for="denominazione" class="form-label fw-bold">Denominazione<span
            class="required">*</span></label>
        <input type="text" class="form-control border-0"
          id="name" name="denominazione" formControlName="denominazione"
          required>
        <div class="text-danger x-small mt-1">
          <span *ngIf="this.confirmed &&
            this.modalForm.controls['denominazione'].errors?.['required']">Questo
            campo è obbligatorio</span>
        </div>
      </div>

      <div class="col-md-12 mb-4">
        <label id="collab" class="form-label fw-bold mt-3">Anno scolastico<span
            class="required">*</span></label>
        <ng-multiselect-dropdown
          [placeholder]="'Seleziona l\'anno scolastico'"
          [settings]="dropdownSettingsYear"
          [data]="dropdownListYear"
          [(ngModel)]="selectedItemsYear"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          formControlName="year" required>
        </ng-multiselect-dropdown>
        <div class="text-danger x-small mt-1">
          <span *ngIf="this.confirmed &&
            this.modalForm.controls['year'].errors?.['required']">Questo campo è
            obbligatorio</span>
        </div>
      </div>

      <div class="col-md-12 mb-4">
        <label class="form-label fw-bold mt-3">Docente responsabile<span
            class="required">*</span></label>
        <ng-multiselect-dropdown
          name="docente"
          [placeholder]="'Seleziona il responsabile del corso'"
          [settings]="dropdownSettingsResp"
          [data]="elencoProfessori"
          [(ngModel)]="selectedItemsResp"
          formControlName="docente"
          required>
        </ng-multiselect-dropdown>
        <div class="text-danger x-small mt-1">
          <span *ngIf="this.confirmed &&
            this.modalForm.controls['docente'].errors?.['required']">Questo
            campo è obbligatorio</span>
        </div>
      </div>

      <div class="col-md-12 mb-4">
        <label class="form-label fw-bold mt-3">Collaboratori</label>
        <ng-multiselect-dropdown
          name="collaborators"
          [placeholder]="'Seleziona i collaboratori del corso'"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          [(ngModel)]="selectedItems"
          formControlName="collaborators">
        </ng-multiselect-dropdown>
      </div>

      <div class="col-md-12 mb-4">
        <label for="descrizione" class="form-label fw-bold mt-3">Descrizione<span
            class="required">*</span></label>
        <input type="text" class="form-control border-0"
          id="description" name="descrizione" formControlName="descrizione"
          required>
        <div class="text-danger x-small mt-1">
          <span *ngIf="this.confirmed &&
            this.modalForm.controls['descrizione'].errors?.['required']">Questo
            campo è obbligatorio</span>
        </div>
      </div>

      <div class="col-md-12 mb-4">
        <label for="icon" class="form-label fw-bold mt-3">Icona</label>
        <div formControlName="icona">
          <a role="button" *ngFor="let image of images"
            [ngClass]="image.selected ? 'rounded-circle btn btn-icons me-2
            active mt-3': 'rounded-circle btn btn-icons me-2 mt-3'"
            (click)="selectIcon(image.id, image)">
            <img src={{image.path}} alt={{image.alt}} id={{image.id}}
              class="subject-icon">
          </a>
        </div>
      </div>      
    </form>
    <div class="modal-footer">
      <button type="submit" class="btn btn-outline-secondary d-flex align-items-center"
        (click)="modal.dismiss('Modifica non effettuata')"><svg class="icon me-2">
          <use xlink:href="assets/icons/annulla.svg#annulla" href="assets/icons/annulla.svg#annulla"></use>
        </svg>Annulla</button>
      <button type="submit" (click)="submitForm(modalForm.value)" class="btn btn-primary"><svg class="icon me-2">
          <use xlink:href="assets/icons/confirm.svg#confirm"
            href="assets/icons/confirm.svg#confirm"></use>
        </svg>{{confirmButton}}</button>
    </div>
  </div>
</div>
