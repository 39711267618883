import { Injectable } from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TokenStorageService} from "./token.service";
import { Observable } from 'rxjs';
import { Credentials } from 'src/app/modules/login/login-model/credentials';
import { User } from '../../model/user';
import { GenericHttpService } from '../generic-http.service';

const AUTH_API = 'https://auth.apeironlab.tech/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = '/api'

  constructor(private oidcSecurityService : OidcSecurityService,
              private router : Router,
              private http: HttpClient,
              private tokenStorage : TokenStorageService,
              private genericHttpService: GenericHttpService
              ) { }

  isAuth(){
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData}) => {
      if(!isAuthenticated) {
        this.login();
      } else {
        //this.router.navigate(['/home']);
        this.tokenStorage.saveToken(userData.accessToken);
        this.tokenStorage.saveRefreshToken(userData.refreshToken);
        this.tokenStorage.saveUser(userData);
        this.getUserInfoByToken().subscribe({
          next: (user) => {
            localStorage.setItem('user', JSON.stringify(user)); //can only store string in localstorage
            this.router.navigate(['home']);
          }, error: ()=>{
            //alert('Credenziali Errate');
          }
        });
      }
    },
      err => console.log(err)
      );
    this.getToken();
  }

  getUserData(): string{
    let userData = this.oidcSecurityService.getUserData();
    if(userData == null) return "user";
    else return userData.name;
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  getToken(){
    return this.oidcSecurityService.getAccessToken();
  }

  getUserInfo(email: string , password:string): Observable<User> {
    console.log('GET USER');
    const credentials: Credentials = {
      email: email,
      password: password,
      token: ''
    }
    return this.genericHttpService.httpPost<Credentials,User>(`${this.baseUrl}/auth/login`, credentials);
  }

  getUserInfoByToken(): Observable<User> {
    console.log('GET USER');
    const credentials: Credentials = {
      email: '',
      password: '',
      token: this.getToken()
    }
    return this.genericHttpService.httpPost<Credentials,User>(`${this.baseUrl}/auth/login`, credentials);
  }
}
