<h1 class="text-center">Impostazioni profilo</h1>
<div class=" d-flex justify-content-center">
  <form [formGroup]="modalForm" class="my-5 col-12 col-md-10 col-lg-6">
    <div class="mb-4">
      <label class="form-label fw-bold" for="FormControlInput">Nome</label>
      <input type="text" class="form-control" id="ProfileFormName"
        formControlName="nome" [disabled]="user.role === 'Student'">
    </div>
    <div class="mb-4">
      <label class="form-label fw-bold" for="FormControlInput">Cognome</label>
      <input type="text" class="form-control" id="ProfileFormName"
        formControlName="cognome">
    </div>
    <div class="mb-4">
      <label class="form-label fw-bold" for="FormControlInput">Email</label>
      <input type="text" class="form-control" id="ProfileFormName"
        formControlName="email">
    </div>
    <ng-template [ngIf]="user.role !== 'Student'">
      <div class="d-flex justify-content-end">
        <button type="button" class="btn me-3 btn-link
          align-items-center"><svg class="icon me-2">
            <use xlink:href="assets/icons/annulla.svg#annulla"
              href="assets/icons/annulla.svg#annulla"></use>
          </svg>Torna
          indietro</button>
        <button type="submit" class="btn btn-primary"><svg class="icon me-2">
            <use xlink:href="assets/icons/confirm.svg#confirm"
              href="assets/icons/confirm.svg#confirm"></use>
          </svg>Salva le
          modifiche</button>
      </div>
    </ng-template>
  </form>
</div>
<div class="d-flex justify-content-start">
  <button type="button" class="btn btn-link
    align-items-center"><svg class="icon me-2">
      <use xlink:href="assets/icons/password.svg#password"
        href="assets/icons/password.svg#password"></use>
    </svg>Cambia la password
  </button>

  <ng-template [ngIf]="user.role === 'Student'">
  </ng-template>

  <ng-template [ngIf]="user.role === 'Admin'"> </ng-template>

  <ng-template [ngIf]="user.role === 'Professor'"> </ng-template>
