import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginRoutingModule } from './login-routing.module';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { NgEventBus } from 'ng-event-bus';
import { LoginFormComponent } from './components/login-page/login-form/login-form.component';
import {HomePageComponent} from "../home/home-page/home-page.component";
import {HomeModule} from "../home/home.module";


@NgModule({
  declarations: [
    LoginPageComponent,
    LoginFormComponent,
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [NgEventBus],
})
export class LoginModule { }
