import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Student, StudentFilter, StudentPage } from '../../models/data-model';
import { environment } from '../../../../../../../environments/environment';
import { StudentService } from '../../../services/student.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../../../shared/model/user';
import { StudentModalComponent } from 'src/app/modules/shared/components/modals/student-modal/student-modal.component';
import { WDialogService } from 'src/app/modules/shared/components/w-dialog/services/w-dialog.service';
import { DlgType } from 'src/app/modules/shared/components/w-notification/models/notification-models';
import { Router } from '@angular/router';

@Component({
  selector: 'wave-eduxible-students-overview-page',
  templateUrl: './students-overview-page.component.html',
  styleUrls: ['./students-overview-page.component.scss'],
})
export class StudentsOverviewPageComponent implements OnInit {
  @Output() filterStud!: StudentFilter;
  @Output() raiseFilterStud = new EventEmitter<StudentFilter>();

  elencoStudenti: Student[] = [];
  elencoStudentiFiltrato: StudentPage[] = [];
  students: any;
  totalElements!: number;
  user!: User;
  page = 1;
  pageSize: number = environment.size;

  /*  // @Input() tableData!: Meters;
  @Output() onChangePageStudents = new EventEmitter<number>();
  @Input() page = 1;
  sizeCurrent: number = environment.size; */

  constructor(
    private studentService: StudentService,
    private dialogService: WDialogService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.filterStud = {};
  }

  changePage($event: number) {
    console.log('Page:', $event);
    this.page = $event;
    this.getStudentList(this.page - 1, this.filterStud);
  }

  raiseFormSubmittedStud($event: StudentFilter) {
    console.log('raise filter STUD', $event);
    this.filterStud = $event;
    this.getStudentList(0, this.filterStud);
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user')!);
    this.filterStud.active = true;
    this.getStudentList(0, this.filterStud);
  }

  getStudentList(page: number, filter: StudentFilter) {
    this.studentService.getStudentsList(page, filter).subscribe({
      next: (students: StudentPage) => {
        this.elencoStudenti = students.content;
        this.totalElements = students.totalElements;
        console.log('Studenti', this.elencoStudenti);
      },
    });
  }

  openModalStudent() {
    const modalRef = this.modalService.open(StudentModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.action = 'add';
    modalRef.componentInstance.title = 'Aggiungi studente';
    modalRef.componentInstance.confirmButton = 'Conferma e aggiungi';
    modalRef.result
      .then((studentForm) => {
        window.location.reload();
        console.log('studentForm: ', studentForm);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }
  /*   assignCopy(){
    this.elencoStudentiFiltrato = Object.assign([], this.elencoStudenti);
  } */

  editStudent(student: Student) {
    console.log(student);
    const modalRef = this.modalService.open(StudentModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.student = student;
    modalRef.componentInstance.action = 'edit';
    modalRef.componentInstance.title = 'Modifica studente';
    modalRef.componentInstance.confirmButton = 'Modifica';
    modalRef.result
      .then((editForm) => {
        window.location.reload();
        console.log('editForm: ', editForm);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  deleteStudent(student: Student) {
    console.log(student);
    // Aprire il dialog
    this.dialogService.open({
      titolo: `Vuoi eliminare ${student.name} ${student.surname}?`,
      userType: 'student',
      dlgType: 'delete',
      tipo: DlgType.ERROR,
      // messaggio: '',
      cancelVisible: true,
      okHandler: () => {
        this.studentService.deleteStudent(student).subscribe();
        window.location.reload();
      },
    });
  }

  restoreStudent(student: Student) {
    this.dialogService.open({
      titolo: `Vuoi ripristinare ${student.name} ${student.surname}?`,
      userType: '',
      dlgType: 'restore',
      tipo: DlgType.RESTORE,
      // messaggio: '',
      cancelVisible: true,
      okHandler: () => {
        this.studentService.restoreStudent(student).subscribe({
          next: (res) => {
            window.location.reload();
          },
        });
      },
    });
  }

  goToStudentDetail(student: Student) {
    localStorage.removeItem('student');
    localStorage.setItem('student', JSON.stringify(student));
    this.router.navigate(['/dettaglio_studenti']);
  }
}
