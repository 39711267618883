import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MetaData, NgEventBus } from 'ng-event-bus';
import { Subscription } from 'rxjs';
import { Credentials } from '../../login-model/credentials';
import { AuthService } from 'src/app/modules/shared/services/auth/auth.service';
import { environmentOauth } from 'src/environments/environment.oauth';
import { WNotificationService } from 'src/app/modules/shared/components/w-notification/services/w-notification.service';
import { DlgType } from 'src/app/modules/shared/components/w-notification/models/notification-models';

@Component({
  selector: 'wave-eduxible-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  /*  login = "";*/
  user!: Credentials;
  subscriptions: Subscription = new Subscription();
  formVisible: boolean = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private alertService: WNotificationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    console.log(this.user, 'user oninit');  
    if(environmentOauth.authType == 'OAUTH'){
      this.formVisible = false;
      this.authService.isAuth();      
    } 
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async handleLogin(user: Credentials) {
    if(environmentOauth.authType != 'OAUTH'){
      console.log('LOGIN');
      localStorage.removeItem('user');
      console.log(user, 'handle');

      await this.authService.getUserInfo(user.email, user.password).subscribe({
        next: (user) => {
          localStorage.setItem('user', JSON.stringify(user)); //can only store string in localstorage
          this.router.navigate(['home']);
        }, error: ()=>{
          this.alertService.open({
            titolo: 'Errore',
            tipo: DlgType.ERROR,
            messaggio: `Credenziali errate.`,
            cancelLabel: '',
            cancelVisible: true,
            okLabel: '',
            codErrore: 0,
          });
        }
      });
    }
  }
}
