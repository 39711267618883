import {
  CourseFilter,
  Course,
  CoursePage,
  UpdatableCourse,
  CourseCombo,
  NewCourse,
  StudentPage,
  Student,
  LessonPage,
  LessonStatPage,
  AttemptStatPage,
  CourseStat,
} from './../home-components/models/data-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GenericHttpService } from 'src/app/modules/shared/services/generic-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  baseURL = '/api';

  constructor(
    private httpClient: HttpClient,
    private genericHttpService: GenericHttpService
  ) {}

  getCoursesList(page: number, filter: CourseFilter): Observable<CoursePage> {
    console.log('GET COURSES');
    return this.genericHttpService.httpGet<CoursePage>(
      this.baseURL +
        '/courses?page=' +
        page +
        '&size=' +
        environment.sizeCourses +
        (filter != null ? '&q=' + btoa(JSON.stringify(filter)) : '')
    );
  }

  getCoursesListForCombo(): Observable<CourseCombo> {
    console.log('GET COURSE COMBO');
    return this.genericHttpService.httpGet<CourseCombo>(
      this.baseURL + '/courses/combolist'
    );
  }

  getCourseStudent(
    idCourse: number,
    page: number,
    filter: CourseFilter
  ): Observable<StudentPage> {
    return this.genericHttpService.httpGet<StudentPage>(
      this.baseURL +
        '/courses/' +
        idCourse +
        '/students?page=' +
        page +
        '&size=' +
        environment.size +
        (filter != null ? '&q=' + btoa(JSON.stringify(filter)) : '')
    );
  }

  removeStudentFromCourse(idCourse: number, idStudent: number) {
    return this.genericHttpService.httpDelete<Student>(
      this.baseURL + '/courses/' + idCourse + '/students/' + idStudent
    );
  }

  editCourse(id: number, course: UpdatableCourse) {
    console.log('EDIT COURSE', course);
    return this.genericHttpService
      .httpPatch(`${this.baseURL}/courses/${id}`, course, {
        headers: { 'Content-Type': 'application/json' },
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          console.log(response);
          return response.status;
        })
      );
  }

  deleteCourse(course: Course) {
    console.log('DELETE COURSE');
    return this.genericHttpService.httpDelete<Course>(
      `${this.baseURL}/courses/${course.id}`
    );
  }

  addCourse(course: NewCourse): Observable<number> {
    console.log('ADD COURSE', course);
    return this.genericHttpService
      .httpPost<NewCourse>(`${this.baseURL}/courses`, course, {
        headers: { 'Content-Type': 'application/json' },
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          // alert(response.status);
          return response.status;
        })
      );
  }

  restoreCourse(courseID: number) {
    return this.genericHttpService.httpPost<Course>(
      `${this.baseURL}/courses/${courseID}`
    );
  }

  getLessonsStats(courseID: number, page: number): Observable<LessonStatPage>{
    return this.genericHttpService.httpGet<LessonStatPage>(`${this.baseURL}/courses/${courseID}/lessons/stats?page=${page}&size=10`);
  }
  getAttemptsStats(courseID: number, page: number): Observable<AttemptStatPage>{
    return this.genericHttpService.httpGet<AttemptStatPage>(`${this.baseURL}/courses/${courseID}/lessons/tests/stats?page=${page}&size=10`);
  }
  getAllCourseStats() {
    console.log('GET ALL COURSES');
    return this.genericHttpService.httpGet<CourseStat[]>(
      `${this.baseURL}/stats/courses`
    );
  }
}
