<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/statistiche">Statistiche</a></li>
    <li class="breadcrumb-item active" aria-current="page">Statistiche studenti</li>
  </ol>
</nav>
<h1>Statistiche studenti</h1>

<div class="my-4 my-md-5">
  <div
    class="bg-light p-3 d-flex gap-4 flex-column flex-md-row justify-content-between align-items-end align-items-md-start">
    <form [formGroup]="form" class="w-100">
      <div class="row align-items-start gy-3">
        <div class="col-12 col-md-4 col-lg-2">
          <p class="form-label mt-md-1 mt-3 visually-hidden">Stato utente</p>
          <div>
            <select formControlName="active" class="form-select" (change)="runFilter($event)">
              <option value="true">Attivo</option>
              <option value="false">Non attivo</option>
              <option value="">Tutti</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex">
            <label id="collab" class="form-label visually-hidden">Studenti</label>
            <ng-multiselect-dropdown class="col"
              [placeholder]="'Seleziona uno o più studenti'"
              [settings]="dropdownSettingsStudents"
              [data]="dropdownListStudents"
              [(ngModel)]="selectedItemsStudents"
              (onSelect)="runFilter($event)"
              (onSelectAll)="runFilter($event)"
              (onDeSelect)="runFilter($event)"
              (onDeSelectAll)="runFilter($event)"
              formControlName="students">
            </ng-multiselect-dropdown>
            <a
              role="button"
              tooltip="Annulla"
              placement="top"
              *ngIf="selectedItemsStudents.length > 0"
              class="btn btn-sm btn-icon"
              (click)="resetFilter()">
              <svg class="icon icon-red">
                <use xlink:href="assets/icons/close.svg#close" href="assets/icons/close.svg#close">
                </use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </form>

    <div class="ps-4 flex-shrink-0">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button tooltip="Grafico" placement="top" class="nav-link p-2 active" id="home-tab" data-bs-toggle="tab" data-bs-target="#stud-tab-pane"
            type="button" role="tab" aria-controls="stud-tab-pane" aria-selected="true">
            <svg class="icon me-1 mb-1">
              <use xlink:href="assets/icons/grafico.svg#grafico" href="assets/icons/grafico.svg#grafico"></use>
            </svg>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button tooltip="Tabella" placement="top" class="nav-link p-2" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane-stud"
            type="button" role="tab" aria-controls="profile-tab-pane-stud" aria-selected="false">
            <svg class="icon me-1 mb-1">
              <use xlink:href="assets/icons/tabella.svg#tabella" href="assets/icons/tabella.svg#tabella"></use>
            </svg>
          </button>
        </li>
      </ul>
    </div>
  </div>

  <div class="mt-4">
    <div class="tab-content" id="StudTabContent">
      <div class="tab-pane fade show active" id="stud-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
        <div class="chart-container">
          <div class="col-12 col-md-4 mb-4">
            <select #option class="form-select" aria-label="Tipologia di statistiche" (change)="onSelect(option.value)">
              <option default selected value="totCorsi">Totale corsi seguiti</option>
              <option value="avanz">Avanzamento</option>
              <option value="puntMed">Punteggio medio</option>
            </select>
          </div>

          <canvas id="StudChart" class="h-auto">{{ chart }}</canvas>
        </div>
      </div>

      <div class="tab-pane fade" id="profile-tab-pane-stud" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
        <div class="table-responsive overflow-x-scroll">
          <table class="table table-hover">
            <thead class="sticky-top">
              <tr class="align-middle border-bottom border-2">
                <th>Nome</th>
                <th>Totale corsi seguiti</th>
                <th>Avanzameto</th>
                <th>Punteggio Medio</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sStat of studentStatsFiltered">
                <td class="h-100">
                  {{ sStat.surname }} {{ sStat.name }}
                </td>
                <td class="h-100">
                  {{ sStat.totalCourses }}
                </td>
                <td class="h-100">
                  {{ sStat.avgProgressCourses }}
                </td>
                <td class="h-100">
                  {{ sStat.avgScoresLessons }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-5">
  <a role="button" class="text-primary mt-4 btn btn-link" (click)="back()">
    <svg class="icon me-2">
      <use xlink:href="assets/icons/annulla.svg#annulla" href="assets/icons/annulla.svg#annulla"></use>
    </svg>
    <span>Torna indietro</span>
  </a>
</div>
