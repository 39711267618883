import { Component, OnInit, ElementRef, Inject } from '@angular/core';
/*
import '../../../../../../../../../node_modules/pivottable/dist/pivot.coffee';
import '../../../../../../../../../node_modules/pivottable/dist/d3_renderers.coffee';
import '../../../../../../../../../node_modules/pivottable/dist/export_renderers.coffee';
import '../../../../../../../../../node_modules/pivottable/dist/plotly_renderers.coffee';*/
import { StatService } from '../../../../services/stat.service';
import { StatAdvanced } from '../../../models/data-model.js';
import { Location } from '@angular/common';
declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'wave-eduxible-stat-advanced',
  templateUrl: './stat-advanced.component.html',
  styleUrls: ['./stat-advanced.component.scss']
})
export class StatAdvancedComponent implements OnInit {
  private el: ElementRef;
  myData!: StatAdvanced[];

  constructor(@Inject(ElementRef)el: ElementRef, private statService: StatService, public location: Location) {
     this.el = el;
   }

  ngOnInit() {

  }

  ngAfterViewInit(){

  if (!this.el ||
      !this.el.nativeElement ||
      !this.el.nativeElement.children){
          console.log('cant build without element');
          return;
   }

    var container = this.el.nativeElement;
    var inst = jQuery(container);
    var targetElement = inst.find('#output');

    if (!targetElement){
      console.log('cant find the pivot element');
      return;
    }

   while (targetElement.firstChild){
      targetElement.removeChild(targetElement.firstChild);
    }

    var derivers = $.pivotUtilities.derivers;

    var renderers = $.extend(
        $.pivotUtilities.renderers,
        $.pivotUtilities.plotly_renderers,
        $.pivotUtilities.d3_renderers,
        $.pivotUtilities.export_renderers
        );

    this.statService.getAdvancedStat().subscribe((response: any) =>{
      this.myData = response.items;
      targetElement.pivotUI(this.myData, {
        renderers: renderers,
        cols: ["studente"], rows: ["nome_del_corso"],
        rendererName: "Table"
      });
    });

   }

  back() {
    this.location.back();
  }
}
