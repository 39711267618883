export interface StudentPage {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: Student[];
}

export class Student {
  id!: number;
  name!: string;
  surname!: string;
  email!: string;
  active!: boolean;
  pwd!: string;
  courses!: StudentCourse[];
  fullname: string = this.surname + ' ' + this.name;

  Student() {
    courses: [];
  }
}
export interface StudentCourse {
  courseId: number;
  name: string;
  completedLessons: number;
  totalLessons: number;
  avgCorrectAnswers: number;
  icon: string;
}

export class NewStudent {
  id!: number;
  name!: string;
  surname!: string;
  email!: string;
  active!: boolean;
  pwd!: string;
  courses!: number[];

  Student() {
    courses: [];
  }
}

export interface ProfessorPage {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: Professor[];
}
export class Professor {
  id!: number;
  name!: string;
  surname!: string;
  fullname: string = this.surname + ' ' + this.name;
  email!: string;
  active!: boolean;
  admin!: boolean;
  title!: string;
  managerOf!: CourseCombo[];
  collaboratorOf!: CourseCombo[];
  pwd!: string;
  //static ManagerOf: any;

  Professor() {
    this.managerOf = [];
    this.collaboratorOf = [];
  }
}
export interface ProfessorCombo {
  id: number;
  name: string;
  surname: string;
  active: boolean;
}
export interface CourseCombo {
  id: number;
  name: string;
  active: boolean;
}

export interface ProfessorFilter {
  name?: string;
  active?: boolean;
  surname?: string;
  email?: string;
  admin?: boolean;
  //respCourses: string | null;
  // collabCourses: string | null;
  // page?: number;
}
export interface CourseFilter {
  name?: string;
  active?: boolean;
  managers?: number[];
  years?: string[];
}
export interface StudentFilter {
  name?: string;
  active?: boolean;
  surname?: string;
  email?: string;
  class?: string;
  //respCourses: string | null;
  // collabCourses: string | null;
  // page?: number;
}
export interface LessonFilter {
  name?: string;
  studentCourses?: string;
  score?: string;
}
export interface StudentFilterStats {
  name?: string;
  active?: boolean;
  surname?: string;
  email?: string;
  class?: string;
  students?: number[];
}

export interface ProfessorFilterStats {
  name?: string;
  active?: boolean;
  surname?: string;
  managerOf?: number[];
  collaboratorOf?: number[];
  admin?: boolean;
  title?: string;
}
export interface CourseFilterStats {
  name?: string;
  active?: boolean;
  managers?: number[];
  years?: string[];
  courses?: number[];
}

export interface CourseStat {
  id: number;
  name: string;
  manager: string;
  totalCollaborators: number;
  totalStudents: number;
  totalLessons: number;
  avgProgress: number;
  avgScore: number;
  active: boolean;
}
export class AdminWrapper {
  admin: boolean | undefined;
  professors: Professor[] | undefined;

  AdminWrapper() {
    this.professors = [];
  }
}

export interface CoursePage {
  totalLessons: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: Course[];
}
export interface LessonStatPage {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: LessonStat[];
}
export interface AttemptStatPage {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: Attempt[];
}
export interface LessonStat {
  id: number;
  title: string;
  completed: true;
  numOfAttempts: number;
  progressiveNumber: number;
  score: number;
  testDate: string;
}
export interface Attempt {
  id: number;
  testDate: string;
  score: number;
  passed: boolean;
  title: string;
  lessonId: number;
  studentName: string;
}
export class Course {
  id!: number;
  //courseId!: number;
  code!: string;
  name!: string;
  manager!: Professor;
  description!: string;
  active!: boolean;
  collaborators!: Professor[];
  icon!: string;
  year!: string;
  completedLessons!: number;
  totalLessons!: number;
  avgCorrectAnswers!: number;
  totalElements: any;
}

export class NewCourse {
  id!: number;
  code!: string;
  name!: string;
  manager!: number[]; //era number[]
  description!: string;
  active!: boolean;
  collaborators!: number[];
  icon!: string;
  year!: string;
  // completedLessons: number;
  // totalLessons: number;
  // avgCorrectAnswers: number;
}

export interface Manager {
  id: number;
  name: string;
  surname: string;
  active: boolean;
}
export interface Collaborator {
  id: number;
  name: string;
  surname: string;
  active: boolean;
}
export interface UpdatableProfessor {
  email: string;
  name: string;
  surname: string;
  title: string;
  admin: boolean;
  managerCourses: number[];
  collaboratorCourses: number[];
  pwd: string;
}

export interface UpdatableStudent {
  class: string;
  email: string;
  name: string;
  surname: string;
  courses: number[];
}

export interface UpdatableCourse {
  name: string;
  description: string;
  year: string;
  icon: string;
  manager: number[];
  collaborators: number[];
}

export interface UpdatableLesson {
  progressiveNumber: number;
  professorId: number;
  title: string;
  content: string;
  threshold: number;
  secondsPerQuestion: number;
  secondsTest: number;
}
export interface Lesson {
  id: number;
  progressiveNumber: number;
  deleted: boolean;
  professor: Professor;
  professorIsManager: boolean;
  title: string;
}

export interface LessonDetail {
  id: number;
  progressiveNumber: number;
  deleted: boolean;
  professor: Collaborator;
  professorIsManager: boolean;
  title: string;
  nquestions: number;
  secondsPerQuestion: number;
  secondsTest: number;
  content: string;
  threshold: number;
  updatedAt: string;
  updatedBy: string;
}
export interface NewLesson {
  progressiveLessonNumber: number;
  title: string;
  content: string;
}
export interface LessonPage {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: LessonDetail[];
}
export interface QuestionPage {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: Question[];
}

export interface Question {
  id: number;
  text: string;
  answers: Answer[];
  deleted: boolean;
  // lezione: Lesson;
}

export interface Answer {
  id: number;
  text: string;
  correct: boolean;
  checkedByStudent: boolean;
  deleted: boolean;
  // domanda: Question[];
}

export interface TestAnswers {
  answers: number[];
}

export interface CompiledTest {
  id: number;
  date: string;
  studentName: string;
  lessonTitle: string;
  correctAnswers: number;
  totalQuestions: number;
  percentage: number;
  passed: boolean;
  questions: Question[];
}

export interface ProfStatistics {
  managerCourses: number;
  collaboratorCourses: number;
  students: number;
  lessons: number;
  tests: number;
  passedTests: number;
  passedTestsPerc: number;
}
export interface AdminStatistics {
  courses: number;
  professors: number;
  students: number;
  lessons: number;
  tests: number;
  passedTests: number;
  passedTestPerc: number;
}

export interface StudentTestStatsPage {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: StudentStatsTest[];
}

export interface StudentStatsTest {
  progressiveNumber: number;
  title: string;
  lessonId: number;
  courseId: number;
  courseName: string;
  date: string;
  score: number;
  numOfAttempts: number;
  testId: number;
}

export interface ProfessorStat {
  id: number;
  name: string;
  surname: string;
  totalCollaborations: number;
  totalManagers: number;
  active: boolean;
  totalLessons: number;
  passedTests: number;
  passedTestsPerc: number;
}
export interface StatAdvanced {
  courseId: number;
  courseName: string;
  year: string;
  code: string;
  managerId: number;
  manager: string;
  lessonId: number;
  lessonTitle: string;
  passed: boolean;
  numOfAttempts: number;
  score: number;
  courseActive: boolean;
  lessonDeleted: boolean;
  lessonProfessorId: number;
  lessonProfessorName: string;
  studentId: number;
  student: string;
  studentEmail: string;
}

export interface StudentStat {
  id: number;
  surname: string;
  name: string;
  active: boolean;
  totalCourses: number;
  avgProgressCourses: number;
  avgScoresLessons: number;
}

export interface TrendStat {
  numOfTests: number,
  numOfTestsPassed: number,
  label: string
}
