<ng-template [ngIf]="user.role !== 'Student'">
  <nav id="navbar_admin" class="nav navbar navbar-light">
    <ul class="navbar-nav w-100">
      <li class="nav-item pt-3">
        <a *ngIf="paths.dashboard.includes(this.router.url); else
          dashboardNotActive"
          role="link"
          class="nav-link w-100 active"
          routerLink="dashboard"
          id="dashboard"
          onclick="closeCollapse('collapseMenu')">
          <svg class="icon me-2">
            <use
              xlink:href="assets/icons/dashboard.svg#dashboard"
              href="assets/icons/dashboard.svg#dashboard"></use></svg>
          Dashboard
        </a>
        <ng-template #dashboardNotActive>
          <a
            role="link"
            class="nav-link w-100"
            routerLink="dashboard"
            id="dashboard"
            onclick="closeCollapse('collapseMenu')">
            <svg class="icon me-2">
              <use
                xlink:href="assets/icons/dashboard.svg#dashboard"
                href="assets/icons/dashboard.svg#dashboard"></use></svg>
            Dashboard
          </a>
        </ng-template>
      </li>
      <li class="nav-item">
        <a *ngIf="paths.courses.includes(this.router.url); else
          coursesNotActive"
          role="link"
          class="nav-link w-100 active"
          routerLink="corsi"
          id="corsi_"
          onclick="closeCollapse('collapseMenu')">
          <svg class="icon me-2">
            <use
              xlink:href="assets/icons/course.svg#course"
              href="assets/icons/course.svg#course"></use>
          </svg>
          Corsi e lezioni
        </a>
        <ng-template #coursesNotActive>
          <a
            role="link"
            class="nav-link w-100"
            routerLink="corsi"
            id="corsi_"
            onclick="closeCollapse('collapseMenu')">
            <svg class="icon me-2">
              <use
                xlink:href="assets/icons/course.svg#course"
                href="assets/icons/course.svg#course"></use>
            </svg>
            Corsi e lezioni
          </a>
        </ng-template>
      </li>
      <li class="nav-item">
        <a *ngIf="paths.students.includes(this.router.url); else
          studentsNotActive"
          role="link"
          class="nav-link w-100 active"
          routerLink="studenti"
          id="studenti_overview"
          onclick="closeCollapse('collapseMenu')">
          <svg class="icon me-2">
            <use
              xlink:href="assets/icons/student.svg#student"
              href="assets/icons/student.svg#student"></use>
          </svg>
          Studenti
        </a>
        <ng-template #studentsNotActive>
          <a
            role="link"
            class="nav-link w-100"
            routerLink="studenti"
            id="studenti_overview"
            onclick="closeCollapse('collapseMenu')">
            <svg class="icon me-2">
              <use
                xlink:href="assets/icons/student.svg#student"
                href="assets/icons/student.svg#student"></use>
            </svg>
            Studenti
          </a>
        </ng-template>
      </li>
      <li class="nav-item" *ngIf="user.role === 'Admin'">
        <a *ngIf="paths.teachers.includes(this.router.url); else
          teachersNotActive"
          role="link"
          class="nav-link w-100 active"
          routerLink="docenti"
          id="docenti_overview"
          onclick="closeCollapse('collapseMenu')">
          <svg class="icon me-2">
            <use
              xlink:href="assets/icons/teacher.svg#teacher"
              href="assets/icons/teacher.svg#teacher"></use>
          </svg>
          Docenti
        </a>
        <ng-template #teachersNotActive>
          <a
            role="link"
            class="nav-link w-100"
            routerLink="docenti"
            id="docenti_overview"
            onclick="closeCollapse('collapseMenu')">
            <svg class="icon me-2">
              <use
                xlink:href="assets/icons/teacher.svg#teacher"
                href="assets/icons/teacher.svg#teacher"></use>
            </svg>
            Docenti
          </a>
        </ng-template>
      </li>
      <li class="nav-item">
        <a *ngIf="paths.stats.includes(this.router.url); else statsNotActive"
          role="link"
          class="nav-link w-100 active"
          routerLink="statistiche"
          id="statistics"
          onclick="closeCollapse('collapseMenu')">
          <svg class="icon me-2">
            <use
              xlink:href="assets/icons/statistics.svg#statistics"
              href="assets/icons/statistics.svg#statistics"></use></svg>Statistiche
        </a>
        <ng-template #statsNotActive>
          <a
            role="link"
            class="nav-link w-100"
            routerLink="statistiche"
            id="statistics"
            onclick="closeCollapse('collapseMenu')">
            <svg class="icon me-2">
              <use
                xlink:href="assets/icons/statistics.svg#statistics"
                href="assets/icons/statistics.svg#statistics"></use></svg>Statistiche
          </a>
        </ng-template>
      </li>
      <li class="nav-item" *ngIf="user.role === 'Admin'">
        <a *ngIf="paths.users.includes(this.router.url); else usersNotActive"
          role="link"
          class="nav-link w-100 active"
          routerLink="amministratori"
          id="users"
          onclick="closeCollapse('collapseMenu')">
          <svg class="icon me-2">
            <use
              xlink:href="assets/icons/users.svg#users"
              href="assets/icons/users.svg#users"></use></svg>Amministratori
        </a>
        <ng-template #usersNotActive>
          <a
            role="link"
            class="nav-link w-100"
            routerLink="amministratori"
            id="users"
            onclick="closeCollapse('collapseMenu')">
            <svg class="icon me-2">
              <use
                xlink:href="assets/icons/users.svg#users"
                href="assets/icons/users.svg#users"></use></svg>Amministratori
          </a>
        </ng-template>
      </li>
    </ul>
  </nav>
</ng-template>
