import { WNotificationService } from './../../w-notification/services/w-notification.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { User } from '../../../model/user';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProfessorService } from '../../../../home/home-page/services/professor.service';
import {
  Course,
  CourseCombo,
  CourseFilter,
  Professor,
  UpdatableProfessor,
} from '../../../../home/home-page/home-components/models/data-model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipOption, MatChipInputEvent } from '@angular/material/chips';
import { map, Observable, startWith } from 'rxjs';
import { CoursesService } from 'src/app/modules/home/home-page/services/courses.service';
import { DlgType } from '../../w-notification/models/notification-models';

@Component({
  selector: 'wave-eduxible-professor-modal',
  templateUrl: './professor-modal.component.html',
  styleUrls: ['./professor-modal.component.scss'],
})
export class ProfessorModalComponent implements OnInit {
  @Input() title!: string;
  @Input() action!: string;
  @Input() user!: User;
  @Input() prof!: Professor;
  @Input() confirmButton!: string;

  modalForm!: FormGroup;
  professorAlreadyExist = false;
  errorMessage: string = '';
  foundError: boolean = false;
  confirmed: boolean = false;

  filter!: CourseFilter;
  dropdownList: CourseCombo[] = [];
  selectedItemsResp: CourseCombo[] = [];
  selectedItemsCollab: CourseCombo[] = [];
  dropdownSettings = {};
  pwd: string = 'password';
  constructor(
    public modal: NgbActiveModal,
    private _fb: FormBuilder,
    public professorService: ProfessorService,
    private alertService: WNotificationService,
    private courseService: CoursesService
  ) {}

  ngOnInit() {
    this.courseService.getCoursesListForCombo().subscribe((response: any) => {
      console.log('RESP', response);
      this.dropdownList = response.courses;
      console.log('dropdown-list', this.dropdownList);

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        searchPlaceholderText: 'Cerca...',
        noFilteredDataAvailablePlaceholderText: 'Nessun dato trovato',
        selectAllText: 'Seleziona tutti',
        unSelectAllText: 'Deseleziona tutti',
        itemsShowLimit: 30,
        allowSearchFilter: true,
      };

      this.selectedItemsResp = this.prof ? this.prof.managerOf : [];
      this.selectedItemsCollab = this.prof ? this.prof.collaboratorOf : [];
    });
    this.modalForm = this._fb.group({
      nome: [this.prof ? this.prof.name : '', Validators.required],
      cognome: [this.prof ? this.prof.surname : '', Validators.required],
      email: [
        this.prof ? this.prof.email : '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      respCorsi: [this.selectedItemsResp],
      collabCorsi: [this.selectedItemsCollab],
    });

    // this.onItemSelectResp(this.prof.managerOf);
    // this.onItemSelectCollab(this.prof.collaboratorOf);
  }

  onItemSelectResp(item: any) {
    console.log(item);
  }

  onSelectAllResp(items: any) {
    console.log(items);
  }

  onItemSelectCollab(item: any) {
    console.log(item);
  }

  onSelectAllCollab(items: any) {
    console.log(items);
  }

  submitForm(formValue: any) {
    this.confirmed = true;
    console.log(formValue);
    console.log(JSON.stringify(formValue));

    let managerOf: number[] = [];
    let collaboratorOf: number[] = [];
    let collaboratorCourses: CourseCombo[] = formValue['collabCorsi'];
    let managerCourses: CourseCombo[] = formValue['respCorsi'];
    for (let i = 0; i < managerCourses.length; i++) {
      managerOf.push(managerCourses[i].id);
    }
    for (let i = 0; i < collaboratorCourses.length; i++) {
      collaboratorOf.push(collaboratorCourses[i].id);
    }

    switch (this.action) {
      case 'add':
        let prof: UpdatableProfessor = {
          name: formValue.nome,
          surname: formValue.cognome,
          email: formValue.email,
          collaboratorCourses: collaboratorOf,
          managerCourses: managerOf,
          admin: false,
          title: '',
          pwd: this.pwd,
        };
        this.professorService.addProfessorOrAdmin(prof, false).subscribe({
          next: (val) => {
            this.alertService.open({
              titolo: 'Successo',
              tipo: DlgType.SUCCESS,
              messaggio: `Docente inserito correttamente.`,
              cancelLabel: '',
              cancelVisible: true,
              okLabel: '',
              codErrore: 0,
            });
            this.modal.close(formValue);
          },
          error: (error) => {            
            /*this.alertService.open({
              titolo: 'Errore',
              tipo: DlgType.ERROR,
              messaggio: `Errore nell'inserimento del docente.`,
              cancelLabel: '',
              cancelVisible: true,
              okLabel: '',
              codErrore: 0,
            });*/
          },
        });
        break;
      case 'edit':
        let updatableProfessor: UpdatableProfessor = {
          email: formValue['email'],
          name: formValue['nome'],
          surname: formValue['cognome'],
          title: this.prof.title,
          admin: this.prof.admin,
          managerCourses: managerOf,
          collaboratorCourses: collaboratorOf,
          pwd: this.pwd,
        };
        console.log(updatableProfessor);
        let ret;
        this.professorService
          .editProfessor(this.prof.id, updatableProfessor)
          .subscribe({
            next: (val) => {
              ret = val;
              console.log('STATUS: ', ret);
              this.alertService.open({
                titolo: 'Successo',
                tipo: DlgType.SUCCESS,
                messaggio: `Docente modificato correttamente.`,
                cancelLabel: '',
                cancelVisible: true,
                okLabel: '',
                codErrore: 0,
              });
              this.modal.close(formValue);
            },
            error: (error: any) => {
              console.log('ERROR MESS', error);
              /*this.alertService.open({
                titolo: 'Errore',
                tipo: DlgType.ERROR,
                messaggio: `Errore nella modifica del docente.`,
                cancelLabel: '',
                cancelVisible: true,
                okLabel: '',
                codErrore: 0,
              });*/
            },
          });
        break;
    }
  }
}
