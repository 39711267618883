import { NewStudent, UpdatableStudent } from './../../../../home/home-page/home-components/models/data-model';
import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../model/user';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { StudentService } from 'src/app/modules/home/home-page/services/student.service';
import {
  Course,
  Student,
} from 'src/app/modules/home/home-page/home-components/models/data-model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CoursesService } from 'src/app/modules/home/home-page/services/courses.service';
import { CourseFilter } from 'src/app/modules/home/home-page/home-components/models/data-model';
import { CourseCombo } from '../../../../home/home-page/home-components/models/data-model';
import { WNotificationService } from '../../w-notification/services/w-notification.service';
import { DlgType } from '../../w-notification/models/notification-models';

@Component({
  selector: 'wave-eduxible-student-modal',
  templateUrl: './student-modal.component.html',
  styleUrls: ['./student-modal.component.scss'],
})
export class StudentModalComponent implements OnInit {
  @Input() title!: string;
  @Input() student!: Student;
  @Input() action!: string;
  @Input() confirmButton!: string;
  filter!: CourseFilter;
  dropdownList: CourseCombo[] = [];
  selectedItems: CourseCombo[] = [];
  dropdownSettings = {};

  modalForm!: FormGroup;
  studentAlreadyExist = false;
  foundError: boolean = false;
  confirmed: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private router: Router,
    public studentService: StudentService,
    private _fb: FormBuilder,
    private alertService: WNotificationService,
    private courseService: CoursesService
  ) {}

  ngOnInit() {
    console.log('studente', this.student);
    let selectedNames: CourseCombo[] = [];
    if(this.student && this.student.courses.length > 0){
      for (let i = 0; i < this.student.courses.length; i++) {
        let c: CourseCombo = {
          id: this.student.courses[i].courseId,
          name: this.student.courses[i].name,
          active: true
        }
        console.log('C',c);
        selectedNames.push(c);
      }
    }
    //this.selectedCourse

    this.courseService
      .getCoursesListForCombo()
      .subscribe((response: any) => {
        console.log('RESP', response);
        this.dropdownList = [];
        for(var i=0; i<response.courses.length; i++){
          if(response.courses[i].active){
            this.dropdownList.push(response.courses[i]);
          }
        }
        console.log('dropdown-list', this.dropdownList);

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'name',
          searchPlaceholderText: 'Cerca...',
          noFilteredDataAvailablePlaceholderText: 'Nessun dato trovato',
          selectAllText: 'Seleziona tutti',
          unSelectAllText: 'Deseleziona tutti',
          itemsShowLimit: 20,
          allowSearchFilter: true,
        };
       this.selectedItems = selectedNames;

      //  this.modalForm.controls['courses'].patchValue(this.selectedItems);
      });
      this.modalForm = this._fb.group({
        nome: [this.student ? this.student.name : '', Validators.required],
        cognome: [this.student ? this.student.surname : '', Validators.required],
        email: [
          this.student ? this.student.email : '',
          [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
        ],
        courses: [this.selectedItems],
      });

  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  submitForm(formValue: any) {
    this.confirmed = true;
    console.log('FORMVALUE', formValue);
    let studCourses: number[] = [];
    let courses: Course[] = formValue['courses'];
    for (let i = 0; i < courses.length; i++) {
      studCourses.push(courses[i].id);
    }
    switch (this.action) {
      case 'add':
        let student: NewStudent = new NewStudent();
        student.name = formValue.nome;
        student.surname = formValue.cognome;
        student.email = formValue.email;
        student.courses = studCourses;
        student.pwd = '';
        let ret;
        this.studentService.addStudent(student).subscribe({
          next: (val) => {
            ret = val;
            this.alertService.open({
              titolo: "Successo",
              tipo: DlgType.SUCCESS,
              messaggio: `Studente inserito correttamente.`,
              cancelLabel: "",
              cancelVisible: true,
              okLabel: "",
              codErrore: 0
            });
            this.modal.close(formValue);
          },
          error: (error) => {
            console.log('ERROR MESS', error);
            /*this.alertService.open({
              titolo: "Errore",
              tipo: DlgType.ERROR,
              messaggio: `Errore nell'inserimento dello studente.`,
              cancelLabel: "",
              cancelVisible: true,
              okLabel: "",
              codErrore: 0
            });*/
          },
        });
        break;

      case 'edit':

        let updatableStudentWrapper: UpdatableStudent = {
          class: '',
          email: formValue['email'],
          name: formValue['nome'],
          surname: formValue['cognome'],
          courses: studCourses,
        };
        console.log(updatableStudentWrapper);
        this.studentService
          .editStudent(this.student.id, updatableStudentWrapper)
          .subscribe({
            next: (val) => {
              console.log('STATUS: ', val);
              this.alertService.open({
                titolo: "Successo",
                tipo: DlgType.SUCCESS,
                messaggio: `Studente modificato correttamente.`,
                cancelLabel: "",
                cancelVisible: true,
                okLabel: "",
                codErrore: 0
              });
              this.modal.close(formValue);
            },
            error: (error: any) => {              
             /* this.alertService.open({
                titolo: "Errore",
                tipo: DlgType.SUCCESS,
                messaggio: `Errore nella modifica dello studente.`,
                cancelLabel: "",
                cancelVisible: true,
                okLabel: "",
                codErrore: 0
              });*/
            },
          });
        break;
    }
  }
}
