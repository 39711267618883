<div class="modal-lg modal-content modal-xs">
  <div class="modal-header">
    <h3 class="modal-title w-100 text-center" id="modal_title_4">{{title}} </h3>
  </div>
  <div class="modal-body">
    <form [formGroup]="modalForm" (ngSubmit)="submitForm(modalForm.value)">
      <div class="col-sm-12 mb-4">
        <label for="firstName" class="form-label fw-bold ms-2">Nome<span
            class="required">*</span></label>
        <input type="text" class="form-control border-0"
          id="firstName" name="nome" formControlName="nome" required>
        <div class="text-danger x-small mt-1">
          <span *ngIf="this.confirmed &&
            this.modalForm.controls['nome'].errors?.['required']">Questo
            campo è obbligatorio</span>
        </div>
      </div>
      <div class="col-sm-12 mb-4">
        <label for="lastName" class="form-label fw-bold ms-2">Cognome<span
            class="required">*</span></label>
        <input type="text" class="form-control border-0"
          id="lastName" name="cognome" formControlName="cognome" required>
        <div class="text-danger x-small mt-1">
          <span *ngIf="this.confirmed &&
            this.modalForm.controls['cognome'].errors?.['required']">Questo
            campo è obbligatorio</span>
        </div>
      </div>
      <div class="col-sm-12 mb-4">
        <label for="inputEmail" class="form-label fw-bold ms-2">Email<span
            class="required">*</span></label>
        <input
          type="email"
          id="inputEmail"
          name="email"
          formControlName="email"
          (change)="foundError= false"
          [ngClass]="(modalForm.controls['email'].invalid &&
          modalForm.controls['email'].touched) || foundError ? 'form-control
          border-0 border-bottom mb-2 border-danger text-danger' : 'form-control
          border-0 border-bottom mb-2'" required>
        <div *ngIf="modalForm.controls['email'].invalid &&
          modalForm.controls['email'].touched &&
          modalForm.controls['email'].value !== ''" class="invalid-feedback
          d-block">
          Formato email non valido
        </div>
        <div *ngIf="modalForm.controls['email'].touched &&
          modalForm.controls['email'].value === ''" class="invalid-feedback
          d-block">
          Email obbligatoria
        </div>
        <div *ngIf=" modalForm.controls['email'].touched &&
          modalForm.controls['email'].value !== '' && foundError"
          class="invalid-feedback d-block">
          Esiste già un utente con questa email
        </div>
        <div class="text-danger x-small mt-1">
          <span *ngIf="this.confirmed &&
            this.modalForm.controls['email'].errors?.['required']">Questo
            campo è obbligatorio</span>
        </div>
      </div>

      <div class="col-sm-12 mb-4">
        <label id="collab" class="form-label fw-bold mb-2 ms-2">Corsi</label>
        <ng-multiselect-dropdown
          name="courses"
          [placeholder]="'Corsi a cui si è iscritti'"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          [(ngModel)]="selectedItems"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          formControlName="courses">
        </ng-multiselect-dropdown>
      </div>
    </form>
    <div class="modal-footer">
      <button type="submit" class="btn btn-outline-secondary d-flex align-items-center"
        (click)="modal.dismiss('Modifica non effettuata')">
        <svg class="icon me-2">
          <use xlink:href="assets/icons/annulla.svg#annulla"
            href="assets/icons/annulla.svg#annulla"></use>
        </svg>Annulla</button>
      <button type="submit" (click)="submitForm(modalForm.value)" class="btn btn-primary">
        <svg class="icon me-2">
          <use xlink:href="assets/icons/confirm.svg#confirm"
            href="assets/icons/confirm.svg#confirm"></use>
        </svg>{{confirmButton}}</button>
    </div>
  </div>
</div>
